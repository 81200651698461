import * as React from 'react';
import qs from 'qs';
import _ from 'lodash';
import Helmet from 'react-helmet';
import Color from 'color';
import { Query, Mutation, MutationFn, withApollo, WithApolloClient } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { Subscribe, getContainers } from 'infra-frontend/helpers/apollo';
import { Shareable, Design, DesignArtwork } from 'inkp-design-sdk/types.g';
import { routes, url, path, updateURLQuery } from 'inkp-routes/public';
import { hasAb } from 'inkp-user-sdk/user';
import globalConfig from 'inkp-config/public';
import mockupRoutes from 'inkp-mockup-sdk/routes';

import PrintQualityAlert from 'inkp-components/dist/Components/PrintQualityAlert';
import Notif from 'inkp-components/dist/Components/Notif';
import WithLazyCustomFont from 'inkp-components/dist/HOC/WithLazyCustomFont';
import Button from 'inkp-components/dist/Components/Button';
import Alert from 'inkp-components/dist/Components/Alert';
import Modal from 'inkp-components/dist/Components/Modal';
import Loading from 'inkp-components/dist/Components/Loading';
import { UPLOAD_ERROR_CODE } from 'inkp-components/dist/Components/FileUpload';

import { Product, ProductColor } from 'inkp-product-sdk/types.g';
import {
  LimitedQuoteItems,
  OrderQuoteItemProduct,
  PRINT_TYPES_ENUM,
  QuoteSides,
  Cart,
  Query as OrderQueryResults,
  Order,
} from 'inkp-order-sdk/types.g';

import { stringifyProductItemId, parseProductItemId } from 'inkp-product-sdk';
import { selectPrintType } from 'inkp-order-sdk/quoter';

import { Shape, ShapeType, isTextShape } from '../../interfaces/Canvas';
import { CartProduct } from '../../interfaces/CartProduct';
import { CartProductState } from '../../components/CartDrawer/cartContents';
import Canvas, { PRINTZONE_COLOR_HEX } from '../../components/Canvas';
import { MIN_QUALITY_DPI } from '../../components/Canvas/ImageShape';
import QuantitySelector from '../../components/QuantitySelector';
import DesignCart, { DesignCartState, DesignCartProductInput } from '../../states/global/designCart';
import { QUERY_CURRENT_USER, QueryCurrentUser } from '../../graphql/users';

import CanvasDimensionsProvider from './CanvasDimensionsProvider';
import ProgressModal from './ProgressModal';
import FailUploadModal from './FailUploadModal';
import DesignToolHeader from './DesignToolHeader';
import SaveDesignModal from './SaveDesignModal';
import DesignToolControl from './DesignToolControl';
import ImageUploadOverlay from './DesignToolControl/ImageUploadOverlay';
import AddProductModal from './AddProductModal';
import RetrieveDesignModal from './RetrieveDesignModal';
import SideThumbnails from './SideThumbnails';

import WithNavigationPrompt from '../../components/HOC/WithNavigationPrompt';
import DesignToolStateContainer, { DesignToolState } from './DesignToolStateContainer';
import ShapeMenu from './ShapeMenu';
import { DesignToolQuote } from './DesignToolControl/ProductManager';
import { FontsByNamesQuery, FONTS_BY_NAMES_QUERY } from '../DesignTool/FontSelector';
import { pixelPrintZone, toFixed2, filterOutOfBoundShapes, createPrintArea, templateCanvasData } from './utils';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_TEXT_COLOR_HEX,
  MIN_FONT_SIZE_PX,
  snapToGuideline,
  createGuideline,
} from '../../util/canvas';
import { CartItemsToCartProducts } from '../../util/Product';
import {
  ARTWORK_CREATION_MUTATION,
  SWAP_ORDER_DESIGN_MUTATION,
  CREATE_DESIGNS_MUTATION,
  CartQuery,
  CART_QUERY,
  CREATE_TEMP_DESIGNS_MUTATION,
  QueryDesignItemQuote,
  QUERY_DESIGN_ITEM_QUOTE,
} from './queries';
import { QUERY_PRODUCTS_BY_ID } from '../../graphql/products';
import { QUERY_DESIGN_BY_ID, QueryDesignById } from '../../graphql/designs';
import CartDrawer from '../../components/CartDrawer';
import CartActionsModal from '../../components/CartActionsModal';
import AbTest, { AbVariation } from '../../components/HOC/AbTest';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes, GTMEvents } from '../../interfaces/GTM';

const SIDE_ORDER = ['FRONT', 'BACK', 'LEFT', 'RIGHT'];
const {
  s3: { uploadPolicy: UPLOAD_POLICY },
} = globalConfig;

interface TempDesign {
  id: string;
  sides: {
    name: string;
    artwork: TempDesignArtwork;
  }[];
}
type TempDesignArtwork = Pick<DesignArtwork, 'colors' | 'fullColor'>;

interface QueryResult<T = any, D = any> {
  loading: boolean;
  error?: any;
  data?: T;
  client: D;
}

interface PageProps extends RouteComponentProps {
  order?: Order; // Order data can be passed in during redesign
  cart?: Cart;
  itemIndexes?: number[]; // Item indexes can be passed in during redesign
  designCart: DesignCart;
  designCartState: DesignCartState;
  designToolStateContainer: DesignToolStateContainer;
  shareable?: Shareable;
  onNext?: (data: { product: Product; color: ProductColor }) => void;
  submitting?: boolean;
  alerts?: any[];
}
interface PageState {
  file?: File | null; // File cannot be serialized, so it cannot be store in state container/URL query
  fontsLoaded?: boolean; // Fonts are lazy loaded

  tempDesigns: TempDesign[];
  loadingQuote: boolean;
}

export class TypedSubscribe extends Subscribe<[DesignToolStateContainer]> {}

class DesignTool extends React.Component<WithApolloClient<PageProps>, PageState> {
  state: PageState = {
    fontsLoaded: false,
    tempDesigns: [],
    loadingQuote: false,
  };

  constructor(props: WithApolloClient<PageProps>) {
    super(props);
  }

  onToggleProductModal = (open: boolean) => {
    const params = qs.parse(`${this.props.location.search}`, { ignoreQueryPrefix: true });
    this.props.history.replace(
      updateURLQuery(this.props.location.pathname, params, { productModal: open || null, showMockups: null }, {})
    );
  };

  createSideColors() {
    const tempDesignSides: TempDesign['sides'] | undefined = _.get(this.state.tempDesigns, '0.sides');

    let sideColors: { [key: string]: number } = {};
    _.forEach(tempDesignSides, (designSide) => {
      const colorNum = _.get(designSide, 'artwork.colors.length') || 0;
      sideColors = { ...sideColors, [designSide.name]: colorNum };
    });

    return sideColors;
  }

  createQuoteItems(product: any): LimitedQuoteItems[] {
    const haveSize = _.some(product.sizesQuantities, (sizeQuantity) => sizeQuantity.qty > 0);
    if (!haveSize) return [];

    const tempDesignId: string | undefined = _.get(this.state.tempDesigns, '0.id') || 'tempDesignId';
    const tempDesignSides: TempDesign['sides'] | undefined = _.get(this.state.tempDesigns, '0.sides');

    const activeColor = _.find(product.colors, { selected: true });

    let productItems: OrderQuoteItemProduct[] = [];
    product.sizesQuantities.forEach((sizeQuantity: { name: string; qty: number }) => {
      if (sizeQuantity.qty <= 0) return;
      const productItem: OrderQuoteItemProduct = {
        productItemId: stringifyProductItemId(product.id, activeColor.name, sizeQuantity.name),
        quantity: sizeQuantity.qty,
      };

      productItems.push(productItem);
    });

    let quoteSides: QuoteSides = {};
    _.forEach(tempDesignSides, (designSide) => {
      const inkSize = designSide.artwork.fullColor ? 1 : _.get(designSide, 'artwork.colors.length' || 0);
      quoteSides = { ...quoteSides, [designSide.name]: inkSize };
    });
    if (!_.some(quoteSides, (color) => color! > 0)) {
      quoteSides = { FRONT: 1 };
    }

    const printType: PRINT_TYPES_ENUM = _.some(tempDesignSides, (side) => side.artwork.fullColor)
      ? PRINT_TYPES_ENUM.DTG
      : selectPrintType(quoteSides, [product], productItems);

    const quoteItems = productItems.map((productItem) => ({
      designId: tempDesignId,
      printType,
      product: productItem,
      quoteSides,
    }));

    return quoteItems;
  }

  formatQuoteData({
    quoteData,
    quoteLoading,
    skip,
  }: {
    quoteData: OrderQueryResults | undefined;
    quoteLoading: boolean;
    skip: boolean;
  }): DesignToolQuote {
    let quote: DesignToolQuote = {
      subtotal: 0,
      qty: 0,
      loading: false,
    };
    if (skip) return quote;
    if (quoteData && quoteData.requestLimitedQuote) {
      const qty = _.sumBy(quoteData.requestLimitedQuote, 'product.quantity');
      const subtotal = _.sumBy(quoteData.requestLimitedQuote, 'amounts.subtotal');
      quote = {
        subtotal,
        qty,
        loading: false,
      };
    }

    quote.loading = quoteLoading || this.state.loadingQuote;

    return quote;
  }

  renderTools(
    params: any,
    designIndex: number,
    designSide: any,
    productSide: any,
    template: any,
    item: any,
    fonts: any,
    selectedProducts: any,
    isOutOfBounds: boolean
  ) {
    const { designCartState, designCart, designToolStateContainer } = this.props;

    const {
      state,
      mobileSelect,
      selectTool,
      selectMobileTool,
      selectShape,
      changeMobileSection,
      setDragging,
      setResizing,
      setVerticalGuideline,
      startUpload,
      progressUpload,
      unsetUpload,
      setUploadError,
      deselect,
      setHovered,
      unsetHovered,
      showLayer,
      setProductEditTab,
    } = designToolStateContainer;

    const {
      updateShape,
      deleteShape,
      addText,
      addImage,
      addClipArt,
      rearrange,
      changeProductColor,
      removeProduct,
      updateQty,
      updateNote,
      cleanUp,
    } = designCart;

    const canvasData = templateCanvasData(template);
    const { inchPerPx } = canvasData;

    const selected = designSide.shapes.find(({ id }: { id: string }) => id === state.selectedId);
    let dpi: number, dimensions: { width: number; height: number };
    if (selected && selected.naturalWidth) {
      const widthInch = (selected.width || 0) * inchPerPx;
      const heightInch = (selected.height || 0) * inchPerPx;
      dpi = Math.round(selected.naturalWidth / widthInch);
      dimensions = { width: widthInch, height: heightInch };
    }

    const printArea = createPrintArea(template, canvasData);

    const printZones = productSide.printZonesObjects.map((printZone: any) =>
      pixelPrintZone(printZone, inchPerPx, printArea)
    );
    // TODO: Figure out what is main printzone
    const mainPrintZone = printZones[0];
    mainPrintZone.main = true;
    const mainGuidelinePosition = 'vertical-center';

    const blankSrc = url('mockup', mockupRoutes.blank, {
      templateIdentifier: template.id,
      color: item.hex,
      size: 'regular',
    });

    const activeProduct = selectedProducts.find(({ active }: { active: boolean }) => active) || selectedProducts[0];

    const selectedProductColor =
      activeProduct.colors.find(({ selected }: { selected: boolean }) => selected) || activeProduct.colors[0];

    const quoteItems = this.createQuoteItems(activeProduct);

    return (
      <Mutation
        mutation={ARTWORK_CREATION_MUTATION}
        onCompleted={(data: any) => {
          if (state.upload) {
            addImage(
              designIndex,
              designSide.name,
              {
                artworkId: data.createArtwork.id,
                src: data.createArtwork.url,
                display: state.upload.fileName,
                dimensions: data.createArtwork.dimensions,
              },
              mainPrintZone,
              inchPerPx
            );
            const designInputs = designCartState.designs.map(({ sides }: { sides: any }) => {
              return { sides: sides.map((side: any) => _.omit(side, 'tempArtwork')) };
            });
            unsetUpload();
            this.setState({ file: null });
          }
        }}
        onError={(err: any) => {
          setUploadError({ code: UPLOAD_ERROR_CODE.UPLOAD_REJECTED });
        }}
      >
        {(createArtwork: MutationFn) => (
          <>
            <div className="col col-2 overflow-scroll lg:overflow-visible">
              <CanvasDimensionsProvider>
                {(canvasDimensions: { width: number; height: number }) => {
                  let canvasWidth = canvasDimensions.width;
                  let canvasHeight = (canvasWidth * template.data.size.height) / template.data.size.width;
                  if (canvasHeight > canvasDimensions.height) {
                    canvasHeight = canvasDimensions.height;
                    canvasWidth = (canvasHeight! * template.data.size.width) / template.data.size.height;
                  }

                  return (
                    <div className="w-full p-relative" id="canvas-container">
                      <WithLazyCustomFont
                        fonts={fonts}
                        onFontsLoaded={() => {
                          this.setState({ fontsLoaded: true }); // Re-render after fonts are all loaded
                        }}
                      >
                        <Canvas
                          selectedId={state.selectedId}
                          hoveredId={state.hoveredId}
                          shapes={designSide.shapes}
                          blankSrc={blankSrc}
                          blankWidth={template.data.size.width}
                          blankHeight={template.data.size.height}
                          width={canvasWidth!}
                          height={canvasHeight}
                          scale={{
                            x: canvasWidth! / template.data.size.width,
                            y: canvasHeight / template.data.size.height,
                          }}
                          printArea={printArea}
                          inchPerPx={inchPerPx}
                          printZones={printZones}
                          mainPrintZone={mainPrintZone}
                          guidelinePositions={[mainGuidelinePosition]}
                          onDeleteShape={(shape: Shape) => deleteShape(designIndex, designSide.name, shape)}
                          onUpdateShape={(shape: Shape) => {
                            updateShape(designIndex, designSide.name, shape);
                          }}
                          showPrintZones={state.dragging || state.resizing || isOutOfBounds}
                          printZoneProps={{
                            color: isOutOfBounds ? PRINTZONE_COLOR_HEX.warning : PRINTZONE_COLOR_HEX.default,
                          }}
                          guidelineProps={{
                            showVerticalGuide: state.showVerticalGuideline,
                          }}
                          onDragStart={(shape) => setDragging(true, shape)}
                          onDragMove={(shape: Shape, mousePosition: { x: number; y: number }) => {
                            const mainGuideline = createGuideline(mainPrintZone, mainGuidelinePosition);
                            const { isSnappable } = snapToGuideline(shape, mousePosition, mainGuideline);
                            setVerticalGuideline(isSnappable);
                          }}
                          onDragEnd={(shape) => {
                            setDragging(false, shape);
                            setVerticalGuideline(false);
                          }}
                          onResizeStart={(shape) => setResizing(true)}
                          onResizeEnd={(shape) => setResizing(false)}
                          onMouseOver={setHovered}
                          onMouseOut={unsetHovered}
                          onSelectShape={selectShape}
                          onDeselect={() => deselect()}
                        />
                      </WithLazyCustomFont>
                      {designCart.currentZIndex(0, productSide.name) === 0 && (
                        <div className="image-upload-overlay d-n lg:d-b mx-auto p-absolute">
                          <style jsx>
                            {`
                              .image-upload-overlay {
                                width: ${canvasWidth * 0.46}px;
                                height: ${canvasHeight * 0.6}px;
                                top: 45%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                              }
                            `}
                          </style>
                          <ImageUploadOverlay
                            file={this.state.file}
                            uploadPolicy={UPLOAD_POLICY}
                            onUpload={(e: any) => {
                              //TODO: Do a better job to check if there the finish event is not from a canceld upload
                              if (state.upload && state.upload.fileName === e.fileName) {
                                createArtwork({ variables: { url: e.url } });
                              }
                            }}
                            onFileDrop={(e: React.DragEvent<HTMLDivElement>) => {
                              if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
                                this.setState({ file: e.dataTransfer.files[0] });
                              }
                            }}
                            onUploadStart={startUpload}
                            onProgress={progressUpload}
                            onFailure={setUploadError}
                          />
                        </div>
                      )}
                      {isOutOfBounds && !designToolStateContainer.state.hideOutOfBoundsWarning && (
                        <Alert
                          className={'bc-yellow shadow-none p-absolute pin-l pin-t w-full bw-1'}
                          alerts={[
                            {
                              type: 'warning',
                              content:
                                'Artwork outside of print area. Any visuals outside of print area will not print.',
                              onClose: () => {
                                designToolStateContainer.setOutOfBounds(null);
                                designToolStateContainer.hideOutOfBoundsWarning(true);
                              },
                            },
                          ]}
                        />
                      )}
                      {selected &&
                        selected.type === ShapeType.TEXT &&
                        selected.fontSize < MIN_FONT_SIZE_PX &&
                        state.resizing && (
                          <PrintQualityAlert
                            className="p-absolute pin-l pin-t w-full"
                            value={selected.fontSize}
                            thresholds={[
                              {
                                value: MIN_FONT_SIZE_PX,
                                quality: 'Poor' as const,
                                display: `(font size: ${toFixed2(
                                  selected.fontSize
                                )}). Text does not fit. Please resize.`,
                              },
                              {
                                value: 20,
                                quality: 'Good' as const,
                                display: `(font size: ${toFixed2(selected.fontSize)}).`,
                              },
                              {
                                quality: 'Great' as const,
                                display: `(font size: ${toFixed2(selected.fontSize)}).`,
                              },
                            ]}
                          />
                        )}
                      {dpi &&
                        dimensions &&
                        state.resizing &&
                        ((selected.type === ShapeType.CLIPART && dpi < MIN_QUALITY_DPI) ||
                          selected.type === ShapeType.IMAGE) && (
                          <PrintQualityAlert
                            className="p-absolute pin-l pin-t w-full"
                            value={dpi}
                            thresholds={[
                              {
                                value: MIN_QUALITY_DPI,
                                quality: 'Poor',
                                display: `(${dpi} DPI - ${toFixed2(dimensions.width)}" \u2715 ${toFixed2(
                                  dimensions.height
                                )}")`,
                              },
                              {
                                value: 200,
                                quality: 'Good',
                                display: `(${dpi} DPI - ${toFixed2(dimensions.width)}" \u2715 ${toFixed2(
                                  dimensions.height
                                )}")`,
                              },
                              {
                                quality: 'Great',
                                display: `(${dpi} DPI - ${toFixed2(dimensions.width)}" \u2715 ${toFixed2(
                                  dimensions.height
                                )}")`,
                              },
                            ]}
                          />
                        )}
                    </div>
                  );
                }}
              </CanvasDimensionsProvider>
            </div>
            <div className="col col-3">
              <style jsx={true}>{`
                .floating-button {
                  position: absolute;
                  bottom: 8rem;
                  left: 50%;
                  transform: translateX(-50%);
                  text-align: center;
                }
              `}</style>
              {selected && (
                <div className="floating-button lg:d-n">
                  <Button
                    type={'default'}
                    size={'lg'}
                    color={'primary'}
                    disabled={false}
                    icon={true}
                    onClick={() => mobileSelect(true)}
                    noXPadding={false}
                    withIcon={true}
                    mdIcon="pencil"
                  />
                  <span className="d-b color-coral fs-xs fw-bold mt-p5">
                    Edit {selected.type === ShapeType.TEXT ? 'Text' : 'Image'}
                  </span>
                </div>
              )}
              <div className="p-relative w-full">
                <QueryDesignItemQuote
                  query={QUERY_DESIGN_ITEM_QUOTE}
                  variables={{ items: quoteItems }}
                  skip={_.isEmpty(quoteItems)}
                >
                  {({ error: quoteError, loading: quoteLoading, data: quoteData }) => {
                    const quote = this.formatQuoteData({ quoteLoading, quoteData, skip: _.isEmpty(quoteItems) });

                    return (
                      <DesignToolControl
                        selected={selected}
                        editableProducts={!designCartState.redesigning}
                        products={selectedProducts}
                        quote={quote}
                        sideColors={this.createSideColors()}
                        onSelectTool={selectTool}
                        onSelectMobileTool={selectMobileTool}
                        tool={state.selectedTool}
                        mobileTool={state.mobileTool}
                        uploadProps={{
                          file: this.state.file,
                          uploadPolicy: UPLOAD_POLICY,
                          onUpload: (e: any) => {
                            //TODO: Do a better job to check if there the finish event is not from a canceld upload
                            if (state.upload && state.upload.fileName === e.fileName) {
                              createArtwork({ variables: { url: e.url } });
                            }
                          },
                          onFileDrop: (e: React.DragEvent<HTMLDivElement>) => {
                            if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.setState({ file: e.dataTransfer.files[0] });
                            }
                          },
                          onUploadStart: startUpload,
                          onProgress: progressUpload,
                          onFailure: setUploadError,
                        }}
                        textProps={{
                          onAddText: (text: string) => {
                            const defaultTextColor = Color(`#${selectedProductColor.hex}`).isDark()
                              ? '#ffffff'
                              : '#000000'; // Change text color based on selected product color
                            const isDefaultColor = ['#ffffff', '#000000'].includes(designCartState.lastTextColor);
                            const textColor = isDefaultColor ? defaultTextColor : designCartState.lastTextColor;
                            addText(designIndex, designSide.name, text, textColor, mainPrintZone, inchPerPx);
                          },
                        }}
                        clipArtProps={{
                          onAddClipArt: (clipArt: any) => {
                            const defaultClipartColor = Color(`#${selectedProductColor.hex}`).isDark()
                              ? '#ffffff'
                              : '#000000'; // Change clipart color based on selected product color
                            const isDefaultColor = ['#ffffff', '#000000'].includes(designCartState.lastTextColor);
                            const clipartColor = isDefaultColor ? defaultClipartColor : designCartState.lastTextColor;
                            addClipArt(
                              designIndex,
                              designSide.name,
                              {
                                svg: clipArt.svg,
                                display: clipArt.name,
                                dimensions: clipArt.dimensions,
                              },
                              clipartColor,
                              mainPrintZone,
                              inchPerPx
                            );
                          },
                        }}
                        onDisplayColor={({ productId, color }: { productId: string; color: string }) => {}}
                        onSelectColor={({ productId, color }: { productId: string; color: string }) => {
                          changeProductColor(productId, color);
                          this.props.history.replace(
                            updateURLQuery(
                              this.props.location.pathname,
                              params,
                              { product: productId, color: color },
                              {}
                            )
                          );
                        }}
                        onRemoveProduct={(productId: string) => {
                          removeProduct(productId);
                        }}
                        onUpdateQty={updateQty}
                        onUpdateNote={updateNote}
                        productEditTab={state.productEditTab}
                        onSetProductEditTab={setProductEditTab}
                        onClickAddProduct={() => this.onToggleProductModal(true)}
                        onResetDesignCart={cleanUp}
                      />
                    );
                  }}
                </QueryDesignItemQuote>
                <ShapeMenu
                  selected={selected}
                  onUpdateShape={(shape: any) => updateShape(designIndex, designSide.name, shape)}
                  mobileSelected={state.mobileSelected}
                  mobileSection={state.mobileSection}
                  onChangeSection={changeMobileSection}
                  onCancel={() => mobileSelect(false)}
                  onShowLayer={() => showLayer(true)}
                  onHideLayer={() => showLayer(false)}
                  showLayer={state.showLayer}
                  shapes={designSide.shapes}
                  onNewIndex={({ id, zIndex }: { id: string; zIndex: number }) =>
                    rearrange(designIndex, designSide.name, id, zIndex)
                  }
                  mainPrintZone={mainPrintZone}
                />
              </div>
            </div>
          </>
        )}
      </Mutation>
    );
  }

  outOfBounds = (designState: DesignCartState, products: Product[]) => {
    const results = [];
    for (const selectedProduct of designState.products) {
      const design = designState.designs[selectedProduct.designIndex];
      const product = products.find(({ id }) => id === selectedProduct.productId)!;
      for (const productSide of product.sides) {
        const designSide = design.sides.find(({ name }) => name === productSide.name)!;
        if (designSide.shapes.length === 0) continue;
        const template = product.designTemplates.find(({ side }) => side === productSide.name)!;
        const canvasData = templateCanvasData(template);
        const printArea = createPrintArea(template, canvasData);
        const mainPrintZone = pixelPrintZone(productSide.printZonesObjects![0], canvasData.inchPerPx, printArea);
        const outOfBoundShapes = filterOutOfBoundShapes(designSide.shapes, mainPrintZone);
        if (outOfBoundShapes.length > 0) {
          results.push({ productId: product.id, side: productSide.name, shapes: outOfBoundShapes });
        }
      }
    }

    return results;
  };

  isAllEmpty = (designState: DesignCartState) => {
    for (const selectedProduct of designState.products) {
      const design = designState.designs[selectedProduct.designIndex];
      for (const designSide of design.sides) {
        if (designSide.shapes.length > 0) return false;
      }
    }
    return true;
  };

  createTempDesign(prevProps: PageProps) {
    let designs: any;

    const designState = this.props.designCartState;
    const prevDesignState = prevProps.designCartState;
    if (designState.designs.length !== prevDesignState.designs.length) {
      designs = designState.designs;
    } else {
      designs = designState.designs.map((design: any, index: number) => {
        const prevDesign = prevDesignState.designs[index];
        if (!prevDesign) return design;

        const sides = design.sides.filter((designSide: any) => {
          const prevDesignSide = _.find(prevDesign.sides, { name: designSide.name });
          return !prevDesignSide || !_.isEqual(designSide.shapes, prevDesignSide.shapes);
        });

        return { ...design, sides };
      });
    }

    if (!_.some(designs, (design: any) => !_.isEmpty(design.sides))) return;

    this.setState({ loadingQuote: true });

    const designInputs = designs.map(({ sides }: { sides: any }) => {
      return { sides };
    });

    this.props.client
      .mutate({ mutation: CREATE_TEMP_DESIGNS_MUTATION, variables: { designs: designInputs } })
      .then(({ data }) => {
        const { createTempDesigns: tempDesigns }: { createTempDesigns: TempDesign[] } = data;
        const newTempDesigns = _.cloneDeep(this.state.tempDesigns);
        tempDesigns.forEach((tempDesign, index) => {
          const newTempDesign = newTempDesigns[index];
          if (!newTempDesign) return newTempDesigns.push(tempDesign);
          tempDesign.sides.forEach((side) => {
            let newSide = _.find(newTempDesign.sides, { name: side.name });
            if (!newSide) {
              return newTempDesign.sides.push(side);
            }
            newSide.artwork = side.artwork;
          });
        });
        this.setState({ tempDesigns: newTempDesigns });
      })
      .catch((err: any) => {
        console.log('newtempDesigns error', err);
      })
      .finally(() => {
        this.setState({ loadingQuote: false });
      });
  }

  createTempDesignDebounced = _.debounce(this.createTempDesign, 500);

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  componentDidUpdate(prevProps: PageProps) {
    if (!_.isEqual(prevProps.designCartState.designs, this.props.designCartState.designs)) {
      this.createTempDesignDebounced(prevProps);
    }
  }

  onBackToCartActionClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    return this.props.designToolStateContainer.setShowCartActionsModal(false);
  };

  onEditDesignActionClick = (cart: any, products: any) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { designToolStateContainer, location, designCart } = this.props;
    const { pathname } = location;
    const { designCart: globalDesignCart } = getContainers(this.props.client);
    designToolStateContainer.removeNewCartItem();
    designToolStateContainer.resetDefaultDesignName();
    designToolStateContainer.setShowCartActionsModal(false);
    designToolStateContainer.setShowCart(false, false);
    globalDesignCart.setEditingDesign(false);
    globalDesignCart.setContinueDesignState(designCart.state.products, designCart.state.designs);
    const product = globalDesignCart.state.products[0];
    const colorName: string = product.colors[0];
    this.props.history.push(path(routes.app.designTool, { product: product.productId, color: colorName }));
    return;
  };

  onStartNewDesignActionClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { designToolStateContainer, designCart, location } = this.props;
    const { pathname } = location;
    const { designCart: globalDesignCart } = getContainers(this.props.client);
    globalDesignCart.resetDesign();
    globalDesignCart.setEditingDesign(false);
    designToolStateContainer.removeNewCartItem();
    designToolStateContainer.resetDefaultDesignName();
    designToolStateContainer.setShowCartActionsModal(false);
    designToolStateContainer.setShowCart(false, false);
    if (pathname !== routes.app.designTool) {
      this.props.history.push(path(routes.app.designTool));
    }
  };

  render() {
    const params = qs.parse(`${this.props.location.search}`, { ignoreQueryPrefix: true });
    const selectedProductId =
      params.product ||
      this.props.designCart.state.products.map(({ productId }: { productId: string }) => productId)[0];
    const selectedSide = params.side && params.side.toUpperCase();

    const { designToolStateContainer } = this.props;
    const { designCart } = this.props;

    let listOutOfBoundShapes = () => [];
    let handleNext: (() => void) | null = null;
    return (
      <QueryCurrentUser query={QUERY_CURRENT_USER}>
        {({ error: userError, loading: userLoading, data: userData }) => {
          const designState = designCart.state;
          return (
            <WithNavigationPrompt showPrompt={true}>
              <div className="h-full flex flex-col lg:overflow-auto p-relative">
                <style jsx global>{`
                  .design-tool-notification {
                    top: 3.5rem;
                  }

                  .design-tool-container .grid {
                    display: flex;
                    flex-direction: column;
                  }

                  .design-tool-container .col-2 {
                    flex: 1;
                  }

                  .konvajs-content {
                    margin: auto;
                  }

                  @media only screen and (min-width: 1024px) {
                    .design-tool-container {
                      width: 100%;
                      padding: 0 2rem;
                    }

                    .design-tool-container .grid {
                      margin: 0 -2.625%;
                      display: flex;
                      flex-direction: row;
                    }

                    .design-tool-container .col {
                      padding: 0 2.625%;
                    }

                    .design-tool-container .col-1 {
                      width: 11.05%;
                    }

                    .design-tool-container .col-1 > div {
                      position: sticky;
                      top: 96px;
                    }

                    .design-tool-container .col-2 {
                      width: 55.15%;
                    }

                    .design-tool-container .col-2 > div {
                      position: sticky;
                      top: 96px;
                    }

                    .design-tool-container .col-3 {
                      width 39.05%;
                    }

                  }

                  @media only screen and (min-width: 1440px) {
                    .design-tool-container {
                      width: 1376px;
                      margin: 0 auto;
                      padding: 0;
                    }

                    .design-tool-container .grid {
                      margin: 0 -36px;
                    }

                    .design-tool-container .col {
                      padding: 0 36px;
                    }

                    .design-tool-container .col-1 {
                      width: 152px;
                    }

                    .design-tool-container .col-2 {
                      width: 759px;
                    }

                    .design-tool-container .col-3 {
                      width 537px;
                    }
                  }
                `}</style>
                <Helmet>
                  <title>Design Tool</title>
                </Helmet>
                {designState.products.length > 0 && (
                  <CartQuery query={CART_QUERY} partialRefetch={true}>
                    {({ data: cartQueryData, loading: cartQueryLoading, error: cartQueryError }) => (
                      <Query
                        query={QUERY_PRODUCTS_BY_ID}
                        variables={{
                          ids: _.uniq([
                            ...((cartQueryData &&
                              cartQueryData.cartByUser &&
                              cartQueryData.cartByUser.items.map((item) => {
                                const { productId } = parseProductItemId(item.product.productItemId);
                                return productId;
                              })) ||
                              []),
                            ...designState.products.map(({ productId }: { productId: string }) => productId),
                          ]).sort(),
                        }}
                        partialRefetch={true}
                        notifyOnNetworkStatusChange={true}
                        onCompleted={(data: any) => {
                          const products: Product[] = data.productsById;
                          const product = products.find((_product) => {
                            return _product.id === selectedProductId;
                          });
                          const productInState =
                            designState.products.find(({ productId }) => productId === (product && product.id)) ||
                            designState.products[0];
                          const colorName = productInState.colors[0];
                          if (this.isAllEmpty(designState)) {
                            GTM.push(GTMTypes.USER);
                            GTM.push(GTMTypes.ITEM, {
                              productId: product && product.id,
                              colorName,
                            });
                            GTM.fire(GTMEvents.START_DESIGN);
                          }
                        }}
                      >
                        {({
                          loading: productQueryLoading,
                          error: productQueryError,
                          data: productsData,
                        }: QueryResult) => {
                          if (productQueryLoading || userLoading) {
                            return (
                              <div className="h-full w-full flex items-center justify-center">
                                <Loading size="large" />
                              </div>
                            );
                          }
                          if (
                            productQueryError ||
                            cartQueryError ||
                            userError ||
                            !cartQueryData ||
                            !userData ||
                            !userData.currentUser
                          ) {
                            return (
                              <Alert
                                alerts={[
                                  {
                                    type: 'error',
                                    title: 'Design Tool Error',
                                    content:
                                      'Oops! Something went wrong. We are very sorry. Please try again later or contact us.',
                                  },
                                ]}
                              />
                            );
                          }

                          const cart = cartQueryData.cartByUser;
                          const allProducts: Product[] = productsData.productsById;
                          const products = allProducts.filter((_product) => {
                            return designState.products.find(({ productId }: { productId: string }) => {
                              return productId === _product.id;
                            });
                          });
                          const currentUser = userData.currentUser;

                          // List of products to pass into DesignToolControl that contains state of
                          // whether the product is display and which colors are selected
                          const selectedProducts: any = products.map((selectedProduct, i) => {
                            const active = selectedProductId ? selectedProduct.id === selectedProductId : i === 0;
                            const productInState = designState.products.find(
                              ({ productId }: any) => productId === selectedProduct.id
                            )!;
                            const designIndex = designState.products.find(
                              ({ productId }: { productId: string }) => productId === selectedProduct.id
                            )!.designIndex;
                            const design = designState.designs[designIndex];
                            const colorsSelected = _.keyBy(productInState.colors);

                            return {
                              id: selectedProduct.id,
                              name: selectedProduct.name,
                              active,
                              printTypes: selectedProduct.printTypes,
                              detailTemplates: selectedProduct.detailTemplates,
                              colors: selectedProduct.colors
                                .filter((color) => color.inStock)
                                .map((colorItem) => {
                                  return {
                                    ...colorItem,
                                    active: false, // Selected is now the active state since only one color can be selected
                                    selected: !!colorsSelected[colorItem.name],
                                  };
                                }),
                              sizesQuantities: designState.sizesQuantities,
                              design,
                              designIndex,
                              sides: selectedProduct.sides,
                              userNote: design.userNote,
                            };
                          });

                          // There can currently be only one selectedProduct, TODO: fix ux for selecting products from url parameters vs designCart.
                          const activeSelectedProduct =
                            selectedProducts.find(({ active }: { active: boolean }) => active) || selectedProducts[0];
                          const selectedColor =
                            activeSelectedProduct.colors.find(({ selected }: { selected: boolean }) => selected) ||
                            activeSelectedProduct.colors[0];

                          // Product display
                          const product = products.find(({ id }: { id: string }) => {
                            return id === selectedProductId || id === activeSelectedProduct!.id;
                          })!;

                          // Side displaying
                          const productSide =
                            product.sides.find((side: any) => side.name === selectedSide) || product.sides[0];
                          const productSideIndex = product.sides.findIndex((side: any) => side === productSide);
                          const template =
                            product.designTemplates.find((template: any) => template.side === productSide.name) ||
                            product.designTemplates[0];

                          // Color displaying
                          const item = product.colors.find((color: any) => color.name === selectedColor.name)!;

                          return (
                            <Mutation
                              mutation={SWAP_ORDER_DESIGN_MUTATION}
                              // onCompleted={() => {
                              //   console.log('design tools swap order design mutation onCompleted');
                              //   debugger
                              //   designToolStateContainer.setRedesignSuccess('Successfully submitted design.');
                              //   this.props.history.push({
                              //     pathname: path(routes.app.order.getById, { orderId: this.props.order.id }),
                              //     search: params.email ? `?email=${encodeURIComponent(params.email)}` : '',
                              //   });
                              // }}
                              // onError={(e: any) => {
                              //   designToolStateContainer.setRedesignError('Error submitting design.');
                              // }}
                            >
                              {(swapOrderDesign: MutationFn) => (
                                <Mutation
                                  mutation={CREATE_DESIGNS_MUTATION}
                                  // onCompleted={({ createDesigns }: any) => {
                                  //   console.log('designTools create designs mutation onCompleted');
                                  //   debugger
                                  //   const { order, itemIndexes } = this.props;
                                  //   // TODO: validate all itemIndexes should have the same designId
                                  //   if (order && itemIndexes) {
                                  //     const item = order.quote.items && order.quote.items[itemIndexes[0]];
                                  //     const createdDesign = createDesigns[0];

                                  //     if (item.design) {
                                  //       const swapDesignVariables = {
                                  //         orderId: order.id,
                                  //         oldDesignId: item && item.design.id,
                                  //         newDesignId: createdDesign.id, // Only supports updating 1 design at a time
                                  //       };
                                  //       swapOrderDesign({ variables: swapDesignVariables });
                                  //     }
                                  //   }
                                  // }}
                                  // onError={(e: any) => {
                                  //   designToolStateContainer.setRedesignError('Error submitting design.');
                                  // }}
                                >
                                  {(createDesigns: MutationFn) => {
                                    // TODO: Refacotr all the logic branches for clicking next,
                                    // it is probably better for each page to handle its onNext
                                    // if (designState.redesigning) {
                                    //   handleNext = () => {
                                    //     const { order, itemIndexes } = this.props;
                                    //     if (order && itemIndexes) {
                                    //       const item = order.quote.items && order.quote.items[itemIndexes[0]];
                                    //       const { design } = item;
                                    //       if (design) {
                                    //         const designInputs = designState.designs.map(({ sides }: { sides: any }) => {
                                    //           return { sides, userId: currentUser.id, shareableId: (design as Design).shareableId };
                                    //         });
                                    //         createDesigns({ variables: { designs: designInputs } });
                                    //       }
                                    //     }
                                    //     return;
                                    //   };
                                    // }
                                    const { editingDesign, redesigning } = this.props.designCart.state;
                                    return (
                                      <>
                                        <div className="bgc-white w-full p-relative lg:p-fixed z-10">
                                          <div className="design-tool-container">
                                            <DesignToolHeader
                                              onNextTitle={editingDesign ? 'Update Design' : ''}
                                              submitting={this.props.submitting}
                                              actions={{
                                                redesigning: !!designState.redesigning,
                                              }}
                                              onSaveClick={() => {
                                                if (designState.products.length === 0) return;
                                                designToolStateContainer.showSaveModal(false, this.props.shareable);
                                              }}
                                              onRetrieveClick={
                                                !this.props.shareable
                                                  ? () => {
                                                      designToolStateContainer.setRetrieveDesign(true);
                                                    }
                                                  : undefined
                                              }
                                              onNextClick={(skipSave: boolean) => {
                                                if (designState.products.length === 0) return;

                                                // * Temporary disable out of bounds alert
                                                // const outOfBoundsShapes = listOutOfBoundShapes();
                                                // if (outOfBoundsShapes.length > 0) {
                                                //   designToolStateContainer.setOutOfBounds(outOfBoundsShapes as any);
                                                //   return;
                                                // }

                                                if (this.isAllEmpty(designState)) {
                                                  return designToolStateContainer.setEmptyWarning(true);
                                                }

                                                // * Skipping force save modal, we will do it for the user.
                                                // if (!designCart.state.saved) {
                                                //   return designToolStateContainer.showSaveModal(true, this.props.shareable);
                                                // }

                                                const product = products.find((product) => {
                                                  return product.id === designState.products[0].productId;
                                                })!;
                                                const color = product.colors.find((productColor) => {
                                                  return (
                                                    productColor.name.toLowerCase() ===
                                                    designState.products[0].colors[0].toLowerCase()
                                                  );
                                                })!;

                                                const hasCartDrawerAB = hasAb(currentUser, 'cart-drawer', 'true');
                                                if (!hasCartDrawerAB) {
                                                  if (editingDesign && this.props.onNext) {
                                                    return this.props.onNext({ product, color });
                                                  }
                                                  if (redesigning && this.props.onNext) {
                                                    return this.props.onNext({ product, color });
                                                  }
                                                  return designToolStateContainer.setShowQty(true);
                                                } else {
                                                  if (this.props.onNext) {
                                                    return this.props.onNext({ product, color });
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <div className="w-full flex-1 bgc-blue-50 overflow-hidden lg:overflow-visible lg:pt-4">
                                          {(() => {
                                            listOutOfBoundShapes = this.outOfBounds.bind(this, designState, products);
                                            // // Temporary disable out of bounds alert
                                            // const isOutOfBounds = listOutOfBoundShapes().length > 0;
                                            const isOutOfBounds = false;

                                            const selectedColor =
                                              activeSelectedProduct.colors.find(
                                                ({ selected }: { selected: boolean }) => selected
                                              ) || activeSelectedProduct.colors[0];

                                            const designIndex = designState.products.find(
                                              ({ productId }: { productId: string }) => productId === product.id
                                            )!.designIndex;
                                            const design = designState.designs[designIndex];
                                            const designSide = design.sides.find(
                                              ({ name }: { name: string }) => name === productSide.name
                                            )!;

                                            const fontFamilies: string[] = [DEFAULT_FONT_FAMILY];
                                            if (designSide.shapes) {
                                              for (const shape of designSide.shapes) {
                                                if (
                                                  shape.type === ShapeType.TEXT &&
                                                  shape.fontFamily &&
                                                  fontFamilies.indexOf(shape.fontFamily) === -1 // Ensure no duplicates
                                                ) {
                                                  fontFamilies.push(shape.fontFamily);
                                                }
                                              }
                                            }

                                            return (
                                              <FontsByNamesQuery
                                                query={FONTS_BY_NAMES_QUERY}
                                                variables={{ fontNames: fontFamilies }}
                                              >
                                                {({
                                                  data: fontData = { fontsByNames: [] },
                                                  error: fontError,
                                                  loading: fontLoading,
                                                }) => {
                                                  const images = _.compact(
                                                    product.sides
                                                      .sort(({ name: nameA }, { name: nameB }) => {
                                                        return (
                                                          SIDE_ORDER.findIndex((name) => name === nameA) -
                                                          SIDE_ORDER.findIndex((name) => name === nameB)
                                                        );
                                                      })
                                                      .map(({ name }) => {
                                                        if (product.detailTemplates) {
                                                          const mockupTemplate = product.detailTemplates.find(
                                                            ({ side }) => name === side
                                                          );
                                                          if (mockupTemplate) {
                                                            return {
                                                              label: _.capitalize(name),
                                                              url: url('mockup', mockupRoutes.blank, {
                                                                templateIdentifier: mockupTemplate.id,
                                                                color: item.hex,
                                                                size: 'thumb',
                                                              }),
                                                            };
                                                          }
                                                        }
                                                        return item!.images!.find(({ label }) => {
                                                          return label.toUpperCase() === name;
                                                        })!;
                                                      })
                                                  );

                                                  return (
                                                    <div className="lg:pt-2 h-full">
                                                      {/**  edge case design tool responsiveness */}
                                                      <div className="design-tool-container h-full">
                                                        <div className="grid h-full">
                                                          <div className="col col-1 d-n lg:d-b">
                                                            <SideThumbnails
                                                              active={productSide.name}
                                                              images={images}
                                                              onClick={(side) => {
                                                                this.props.history.replace(
                                                                  updateURLQuery(
                                                                    this.props.location.pathname,
                                                                    params,
                                                                    {
                                                                      side: side.toLowerCase(),
                                                                    },
                                                                    {}
                                                                  )
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="d-b lg:d-n ta-center mt-p5">
                                                            <div
                                                              className="d-ib"
                                                              onClick={() => {
                                                                this.props.history.replace(
                                                                  updateURLQuery(
                                                                    this.props.location.pathname,
                                                                    params,
                                                                    {
                                                                      side: product.sides[
                                                                        (productSideIndex - 1 + product.sides.length) %
                                                                          product.sides.length
                                                                      ]!.name,
                                                                    },
                                                                    {}
                                                                  )
                                                                );
                                                              }}
                                                            >
                                                              <i className="mdi mdi-chevron-left color-primary fs-icon-1p5" />
                                                            </div>
                                                            <div
                                                              className="d-ib capitalize fw-bold color-navy-700 fs-xs"
                                                              style={{ width: '6.5rem' }}
                                                            >
                                                              {_.capitalize(productSide.name)}
                                                            </div>
                                                            <div
                                                              className="d-ib"
                                                              onClick={() => {
                                                                this.props.history.replace(
                                                                  updateURLQuery(
                                                                    this.props.location.pathname,
                                                                    params,
                                                                    {
                                                                      side: product.sides[
                                                                        (productSideIndex + 1 + product.sides.length) %
                                                                          product.sides.length
                                                                      ]!.name,
                                                                    },
                                                                    {}
                                                                  )
                                                                );
                                                              }}
                                                            >
                                                              <i className="mdi mdi-chevron-right color-primary fs-icon-1p5" />
                                                            </div>
                                                          </div>
                                                          {this.renderTools(
                                                            params,
                                                            designIndex,
                                                            designSide,
                                                            productSide,
                                                            template,
                                                            item,
                                                            fontData && fontData.fontsByNames,
                                                            selectedProducts,
                                                            isOutOfBounds
                                                          )}
                                                        </div>
                                                      </div>
                                                      {designToolStateContainer.state.save.modal && (
                                                        <SaveDesignModal
                                                          currentUser={currentUser}
                                                          userId={currentUser.id}
                                                          shareable={this.props.shareable}
                                                          designName={
                                                            designToolStateContainer.state.save.data.designName
                                                          }
                                                          email={
                                                            designToolStateContainer.state.save.data.email ||
                                                            _.get(this.props, 'shareable.email') ||
                                                            currentUser.email
                                                          }
                                                          password={designToolStateContainer.state.save.data.password}
                                                          designs={designState.designs}
                                                          products={designState.products}
                                                          onFieldChange={designToolStateContainer.changeSaveField}
                                                          onCancel={designToolStateContainer.hideSaveModal}
                                                          onSaved={(shareable, mockupUrl) => {
                                                            const { products } = shareable;
                                                            const currentProduct = products[0];
                                                            const userEmail: string | undefined = currentUser.email;
                                                            if (currentProduct) {
                                                              const design: Design = currentProduct.design;
                                                              const productId: string = product.id;
                                                              const designId: string = design.id;
                                                              const itemDesignEmail: string =
                                                                userEmail || shareable.email;
                                                              GTM.push(GTMTypes.USER, {
                                                                designEmail: shareable.email,
                                                              });
                                                              GTM.push(GTMTypes.ITEM, {
                                                                productId,
                                                                designId,
                                                                colorName: selectedColor.name,
                                                                product,
                                                                design,
                                                                mockupUrl,
                                                                itemDesignEmail,
                                                              });
                                                              GTM.push(GTMTypes.CART);
                                                              GTM.fire(GTMEvents.SAVE_DESIGN);
                                                            }
                                                            designToolStateContainer.saveShareable(shareable);
                                                            designCart.assignDesignIds(shareable);
                                                          }}
                                                          loggedIn={userData.currentUser!.role !== 'ANON'}
                                                          selectedProduct={product}
                                                          selectedItem={item}
                                                          selectedTemplate={template}
                                                          selectedColor={selectedColor.name}
                                                          scene={designToolStateContainer.state.save.scene}
                                                          onContinue={() => {
                                                            designToolStateContainer.hideSaveModal();
                                                            designToolStateContainer.setShowQty(true);
                                                          }}
                                                          onChangeScene={designToolStateContainer.changeSaveScene}
                                                          shareUrl={designToolStateContainer.state.save.shareUrl}
                                                          shareCode={designToolStateContainer.state.save.shareCode}
                                                          shareEmail={
                                                            designToolStateContainer.state.save.data.shareEmail
                                                          }
                                                          shareEmails={designToolStateContainer.state.save.shareEmails}
                                                          onAddShareEmail={designToolStateContainer.addShareEmail}
                                                          onRemoveShareEmail={designToolStateContainer.removeShareEmail}
                                                          onDuplicate={designToolStateContainer.setDuplicate}
                                                          duplicate={designToolStateContainer.state.save.duplicate}
                                                          onEmailInUse={designToolStateContainer.setEmailInUse}
                                                          emailInUse={designToolStateContainer.state.save.emailInUse}
                                                          onFieldError={designToolStateContainer.setFieldErrors}
                                                          fieldErrors={designToolStateContainer.state.save.fieldErrors}
                                                          cart={designToolStateContainer.state.save.cart}
                                                        />
                                                      )}
                                                    </div>
                                                  );
                                                }}
                                              </FontsByNamesQuery>
                                            );
                                          })()}
                                        </div>
                                        {/** Only support one product for now */}
                                        {designCart.state.products.length > 0 &&
                                          designToolStateContainer.state.showCart &&
                                          cart && (
                                            <AbTest experiment="cart-drawer">
                                              <AbVariation variation={true}>
                                                <QueryDesignById
                                                  query={QUERY_DESIGN_BY_ID}
                                                  variables={{
                                                    id: designToolStateContainer.state.newCartItem
                                                      ? designToolStateContainer.state.newCartItem.designId
                                                      : '',
                                                  }}
                                                  skip={!designToolStateContainer.state.newCartItem}
                                                >
                                                  {({
                                                    loading: designLoading,
                                                    error: designError,
                                                    data: designData,
                                                  }) => {
                                                    const newDesign = designData && designData.designById;
                                                    return (
                                                      <CartDrawer
                                                        show={designToolStateContainer.state.showCart}
                                                        cart={cart}
                                                        products={allProducts}
                                                        onClose={() => {
                                                          if (cart.items.length === 0) {
                                                            // @ts-ignore - TODO: find a better way to implement this.
                                                            return this.onStartNewDesignActionClick()();
                                                          }
                                                          return designToolStateContainer.setShowCartActionsModal(true);
                                                        }}
                                                        selected={
                                                          newDesign && designToolStateContainer.state.newCartItem
                                                            ? {
                                                                design: newDesign,
                                                                productId:
                                                                  designToolStateContainer.state.newCartItem.productId,
                                                                color: designToolStateContainer.state.newCartItem.color,
                                                                onAddToCart: () => {
                                                                  if (!designCart.state.editingDesign) {
                                                                    designCart.resetSizesQuantities();
                                                                  }
                                                                  designToolStateContainer.removeNewCartItem();
                                                                },
                                                                sizeQuantities: designCart.state.sizesQuantities,
                                                              }
                                                            : undefined
                                                        }
                                                        progressBar={
                                                          designToolStateContainer.state.showProgressBar
                                                            ? {
                                                                seconds: 5,
                                                                completed: false,
                                                                onComplete: () => {},
                                                              }
                                                            : undefined
                                                        }
                                                        onEditDesignClick={() => {
                                                          designToolStateContainer.setShowCart(false, false);
                                                        }}
                                                        onOverlayClick={(
                                                          event: React.MouseEvent<HTMLDivElement, MouseEvent>
                                                        ) => {
                                                          return designToolStateContainer.setShowCartActionsModal(true);
                                                        }}
                                                        onAddNewProduct={(
                                                          event: React.MouseEvent<HTMLDivElement, MouseEvent>,
                                                          cartProductState: CartProductState
                                                        ) => {
                                                          const { cartProduct } = cartProductState;
                                                          designCart.setSourceProductDesignInfo(
                                                            cartProduct.productId,
                                                            cartProduct.designId
                                                          );
                                                          return this.props.history.push(
                                                            path(routes.app.designTool, {
                                                              productModal: 'true',
                                                              showMockups: 'true',
                                                            })
                                                          );
                                                        }}
                                                      />
                                                    );
                                                  }}
                                                </QueryDesignById>
                                                {designToolStateContainer.state.showCartActionsModal && (
                                                  <CartActionsModal
                                                    onModalClose={(
                                                      event: React.MouseEvent<HTMLElement, MouseEvent>
                                                    ) => {
                                                      return designToolStateContainer.setShowCartActionsModal(false);
                                                    }}
                                                    cartActionButtons={[
                                                      {
                                                        label: 'Continue Designing',
                                                        onClick: this.onEditDesignActionClick(cart, allProducts),
                                                      },
                                                      {
                                                        label: 'Start New Design',
                                                        onClick: this.onStartNewDesignActionClick(),
                                                      },
                                                      {
                                                        label: 'Back to Cart',
                                                        onClick: this.onBackToCartActionClick(),
                                                      },
                                                    ]}
                                                  ></CartActionsModal>
                                                )}
                                              </AbVariation>
                                              <AbVariation variation={false}>
                                                <QuantitySelector
                                                  productId={designCart.state.products[0].productId}
                                                  designId={designCart.state.products[0].designId!}
                                                  colorNames={designCart.state.products[0].colors}
                                                  show={designToolStateContainer.state.showQty}
                                                  sizesQuantities={designCart.state.sizesQuantities}
                                                  onBackButtonClick={(ev: React.MouseEvent<HTMLButtonElement>) => {
                                                    return designToolStateContainer.setShowQty(false);
                                                  }}
                                                  onColorSelected={(color: string) => {
                                                    return designCart.addProductColor(
                                                      designCart.state.products[0].productId,
                                                      color
                                                    );
                                                  }}
                                                  onCartItemRemoved={(color: string) => {
                                                    return designCart.removeProductColor(
                                                      designCart.state.products[0].productId,
                                                      color
                                                    );
                                                  }}
                                                  onAddToCart={() => {
                                                    designCart.setContinueDesignState(
                                                      designCart.state.products,
                                                      designCart.state.designs
                                                    );
                                                  }}
                                                  onSavedDesign={(shareable: Shareable, mockupUrl: string) => {
                                                    const product = shareable.products[0];
                                                    designCart.setSourceProductDesignInfo(
                                                      product.productId,
                                                      product.designId
                                                    );
                                                    return designCart.assignDesignIds(shareable);
                                                  }}
                                                  currentUser={currentUser}
                                                  designName={designToolStateContainer.state.save.data.designName}
                                                  designState={designState}
                                                  selectedColor={item}
                                                  selectedProduct={product}
                                                  selectedTemplate={template}
                                                ></QuantitySelector>
                                              </AbVariation>
                                            </AbTest>
                                          )}

                                        {designToolStateContainer.state.showSaving && (
                                          <Modal>
                                            <Loading size="large" />
                                          </Modal>
                                        )}

                                        {designToolStateContainer.state.upload && (
                                          <ProgressModal
                                            uploaded={designToolStateContainer.state.upload.uploaded}
                                            total={designToolStateContainer.state.upload.total}
                                            startedAt={designToolStateContainer.state.upload.startedAt}
                                            onCancel={() => designToolStateContainer.unsetUpload()}
                                          />
                                        )}
                                        {designToolStateContainer.state.uploadError && (
                                          <FailUploadModal
                                            error={designToolStateContainer.state.uploadError}
                                            onDismiss={designToolStateContainer.dismissUploadError}
                                            onReupload={(e) => {
                                              if (e.target.files) {
                                                this.setState({ file: e.target.files[0] });
                                              }
                                            }}
                                          />
                                        )}
                                        {designToolStateContainer.state.redesignError && (
                                          <div className="p-absolute mx-auto pin-x w-1/3 design-tool-notification">
                                            <Notif
                                              type="error"
                                              onClose={() => designToolStateContainer.setRedesignError(null)}
                                            >
                                              {designToolStateContainer.state.redesignError}
                                            </Notif>
                                          </div>
                                        )}
                                        {designToolStateContainer.state.redesignSuccess && (
                                          <div className="p-absolute mx-auto pin-x w-1/3 design-tool-notification">
                                            <Notif
                                              type="success"
                                              onClose={() => designToolStateContainer.setRedesignSuccess(null)}
                                            >
                                              {designToolStateContainer.state.redesignSuccess}
                                            </Notif>
                                          </div>
                                        )}
                                        {designToolStateContainer.state.outOfBounds &&
                                          (() => {
                                            const close = () => {
                                              this.props.history.replace(
                                                updateURLQuery(
                                                  this.props.location.pathname,
                                                  params,
                                                  {
                                                    product: designToolStateContainer.state.outOfBounds![0]!.productId,
                                                    side: designToolStateContainer.state.outOfBounds![0]!.side.toLowerCase(),
                                                  },
                                                  {}
                                                )
                                              );
                                              designToolStateContainer.selectShape(
                                                designToolStateContainer.state.outOfBounds![0]!.shapes[0]!
                                              );
                                              designToolStateContainer.setOutOfBounds(null);
                                            };
                                            return (
                                              <Modal>
                                                <Alert
                                                  alerts={[
                                                    {
                                                      type: 'warning',
                                                      title: 'Artwork Outside of Print Area',
                                                      content:
                                                        'We’ve detected an artwork outside of the print area. All visuals outside of print area will be cropped.',
                                                      onClose: close,
                                                      secondaryAction: 'Back To Design',
                                                      onSecondaryAction: close,
                                                      primaryAction: 'Proceed',
                                                      onPrimaryAction: () => {
                                                        designToolStateContainer.setOutOfBounds(null);
                                                        designToolStateContainer.hideOutOfBoundsWarning(true);

                                                        if (this.props.onNext) {
                                                          return this.props.onNext();
                                                        }

                                                        if (handleNext) {
                                                          return handleNext();
                                                        }

                                                        // if (!designCart.state.saved) {
                                                        //   return designToolStateContainer.showSaveModal(true, this.props.shareable);
                                                        // }

                                                        designToolStateContainer.setShowQty(true);
                                                      },
                                                    },
                                                  ]}
                                                />
                                              </Modal>
                                            );
                                          })()}
                                        {designToolStateContainer.state.emptyWarning && (
                                          <Modal>
                                            <Alert
                                              alerts={[
                                                {
                                                  type: 'warning',
                                                  title: 'Nothing to Print',
                                                  content: 'You did not add anything to your design',
                                                  onClose: () => {
                                                    designToolStateContainer.setEmptyWarning(false);
                                                  },
                                                  secondaryAction: 'Back To Design',
                                                  onSecondaryAction: () => {
                                                    designToolStateContainer.setEmptyWarning(false);
                                                  },
                                                },
                                              ]}
                                            />
                                          </Modal>
                                        )}
                                        {this.props.alerts && this.props.alerts.length && (
                                          <Modal>
                                            <Alert alerts={this.props.alerts} />
                                          </Modal>
                                        )}
                                        {params.productModal && (
                                          <AbTest experiment="cart-drawer">
                                            <AbVariation variation={true}>
                                              <AddProductModal
                                                showMockups={params.showMockups}
                                                designCart={designCart}
                                                onClose={() => this.onToggleProductModal(false)}
                                                onAddProduct={(_product) => {
                                                  designCart.addProduct(
                                                    _product,
                                                    _product.colors[0].name,
                                                    products as DesignCartProductInput[]
                                                  );
                                                  this.props.history.replace(
                                                    updateURLQuery(
                                                      this.props.location.pathname,
                                                      params,
                                                      {
                                                        product: _product.id,
                                                        color: _product.colors[0].name,
                                                        productModal: null,
                                                      },
                                                      {}
                                                    )
                                                  );
                                                }}
                                                onAddToCart={(
                                                  product: Product,
                                                  design: Design,
                                                  colorName: string,
                                                  previousProduct: Product
                                                ) => {
                                                  const color: ProductColor =
                                                    product.colors.find((productColor) => {
                                                      return (
                                                        productColor.name.toLowerCase() === colorName.toLowerCase()
                                                      );
                                                    }) || product.colors[0];
                                                  designCart.restoreFromCartProduct({
                                                    product,
                                                    design,
                                                    colorName,
                                                  });
                                                  designCart.addProduct(
                                                    product as DesignCartProductInput,
                                                    colorName,
                                                    products as DesignCartProductInput[],
                                                    false,
                                                    previousProduct as DesignCartProductInput
                                                  );
                                                  this.props.history.replace(
                                                    updateURLQuery(
                                                      this.props.location.pathname,
                                                      params,
                                                      {
                                                        product: product.id,
                                                        color: colorName,
                                                        productModal: null,
                                                      },
                                                      {}
                                                    )
                                                  );
                                                  this.props.onNext && this.props.onNext({ product, color });
                                                }}
                                                onEditDesign={(
                                                  product: Product,
                                                  design: Design,
                                                  colorName: string,
                                                  previousProduct: Product
                                                ) => {
                                                  designCart.restoreFromCartProduct({
                                                    product,
                                                    design,
                                                    colorName,
                                                  });
                                                  designCart.addProduct(
                                                    product as DesignCartProductInput,
                                                    colorName,
                                                    products as DesignCartProductInput[],
                                                    false,
                                                    previousProduct as DesignCartProductInput
                                                  );
                                                  designToolStateContainer.setShowCart(false, false);
                                                  this.props.history.replace(
                                                    updateURLQuery(
                                                      this.props.location.pathname,
                                                      params,
                                                      {
                                                        product: product.id,
                                                        color: colorName,
                                                        productModal: null,
                                                      },
                                                      {}
                                                    )
                                                  );
                                                }}
                                              />
                                            </AbVariation>
                                            <AbVariation variation={false}>
                                              <AddProductModal
                                                showMockups={params.showMockups}
                                                designCart={designCart}
                                                onClose={() => this.onToggleProductModal(false)}
                                                onAddProduct={(_product) => {
                                                  const color = _product.colors.find(
                                                    (color: ProductColor) => color.inStock
                                                  );
                                                  designCart.addProduct(
                                                    _product,
                                                    color.name,
                                                    products as DesignCartProductInput[]
                                                  );
                                                  this.props.history.replace(
                                                    updateURLQuery(
                                                      this.props.location.pathname,
                                                      params,
                                                      {
                                                        product: _product.id,
                                                        color: color.name,
                                                        productModal: null,
                                                      },
                                                      {}
                                                    )
                                                  );
                                                }}
                                                onAddToCart={(product: any, design: Design, colorName: string) => {
                                                  designCart.addProduct(
                                                    product,
                                                    colorName,
                                                    products as DesignCartProductInput[]
                                                  );
                                                  this.props.history.replace(
                                                    updateURLQuery(
                                                      this.props.location.pathname,
                                                      params,
                                                      {
                                                        product: product.id,
                                                        color: colorName,
                                                        productModal: null,
                                                      },
                                                      {}
                                                    )
                                                  );
                                                  return designToolStateContainer.setShowQty(true);
                                                }}
                                                onEditDesign={(product: any, design: Design, colorName: string) => {
                                                  this.props.history.replace(
                                                    updateURLQuery(
                                                      this.props.location.pathname,
                                                      params,
                                                      {
                                                        product: product.id,
                                                        color: colorName,
                                                        productModal: null,
                                                      },
                                                      {}
                                                    )
                                                  );
                                                }}
                                              />
                                            </AbVariation>
                                          </AbTest>
                                        )}
                                        {designToolStateContainer.state.retrieveDesign && (
                                          <RetrieveDesignModal
                                            onSelectDesign={(shareable: Shareable) => {
                                              const paramsUpdate: { [key: string]: string } = {};
                                              const selectedProduct =
                                                _.find(shareable.products, 'selected') || shareable.products[0];
                                              if (selectedProduct) {
                                                paramsUpdate.product = selectedProduct.productId;
                                                const selectedColor =
                                                  _.find(selectedProduct.colors, 'selected') ||
                                                  selectedProduct.colors[0];
                                                if (selectedColor) {
                                                  paramsUpdate.color = selectedColor.name;
                                                }
                                              }

                                              designCart.restoreFromShareable(shareable);
                                              designToolStateContainer.changeSaveField('designName', shareable.name);
                                              designToolStateContainer.setRetrieveDesign(false);
                                              this.props.history.replace(
                                                updateURLQuery(this.props.location.pathname, params, paramsUpdate, {})
                                              );
                                            }}
                                            onClose={() => designToolStateContainer.setRetrieveDesign(false)}
                                          />
                                        )}
                                      </>
                                    );
                                  }}
                                </Mutation>
                              )}
                            </Mutation>
                          );
                        }}
                      </Query>
                    )}
                  </CartQuery>
                )}
              </div>
            </WithNavigationPrompt>
          );
        }}
      </QueryCurrentUser>
    );
  }
}

const DesignToolWithApollo = withApollo(DesignTool);

function DesignTool2(props: PageProps) {
  return <DesignToolWithApollo {...props} />;
}

export default DesignTool2;
