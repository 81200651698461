import * as React from 'react';
import Button from 'inkp-components/dist/Components/Button';

export default function(props: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <div className="p-absolute pin-b pin-r mr-2 mb-1p5">
      <Button
        type="default"
        size="md"
        corners="full"
        color="secondary"
        shadowEnabled
        onClick={props.onClick}
        noXPadding
      >
        <div className="px-p75">
          <i className="mdi mdi-chevron-up color-navy fs-icon-1p5" />
        </div>
      </Button>
    </div>
  );
}
