import * as React from 'react';
import classnames from 'classnames';

import config from 'inkp-config';

export function HeaderButton(props: {
  children: React.ReactNode;
  className?: string;
  icon?: string;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <a className={classnames(
        {
          'cursor-pointer bgc-gray-50:hover': !props.disabled,
          'cursor-default color-purple-300': props.disabled,
        },
        'd-n md:d-b fs-md py-p5 px-1p25 br-full overflow-hidden select-none outline-0:focus',
        props.className,
      )}
      onClick={props.onClick}
      href={props.href}
    >
      <div className="flex items-center">
        {props.icon && <i className={`mdi mdi-${props.icon} fs-icon-1p5 mr-p25`} />}
        <div className="fs-md fw-bold">{props.children}</div>
      </div>
    </a>
  );
}

export function MobileHeaderButton(props: {
  text: string;
  icon?: string;
  href?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void
}) {
  return (
    <a className={classnames(
        {
          'color-purple-300': props.disabled,
        },
        "d-b md:d-n py-p5",
        props.className,
      )}
      href={props.href}
      onClick={props.onClick}
    >
      {props.icon &&
        <div className="px-1 pb-p25">
          <i className={`mdi mdi-${props.icon} fs-icon-1p5`} />
        </div>
      }
      <div className="ta-center" style={{ lineHeight: '12px',  fontSize: '10px' }}>
        {props.text}
      </div>
    </a>
  );
}

