import gql from 'graphql-tag';
import * as _ from 'lodash';
import { url, routes, path } from 'inkp-routes/public';
import mockupRoutes from 'inkp-mockup-sdk/routes';

import { Props as ProductCardProps, NewProductActions } from 'inkp-components/dist/Components/ProductCard';
import { Product, ProductCategory, ProductColor, DesignTemplate } from 'inkp-product-sdk/types.g';
import { prettyProductSize } from 'inkp-product-sdk/product';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';
import { Design } from 'inkp-design-sdk/types.g';

import { CatalogNewProductActions } from '../Catalog';
import { DESIGN_TEMPLATE_DATA_FRAGMENT } from '../../graphql/products';
import { sortSizes, getProductIcons, SIDES_BY_ORDER } from '../../util/Product';

import { Filters } from './';

export const CATALOG_QUERY = gql`
  query ProductFilter(
    $brands: [String!]
    $colors: [String!]
    $sizes: [String!]
    $styles: [String!]
    $categories: [ProductCategoryInput!]
    $genders: [String!]
    $speeds: [SHIPPING_SPEED_ENUM!]
    $sortBy: [SortSearchProduct!]
    $page: Int!
    $perPage: Int!
  ) {
    filterProducts(
      brands: $brands
      colors: $colors
      sizes: $sizes
      styles: $styles
      genders: $genders
      speeds: $speeds
      sortBy: $sortBy
      categories: $categories
      page: $page
      perPage: $perPage
    ) {
      items {
        id
        name
        detailTemplates {
          id
          side
        }
        designTemplates {
          id
          side
          data {
            ...DesignTemplateDataFragment
          }
        }
        colors {
          name
          hex
          inStock
          startingPrice
          class
          images {
            label
            url
          }
          sizes
        }
        image {
          label
          url
        }
        categories {
          scope
          name
        }
        sides {
          name
          printZonesObjects {
            id
            size {
              width
              height
            }
            position {
              left
              right
              top
              bottom
            }
          }
        }
      }
      filters {
        brands
        colors
        sizes
        styles
        genders
        speeds
      }
      pageMetadata {
        count
        page
        pages
      }
    }
  }
  ${DESIGN_TEMPLATE_DATA_FRAGMENT}
`;

const CATEGORY_SCOPE_TYPE = 'type';
const UNCATEGORIZED_LABEL = 'uncategorized';
const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;
const DEFAULT_MOCKUP_SIDE: string = 'FRONT';
const DEFAULT_MOCK_SIZE: string = 'medium';

export function mapCatalogProducts({
  products,
  filterColors,
  design,
  previousProduct,
  newProductActions,
}: {
  products: Product[];
  filterColors: string[];
  design?: Design;
  previousProduct?: Product;
  newProductActions?: CatalogNewProductActions;
}): ProductCardProps[] {
  return products.map((product) => {
    const { name, image, colors, id, categories } = product;
    const category = categories.find((item: ProductCategory) => item.scope === CATEGORY_SCOPE_TYPE);
    const categoryName = category ? category.name : UNCATEGORIZED_LABEL;

    let imageUrl = image.url;

    const eligibleColors: ProductColor[] = colors.filter((productColor: ProductColor) => {
      if (!productColor.inStock) return false;
      if (filterColors.length === 0) return true;
      return filterColors.indexOf(productColor.class.toLowerCase()) > -1;
    });

    if (eligibleColors[0] && eligibleColors[0].images && eligibleColors[0].images.length) {
      const sortedImages = _.sortBy(eligibleColors[0].images, (image) => {
        return SIDES_BY_ORDER.indexOf(image.label);
      });
      imageUrl = sortedImages[0].url;
    }
    const hex = eligibleColors[0] ? eligibleColors[0].hex : 'FFFFF';
    const color = eligibleColors[0] ? eligibleColors[0].name : 'White';

    const lowestPrice = eligibleColors.reduce((prev, current) => {
      if (current.startingPrice < prev) {
        return current.startingPrice;
      }
      return prev;
    }, Infinity);

    if (product.detailTemplates) {
      const mockupTemplate = product.detailTemplates.find(({ side }) => {
        return side === DEFAULT_MOCKUP_SIDE;
      });

      if (mockupTemplate) {
        imageUrl = url('mockup', mockupRoutes.blank, {
          templateIdentifier: mockupTemplate.id,
          color: hex,
          size: DEFAULT_MOCK_SIZE,
        });
      }
    }

    if (design) {
      const { id: designId } = design;

      const designTemplate: DesignTemplate | undefined = product.designTemplates.find(
        (designTemplate) => designTemplate.side === DEFAULT_MOCKUP_SIDE
      );
      const templateIdentifier = designTemplate ? designTemplate.id : '';
      if (templateIdentifier) {
        imageUrl = url('mockup', mockupRoutes.mockup, {
          designId,
          templateIdentifier,
          color: hex,
          side: DEFAULT_MOCKUP_SIDE,
          size: DEFAULT_MOCK_SIZE,
        });
      }
    }

    const { tag, topRightIcon } = getProductIcons(id);

    let productCartProps: ProductCardProps = {
      id,
      name,
      image: imageUrl,
      price: Math.round(lowestPrice / ITEMS_NUMBER_FOR_STARTING_PRICE),
      colorCount: product.colors.length,
      pathname: path(routes.app.product.detail, { category: categoryName, id }),
      tag,
      topRightIcon,
      color,
      hex,
    };

    if (design && previousProduct && newProductActions) {
      let updatedProductActions: NewProductActions;
      updatedProductActions = {
        onAddToCartClick: newProductActions.onAddToCartClick({ product, design, colorName: color, previousProduct }),
        onEditDesignClick: newProductActions.onEditDesignClick({ product, design, colorName: color, previousProduct }),
      };
      productCartProps = {
        ...productCartProps,
        newProductActions: updatedProductActions,
      };
    }

    return productCartProps;
  });
}

export const SORT_OPTIONS = [
  { value: 'popular', display: 'Most Popular' },
  { value: 'low-to-high', display: '$ Low → High' },
  { value: 'high-to-low', display: '$ High → Low' },
  { value: 'newest', display: 'Newest' },
];

// TODO: Do not expose field sorting in frontend
// sort option to field and order should be mapped in the backend
export const SORT_VALUES = {
  popular: { field: 'rank', order: 1 },
  'low-to-high': { field: 'colors.startingPrice', order: 1 },
  'high-to-low': { field: 'colors.startingPrice', order: -1 },
  newest: { field: 'createdAt', order: -1 },
};

export function genderFilters(tags: string[]) {
  return _.uniq(_.flatten(tags.map((tag) => tag.split(',')))).map((tag) => ({
    display: tag,
    value: tag,
  }));
}

export function sizeFilters(sizes: string[]) {
  return sortSizes(sizes).map((size) => ({
    display: prettyProductSize(size.toUpperCase()),
    value: size,
  }));
}

const PRETTY_SPEED: { [key: string]: string } = {
  [SHIPPING_SPEED_ENUM.THREE_DAY]: 'Lightning 3 Day',
  [SHIPPING_SPEED_ENUM.FOUR_DAY]: 'Standard 4 Day',
  [SHIPPING_SPEED_ENUM.NINE_DAY]: 'Stress Free 9 Day',
};

export function speedFilters(speeds: string[]) {
  return speeds.map((speed) => ({
    display: PRETTY_SPEED[speed] || '',
    value: speed,
  }));
}

export function colorFilters(colors: string[]) {
  return colors.map((color) => ({
    display: _.capitalize(color),
    value: color,
    color,
  }));
}

export function brandFilters(brands: string[]) {
  return brands.sort().map((brand) => ({
    display: brand,
    value: brand,
  }));
}

export function newFilters(oldFilters: Filters, type: string, value: string): Filters {
  //@ts-ignore
  if (_.isArray(oldFilters[type])) {
    //@ts-ignore
    const vals: string[] = oldFilters[type];
    if (vals.indexOf(value) > -1) {
      return Object.assign({}, oldFilters, {
        [type]: _.reject(vals, (item) => item === value),
      });
    }

    return Object.assign({}, oldFilters, {
      [type]: vals.concat([value]),
    });
  }

  return Object.assign({}, oldFilters, {
    [type]: value,
  });
}
