import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

import InputRange from 'inkp-components/dist/Components/InputRange';
import ColorPicker from 'inkp-components/dist/Components/ColorPicker';
import RightSidebar from 'inkp-components/dist/Components/RightSidebar';
import RoundedButton from 'inkp-components/dist/Components/RoundedButton';
import LayerTool from '../../LayerTool';
import FontSelector, { FontObject } from '../../FontSelector';
import ActionButton from '../ActionButton';
import MenuSection from '../MenuSection';
import AddTextTool from '../../DesignToolControl/AddTextTool';

import { TEXT_COLORS, DEGREE_OPTIONS } from '../constants';
import { computeCenterPosition, toNumber } from '../utils';
import { PrintZoneProps } from '../../../../components/Canvas/PrintZone';
import { FontCategory } from '../../../../interfaces';

const SECTION_HEADERS = {
  default: 'Edit Text',
  font: 'Font',
  color: 'Color',
  align: 'Align',
  rotate: 'Rotate',
  layer: 'Layers',
  text: 'Update Text',
};

const COLOR_HEX_MAP = _.keyBy(TEXT_COLORS, 'name');

interface Props {
  /** Function that handles the shape change handler */
  onChange: (newShape: any) => void;

  /* Shape data */
  shape: any;

  /** Mobile section */
  mobileSection: string | null;

  /** Section change handler */
  onChangeSection: (section: string | null) => void;

  /** Cancel handler */
  onCancel: () => void;

  /** Print zone data */
  mainPrintZone: PrintZoneProps;

  /** Class Name **/
  className?: string;

  /** Edit layer button handler */
  onShowLayer: () => void;

  /** Layer hide handler */
  onHideLayer: () => void;

  /** Flag for layer display */
  showLayer?: boolean;

  /** List of shapes in the design for layering */
  shapes: any[];

  /** Layer change handler */
  onNewIndex: (data: { id: string; zIndex: number }) => void;
}

interface State {}

class TextMenu extends React.Component<Props, State> {
  state = {
    showFontMenu: false,
  }

  updateRotation = (rotation: number) => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        rotation,
      })
    );
  };

  updateColor = (color: string) => {
    const hex = COLOR_HEX_MAP[color] && COLOR_HEX_MAP[color].hex || '000000';
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        color: `#${hex}`,
      })
    );
  };

  updateAlignment = (align: string) => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        align,
      })
    );
  };

  updateText = (text: string) => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        text,
      })
    );
  };

  updateFontFamily = (fontObject: FontObject) => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        fontFamily: fontObject.name,
      })
    );
  };

  updateFontCategory = (fontCategory: FontCategory) => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        fontCategory: fontCategory.name,
      })
    );
  };

  showFontMenu = (show: boolean) => {
    this.setState({ showFontMenu: show });
  }

  updatePosition = (position: string) => {
    const { shape, mainPrintZone } = this.props;
    const { x, y } = computeCenterPosition(position, shape, mainPrintZone);
    this.props.onChange(Object.assign({}, this.props.shape, {
      x,
      y,
    }));
  }

  render() {
    const { showLayer, mobileSection } = this.props;
    return (
      <div className={classnames(this.props.className)}>
        <div className="flex p-p75 lg:p-0 bwb-1 color-navy bc-gray lg:bwb-0">
          <div
            className={classnames({ 'vis-hidden': !mobileSection }, 'lg:d-n w-1/4')}
            onClick={() => this.props.onChangeSection(null)}
          >
            <i className="mdi mdi-chevron-left fs-md-icon lg:d-n" /> <span className="fs-md fw-bold">Back</span>
          </div>
          <h2 className="flex-1 ta-center fs-md fw-bold lg:d-n w-1/2">
            {SECTION_HEADERS[mobileSection || 'default']}
          </h2>
          <h2 className="flex-1 d-n lg:d-b fs-lg fw-extra-bold">{SECTION_HEADERS.default}</h2>
          <div className="lg:d-n ta-right w-1/4">
            <div className="d-ib" onClick={this.props.onCancel}>
              <i className="mdi mdi-close fs-md-icon lg:d-n" />
            </div>
          </div>
        </div>
        <style jsx={true}>{`
          .scrollable {
            max-height: 75vh;
            overflow-y: scroll;
          }

          @media only screen and (min-width: 1024px) {
            .scrollable {
              max-height: none;
              overflow: visible;
            }
          }
        `}</style>
        <div className="scrollable">
          <div className={classnames({ 'mb-3': !mobileSection }, 'lg:mb-0')}>
            <MenuSection
              title="Update Text"
              section="text"
              hideDesktopTitle={true}
              selectedSection={mobileSection}
              onClick={() => this.props.onChangeSection('text')}
            >
              <AddTextTool
                className={classnames({ 'd-n': !mobileSection || mobileSection !== 'text' }, 'lg:flex')}
                label="Update Text"
                text={this.props.shape && this.props.shape.text}
                onUpdateText={this.updateText}
              />
            </MenuSection>
            <MenuSection
              title="Font"
              section="font"
              selectedSection={mobileSection}
              onClick={() => this.props.onChangeSection('font')}
            >
              <RoundedButton
                fontColor="navy"
                backgroundColor="blue-50"
                className="bc-navy-500 bw-1 mt-1 mb-1p5 d-n lg:d-b"
                onClick={() => this.showFontMenu(true)}
              >
                {this.props.shape.fontFamily}
                <i className="mdi mdi-chevron-right fs-md-icon float-right" />
              </RoundedButton>
              {mobileSection === 'font' && (
                <FontSelector
                  className={"flex lg:d-n"}
                  activeFont={this.props.shape.fontFamily}
                  activeCategory={this.props.shape.fontCategory}
                  exampleText={this.props.shape.text}
                  onCategorySelect={this.updateFontCategory}
                  onFontSelect={this.updateFontFamily}
                  categoryListOpen={true}
                  toggleListOpen={() => null}
                />
              )}
              <RightSidebar
                className={"d-n lg:d-b"}
                title="Font"
                width="446px"
                show={this.state.showFontMenu}
                showHeader={true}
                onCloseButtonClick={() => this.showFontMenu(false)}
                onOverlayClick={() => this.showFontMenu(false)}
              >
                <FontSelector
                  activeFont={this.props.shape.fontFamily}
                  activeCategory={this.props.shape.fontCategory}
                  exampleText={this.props.shape.text}
                  onCategorySelect={this.updateFontCategory}
                  onFontSelect={this.updateFontFamily}
                  categoryListOpen={true}
                  toggleListOpen={() => null}
                />
              </RightSidebar>
            </MenuSection>
            <MenuSection
              title="Colors"
              section="color"
              selectedSection={mobileSection}
              onClick={() => this.props.onChangeSection('color')}
            >
              <div
                className={classnames(
                  { 'd-n': !mobileSection || mobileSection !== 'color' },
                  'lg:flex mx-1 lg:mx-0'
                )}
              >
                <ColorPicker
                  showTooltip={true}
                  onClick={this.updateColor}
                  colors={TEXT_COLORS.map((color) => {
                    color.active = this.props.shape.color.replace('#', '') === color.hex;
                    return color;
                  })}
                  limit={32}
                  colorPerRow={mobileSection && mobileSection === 'color' ? 8 : 10}
                  onMouseEnter={() => null}
                  onMouseLeave={() => null}
                />
              </div>
            </MenuSection>
            <MenuSection
              title="Align"
              section="align"
              selectedSection={mobileSection}
              onClick={() => this.props.onChangeSection('align')}
            >
              <div
                className={classnames(
                  {
                    'd-n': !mobileSection || mobileSection !== 'align',
                    flex: mobileSection && mobileSection === 'align',
                  },
                  'lg:flex -mx-p5'
                )}
              >
                <div className="w-1/5 px-p5">
                  <ActionButton
                    text="V-Center"
                    value="vertical-center"
                    icon={<i className="mdi mdi-format-align-middle fs-icon-1p5" />}
                    active={false}
                    onClick={this.updatePosition}
                    textStyles={{ whiteSpace: 'nowrap' }}
                  />
                </div>
                <div className="w-1/5 px-p5">
                  <ActionButton
                    text="H-Center"
                    value="horizontal-center"
                    icon={<i className="mdi mdi-format-align-middle mdi-rotate-90 fs-icon-1p5" />}
                    active={false}
                    onClick={this.updatePosition}
                    textStyles={{ whiteSpace: 'nowrap' }}
                  />
                </div>
                <div className="w-1/5 px-p5">
                  <ActionButton
                    text="Left"
                    value="left"
                    icon={<i className="material-icons md-24">format_align_left</i>}
                    active={this.props.shape.align === 'left'}
                    onClick={this.updateAlignment}
                  />
                </div>
                <div className="w-1/5 px-p5">
                  <ActionButton
                    text="Center"
                    value="center"
                    icon={<i className="material-icons md-24">format_align_center</i>}
                    active={this.props.shape.align === 'center'}
                    onClick={this.updateAlignment}
                  />
                </div>
                <div className="w-1/5 px-p5">
                  <ActionButton
                    text="Right"
                    value="right"
                    icon={<i className="material-icons md-24">format_align_right</i>}
                    active={this.props.shape.align === 'right'}
                    onClick={this.updateAlignment}
                  />
                </div>
              </div>
            </MenuSection>
            <MenuSection
              title="Rotate"
              section="rotate"
              selectedSection={mobileSection}
              onClick={() => this.props.onChangeSection('rotate')}
            >
              <div
                className={classnames(
                  { 'd-n': !mobileSection || mobileSection !== 'rotate' },
                  'lg:d-b'
                )}
              >
                <InputRange
                  value={this.props.shape.rotation}
                  options={DEGREE_OPTIONS}
                  step={1}
                  min={-180}
                  max={180}
                  unit={"degrees"}
                  showTicks={true}
                  substractFunction={() => this.updateRotation(Math.max(-180, this.props.shape.rotation - 1))}
                  addFunction={() => this.updateRotation(Math.min(180, this.props.shape.rotation + 1))}
                  onChange={(e: any) => this.updateRotation(toNumber(e.target.value) || 0)}
                />
              </div>
            </MenuSection>
            {this.props.shapes.length > 1 && (
              <MenuSection
                title="Layers"
                section="layer"
                selectedSection={mobileSection}
                onClick={() => this.props.onChangeSection("layer")}
              >
                <div
                  className="d-n lg:d-ib br-full mt-1 py-p5 px-1 bw-1 bc-navy-500 bgc-gray-50 bgc-navy-100:hover cursor-pointer fw-bold"
                  onClick={this.props.onShowLayer}
                >
                  Edit Layers <i className="mdi mdi-chevron-right fs-icon-1p5" />
                </div>
                {(showLayer || mobileSection === 'layer') && (
                  <LayerTool
                    shapes={this.props.shapes}
                    onNewIndex={this.props.onNewIndex}
                    onHide={(e: any) => {
                      e.stopPropagation();
                      this.props.onHideLayer();
                    }}
                  />
                )}
              </MenuSection>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TextMenu;
