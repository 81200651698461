import * as React from 'react';
import classnames from 'classnames';

const MobileFilterMenuHeader: React.SFC<{
  resettable?: boolean;
  onClose: () => void;
  onReset: () => void;
  header: string;
}> = (props) => (
  <div className="flex items-center p-1 bwb-1 bc-gray">
    <div className="w-1/3">
      <div className={classnames('fw-bold', {
        'color-navy cursor-pointer':  props.resettable,
        'color-navy-200': !props.resettable,
      })} onClick={props.onReset}>
        Reset
      </div>
    </div>
    <div className="w-1/3 ta-center">
      <h3 className="fs-lg fw-bold">
        {props.header}
      </h3>
    </div>
    <div className="w-1/3 ta-right">
      <i className="mdi mdi-close fs-icon-1p5 cursor-pointer" onClick={props.onClose} />
    </div>
  </div>
);

export default MobileFilterMenuHeader;
