import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { url, routes } from 'inkp-routes';
//@ts-ignore - No types for this module
import * as useImage from 'use-image';
import mockupRoutes from 'inkp-mockup-sdk/routes';

import { Product, DesignTemplate } from 'inkp-product-sdk/types.g';
import { Design, DesignSide } from 'inkp-design-sdk/types.g';
import { Font } from '../../../interfaces';
import { PrintArea } from '../../../interfaces/Canvas';
import CanvasDimensionsProvider, { CanvasDimensions } from '../../../pages/DesignTool/CanvasDimensionsProvider';
import WithLazyCustomFont from 'inkp-components/dist/HOC/WithLazyCustomFont';
import Canvas, { PRINTZONE_COLOR_HEX } from '../../Canvas';
import { PrintZoneProps } from '../../Canvas/PrintZone';
import Loading from 'inkp-components/dist/Components/Loading';

import { createPrintArea, pixelPrintZone, templateCanvasData } from '../../../pages/DesignTool/utils';
import { DEFAULT_MOCKUP_SIDE, MOCKUP_THUMB_SIZE } from '../../../util/constants';

export interface Props {
  product: Product;
  fonts: Font[];
  design: Design;
  hexColor: string;
}

const renderCanvas: React.FunctionComponent<{
  canvasDimensions: CanvasDimensions;
  template: DesignTemplate;
  designSide: DesignSide | undefined;
  blankSrc: string;
  printArea: PrintArea;
  inchPerPx: number;
  printZones: PrintZoneProps[];
}> = ({ canvasDimensions, template, designSide, blankSrc, printArea, inchPerPx, printZones }) => {
  if (!canvasDimensions.width || !canvasDimensions.height || !template.data || !designSide) {
    // ! TODO: Ask Schukey about this possible case. It should only happens when the givne CSS Selector doesn't find a DOM Node
    return null;
  }
  let canvasWidth = canvasDimensions.width;

  let canvasHeight = (canvasWidth * template.data.size.height) / template.data.size.width;
  if (canvasHeight > canvasDimensions.height) {
    canvasHeight = canvasDimensions.height;
    canvasWidth = (canvasHeight! * template.data.size.width) / template.data.size.height;
  }
  const mainPrintZone = printZones[0];
  mainPrintZone.main = true;
  const mainGuidelinePosition = 'vertical-center';

  return (
    <div className="p-absolute pin">
      <Canvas
        selectedId={null}
        hoveredId={null}
        //@ts-ignore
        shapes={designSide.shapes}
        blankSrc={blankSrc}
        blankWidth={template.data.size.width}
        blankHeight={template.data.size.height}
        width={canvasWidth}
        height={canvasHeight}
        scale={{
          x: canvasWidth / template.data.size.width,
          y: canvasHeight / template.data.size.height,
        }}
        printArea={printArea}
        inchPerPx={inchPerPx}
        printZones={printZones}
        mainPrintZone={mainPrintZone}
        guidelinePositions={[mainGuidelinePosition]}
        onDeleteShape={() => {}}
        onUpdateShape={() => {}}
        showPrintZones={false}
        printZoneProps={{
          color: PRINTZONE_COLOR_HEX.default,
        }}
        guidelineProps={{
          showVerticalGuide: false,
        }}
        onDragStart={() => {}}
        onDragEnd={() => {}}
        onResizeStart={() => {}}
        onResizeEnd={() => {}}
        onMouseOver={() => {}}
        onMouseOut={() => {}}
        onSelectShape={() => {}}
        onDeselect={() => {}}
        showLoading={true}
      />
    </div>
  );
};

const ProductCardCanvasComponent: React.FunctionComponent<Props> = (props: Props) => {
  const { product, fonts, design, hexColor } = props;
  const productSide = product.sides.find((productSide) => productSide.name === DEFAULT_MOCKUP_SIDE) || product.sides[0];
  if (!productSide.printZonesObjects) {
    Sentry.captureException(
      new Error(
        `ProductSide ${productSide.name} doesn't contain the printZone information for Product: ${product.id}. Please verify the query done to obtain the information for this product.`
      )
    );
    throw new Error(
      `ProductSide ${productSide.name} doesn't contain the printZone information for Product: ${product.id}. Please verify the query done to obtain the information for this product.`
    );
  }
  const designSide = design.sides.find(({ name }) => name === (productSide.name as string)); // PRODUCT_SIDES enum vs DESIGN_SIDE_ENUM
  const template =
    product.designTemplates.find((designTemplate) => {
      return designTemplate.side === productSide.name;
    }) || product.designTemplates[0];
  const canvasData = templateCanvasData(template);
  const { inchPerPx } = canvasData;
  const printArea = createPrintArea(template, canvasData);
  const printZones: PrintZoneProps[] = productSide.printZonesObjects.map((printZoneObject) => {
    return {
      ...pixelPrintZone(printZoneObject, inchPerPx, printArea),
      main: false,
    };
  });

  const blankSrc = url('mockup', mockupRoutes.blank, {
    templateIdentifier: template.id,
    color: hexColor,
    size: MOCKUP_THUMB_SIZE,
  });

  const canvasContainerId = `canvas-container-${product.id}`;
  const canvasContainerIdSelector = `#${canvasContainerId}`;

  const [image, status] = useImage(blankSrc);
  const [fontsLoaded, setFontsLoaded] = React.useState<boolean>(false);

  return (
    <div className="w-full h-full">
      <CanvasDimensionsProvider canvasContainerSelector={canvasContainerIdSelector}>
        {(canvasDimensions: CanvasDimensions) => {
          return (
            <div className="canvas-container w-full p-relative" id={canvasContainerId}>
              <style jsx>{`
                .canvas-container {
                  padding-top: 123.5%;
                  height: 0;
                }
              `}</style>
              <WithLazyCustomFont
                fonts={fonts}
                onFontsLoaded={() => {
                  return setFontsLoaded(true);
                }}
              >
                {status === 'loading' || !fontsLoaded ? (
                  <div className="w-full h-full flex p-absolute pin">
                    <Loading textClassName="d-n" size="medium" />
                  </div>
                ) : (
                  renderCanvas({
                    canvasDimensions,
                    template,
                    designSide,
                    blankSrc,
                    printArea,
                    inchPerPx,
                    printZones,
                  })
                )}
              </WithLazyCustomFont>
            </div>
          );
        }}
      </CanvasDimensionsProvider>
    </div>
  );
};

export default ProductCardCanvasComponent;
