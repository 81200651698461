import { StateContainer } from 'infra-frontend/helpers/apollo';

interface FiltersState {
  sort: string;
  genders: string[];
  colors: string[];
  sizes: string[];
  style: string | null;
  brands: string[];
  speeds: string[];
}

export interface AddProductModalState {
  filterMenuOpen: boolean;
  filters: FiltersState;
  expandableSection: string | null;
  expandState: {[key: string]: boolean};
  collapsedState: {[key: string]: boolean};
}

export default class AddProductModalStateContainer extends StateContainer<AddProductModalState> {
  initialState = {
    filterMenuOpen: false,
    filters: {
      sort: 'popular',
      genders: [],
      colors: [],
      sizes: [],
      style: null,
      brands: [],
      speeds: [],
    },
    expandState: {
      colors: false,
      genders: false,
      sizes: false,
      brands: false,
      speeds: false,
    },
    collapsedState: {
      colors: false,
      genders: false,
      sizes: false,
      brands: false,
      speeds: false,
    },
    expandableSection: null,
  };

  shape = `
    {
      filterMenuOpen
      filters {
        sort
        colors
        genders
        sizes
        style
        brands
        speeds
      }
      expandState {
        colors
        genders
        sizes
        brands
        speeds
      }
      collapsedState {
        colors
        genders
        sizes
        brands
        speeds
      }
      expandableSection
    }
  `;

  setFilterMenuOpen = (value: boolean) => {
    this.setState({ filterMenuOpen: value });
  }

  setFilters = (filters: FiltersState) => {
    this.setState({ filters });
  }

  setExpandableSection = (section: string | null) => {
    this.setState({ expandableSection: section });
  }

  setDesktopExpand = (section: string, value: boolean) => {
    this.setState({ expandState: Object.assign({}, this.state.expandState, { [section]: value }) });
  }

  setDesktopCollapsed = (section: string, value: boolean) => {
    this.setState({ collapsedState: Object.assign({}, this.state.collapsedState, { [section]: value }) });
  }
}
