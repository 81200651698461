import * as React from 'react';
import * as _ from 'lodash';
import { Query } from 'react-apollo';

import config from 'inkp-config';
import { Subscribe } from 'infra-frontend/helpers/apollo';
import DesignCart from '../../../states/global/designCart';
import { QueryShareableByCode, SHAREABLE_BY_CODE_QUERY } from '../../../graphql/shareable';
import { QUERY_DESIGN_BY_ID, QueryDesignById } from '../../../graphql/designs';
import { QUERY_PRODUCTS_BY_ID, QueryProductsById } from '../../../graphql/products';
// ! TODO: Migrate this to graphql folder
import { FONTS_BY_NAMES_QUERY, FontsByNamesQuery } from '../FontSelector';

import { CATALOG_QUERY, SORT_VALUES } from '../../../components/Catalog/util';
import Catalog, {
  CatalogNewProductActions,
  CatalogEditDesignActionsProps,
  CatalogAddToCartActionProps,
} from '../../../components/Catalog';

import AddProductModalStateContainer from './state';
import { Product } from 'inkp-product-sdk/types.g';
import { Design, Font } from 'inkp-design-sdk/types.g';
import { DEFAULT_FONT_FAMILY, DEFAULT_MOCKUP_SIDE } from '../../../util/constants';
import { getFontsNamesFromDesignSide } from '../../../util/Design';

const SCROLL_ID = 'product-add-scroll';

class TypedSubscribe extends Subscribe<[AddProductModalStateContainer]> {}

export default function(props: {
  category?: string;
  closable?: boolean;
  designCart: DesignCart;
  onAddProduct: (product: any) => void;
  onAddToCart: (product: Product, design: Design, color: string, previousProduct: Product) => void;
  onClose: () => void;
  onEditDesign: (product: Product, design: Design, color: string, previousProduct: Product) => void;
  showMockups?: boolean;
}) {
  const { designCart } = props;
  const designId: string = designCart.state.latestDesignId || '';
  const productId: string = designCart.state.latestProductId || '';
  const skipQueries: boolean = !productId || !designId;
  const modalTitle: string = skipQueries ? 'Change Product' : 'Select Product';
  const showMockups: boolean = props.showMockups || false;
  let fontNames: string[] = [DEFAULT_FONT_FAMILY];

  const onAddToCartClick = ({ product, design, colorName, previousProduct }: CatalogAddToCartActionProps) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onAddToCart(product, design, colorName, previousProduct);
  };

  const onEditDesignClick = ({ product, design, colorName, previousProduct }: CatalogEditDesignActionsProps) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onEditDesign(product, design, colorName, previousProduct);
  };

  const newProductActions: CatalogNewProductActions = {
    onAddToCartClick,
    onEditDesignClick,
  };

  return (
    <TypedSubscribe to={[AddProductModalStateContainer]} namespace="DesignToolAddProduct">
      {({ state, setFilterMenuOpen, setFilters, setExpandableSection, setDesktopExpand, setDesktopCollapsed }) => {
        return (
          <QueryProductsById query={QUERY_PRODUCTS_BY_ID} variables={{ ids: [productId] }} skip={skipQueries}>
            {({ data: queryProductsByIdData, error: queryProductsByIdError, loading: queryProductsByIdLoading }) => {
              return (
                <QueryDesignById query={QUERY_DESIGN_BY_ID} variables={{ id: designId }} skip={skipQueries}>
                  {({ data: queryDesignByIdData, error: queryDesignByIdError, loading: queryDesignByIdLoading }) => {
                    if (queryDesignByIdLoading || queryProductsByIdLoading) {
                      return null;
                    }

                    const design: Design | undefined = queryDesignByIdData && queryDesignByIdData.designById;
                    const products: Product[] | undefined = queryProductsByIdData && queryProductsByIdData.productsById;
                    const product: Product | undefined = products && products.length ? products[0] : undefined;

                    if (design) {
                      // * This will be changed when we support Catalog of ProductCards that show a different side than FRONT
                      const designFrontSide =
                        design.sides.find((designSide) => designSide.name === DEFAULT_MOCKUP_SIDE) || design.sides[0];
                      const designFrontSideFontNames = getFontsNamesFromDesignSide(designFrontSide);
                      fontNames = [...fontNames, ...designFrontSideFontNames];
                    }
                    return (
                      <FontsByNamesQuery query={FONTS_BY_NAMES_QUERY} variables={{ fontNames }} skip={!design}>
                        {({
                          data: queryFontsByNameData,
                          error: queryFontsByNameError,
                          loading: queryFontsByNameLoading,
                        }) => {
                          if (queryFontsByNameLoading) {
                            return null;
                          }

                          const fonts: Font[] | undefined = queryFontsByNameData && queryFontsByNameData.fontsByNames;

                          return (
                            <div className="p-fixed pin flex flex-col lg:flex-row w-full z-20">
                              <style jsx={true}>{`
                                .slide-bar {
                                  width: 100%;
                                  height: 100%;
                                }

                                @media only screen and (min-width: 1024px) {
                                  .slide-bar {
                                    width: 968px;
                                  }
                                }

                                @media only screen and (min-width: 1440px) {
                                  .slide-bar {
                                    width: calc(100% - 3.5rem);
                                  }

                                  .catalog-container {
                                    width: 1208px;
                                    margin: auto;
                                  }
                                }
                              `}</style>
                              <div className="p-absolute pin bgc-navy opacity-75 -z-10" />
                              <div className="p-relative flex-1 d-n lg:d-b" onClick={props.onClose}>
                                <div className="p-absolute m-p5 color-navy cursor-pointer pin-r pin-t br-full overflow-hidden">
                                  <div
                                    className="p-p5"
                                    style={{
                                      background: 'rgba(255,255,255, 0.15)',
                                    }}
                                  >
                                    <i className="mdi mdi-close fs-icon-1p5 color-white" />
                                  </div>
                                </div>
                              </div>
                              <div className="slide-bar brt lg:brl lg:brtr-none overflow-hidden mt-p75 lg:mt-0">
                                <div className="bgc-white shadow-2 flex flex-col h-full">
                                  <div className="py-1 p-relative bwb-1 bc-gray flex items-center justify-center">
                                    <h3 className="fw-bold fs-lg">{modalTitle}</h3>
                                    <a
                                      className="p-absolute pin-t pin-r color-navy-800 mt-1 mr-1 lg:mr-3"
                                      href={`tel:${config.contact.phone}`}
                                    >
                                      <i className="mdi mdi-headset fs-icon-1p5 mr-p25" />
                                      <span className="d-n lg:d-i fs-md">Help</span>
                                    </a>
                                    <div
                                      className="p-absolute pin-t pin-l mt-1 ml-1 lg:ml-3 lg:d-n"
                                      onClick={props.onClose}
                                    >
                                      <i className="mdi mdi-close fs-icon-1p5 mr-p25" />
                                    </div>
                                  </div>
                                  <div className="flex-1 overflow-hidden p-relative">
                                    <Query
                                      query={CATALOG_QUERY}
                                      notifyOnNetworkStatusChange={true}
                                      variables={{
                                        page: 1,
                                        perPage: 16,
                                        brands: state.filters.brands,
                                        colors: state.filters.colors,
                                        sizes: state.filters.sizes,
                                        styles: _.compact([state.filters.style]),
                                        genders: state.filters.genders,
                                        speeds: state.filters.speeds,
                                        // @ts-ignore
                                        sortBy: [SORT_VALUES[state.filters.sort]],
                                        categories: [],
                                      }}
                                    >
                                      {(query: any) => {
                                        return (
                                          <div
                                            id={SCROLL_ID}
                                            className="h-full overflow-y-auto lg:px-1p5 xl:px-0"
                                            onScroll={(event: any) => {
                                              if (
                                                event.target.id === SCROLL_ID &&
                                                event.target.scrollTop + event.target.clientHeight >
                                                  event.target.scrollHeight - 250
                                              ) {
                                                if (query.loading) return;
                                                if (
                                                  query.data.filterProducts.pageMetadata.page >=
                                                  query.data.filterProducts.pageMetadata.pages
                                                )
                                                  return;
                                                query.fetchMore({
                                                  query: CATALOG_QUERY,
                                                  variables: Object.assign({}, query.variables, {
                                                    page: query.data.filterProducts.pageMetadata.page + 1,
                                                  }),
                                                  updateQuery: (prev: any, { fetchMoreResult: next }: any) => {
                                                    return {
                                                      filterProducts: Object.assign({}, prev.filterProducts, {
                                                        items: [
                                                          ...prev.filterProducts.items,
                                                          ...next.filterProducts.items,
                                                        ],
                                                        pageMetadata: next.filterProducts.pageMetadata,
                                                        filters: next.filterProducts.filters,
                                                      }),
                                                    };
                                                  },
                                                });
                                              }
                                            }}
                                          >
                                            <div className="catalog-container">
                                              <Catalog
                                                query={query}
                                                filters={state.filters}
                                                design={design}
                                                fonts={fonts}
                                                previousProduct={product}
                                                newProductActions={showMockups ? newProductActions : undefined}
                                                onFilterButtonClick={() => setFilterMenuOpen(true)}
                                                onFilterMenuClose={() => setFilterMenuOpen(false)}
                                                filterMenuOpen={state.filterMenuOpen}
                                                onNewFilters={(filters) => setFilters(filters)}
                                                expandableSection={state.expandableSection}
                                                onToggleDesktopCollapsed={(section, value) =>
                                                  setDesktopCollapsed(section, value)
                                                }
                                                collapsedState={state.collapsedState}
                                                onToggleDesktopExpand={(section, value) =>
                                                  setDesktopExpand(section, value)
                                                }
                                                expandState={state.expandState}
                                                onExpandableOpen={(value) => setExpandableSection(value)}
                                                onExpandableClose={() => setExpandableSection(null)}
                                                onBackToTop={() => {
                                                  const div = document.getElementById(SCROLL_ID);
                                                  if (div) div.scrollTop = 0;
                                                }}
                                                onClickProduct={props.onAddProduct}
                                              />
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Query>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </FontsByNamesQuery>
                    );
                  }}
                </QueryDesignById>
              );
            }}
          </QueryProductsById>
        );
      }}
    </TypedSubscribe>
  );
}
