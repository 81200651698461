import * as React from 'react';
import classnames from 'classnames';

import config from 'inkp-config';

// Components
import AddTextTool from './AddTextTool';
import ImageUpload from './ImageUpload';
import ClipArtSelector from './ClipArtSelector';
import ProductManager, { ProductProps } from './ProductManager';
import DefaultMenu, { Tool } from '../ShapeMenu/DefaultMenu';
import { Shape } from '../../../interfaces/Canvas';
import { DesignToolQuote } from './ProductManager';

const QUOTE_UP_ICON_SRC = `https://${config.s3.bucket}.s3-us-west-2.amazonaws.com/public/icons/icon_price_up.png`;
const QUOTE_DOWN_ICON_SRC = `https://${config.s3.bucket}.s3-us-west-2.amazonaws.com/public/icons/icon_price_down.png`;

interface UploadProps {
  /** File to upload from the page */
  file?: File | null;

  /** Additional classNames */
  className?: string;

  /** onClick handler */
  onClick?: () => void;

  /** uploadPolicy */
  uploadPolicy: any;

  /** onUpload handler */
  onUpload: (e: any) => void;

  /** onUpload handler */
  onUploadStart: (file: any) => void;

  /** onProgress handler */
  onProgress: (e: any) => void;

  /** onFailure handler */
  onFailure: (error: any) => void;

  /** onChangeFile handler */
  onChangeFile?: (file: any) => void;

  onFileDrop: (event: React.DragEvent<HTMLDivElement>) => void;
}

interface TextProps {
  /** Additional classNames */
  className?: string;

  /** Text sent by the parent to be displayed on the component */
  text?: string;

  /** onAddText handler */
  onAddText: (e: any) => void;
}

interface ClipArtProps {
  className?: string;

  onAddClipArt: (e: any) => void;
}

interface Props {
  /** Active tool */
  tool: string | null;

  /** Active tool in mobile */
  mobileTool: string | null;

  /** onSelectTool handler */
  onSelectTool: (tool: string) => void;

  /** onSelectTool handler */
  onSelectMobileTool: (mobileTool: string) => void;

  /** Props for the Upload Component */
  uploadProps: UploadProps;

  /** Props for the Add Text Component */
  textProps: TextProps;

  /** Props for the ClipArtSelector Component */
  clipArtProps: ClipArtProps;

  /** List of selected products */
  products: ProductProps[];

  /** Quote */
  quote: DesignToolQuote;

  /** color number for each side */
  sideColors: { [key: string]: number };

  /** Selected shape */
  selected: Shape | null;

  /* Select color handler **/
  onSelectColor: (data: { productId: string, color: string }) => void;

  /* Display color handler **/
  onDisplayColor: (data: { productId: string, color: string }) => void;

  /* Remove product **/
  onRemoveProduct: (productId: string) => void;

  /* Display size handler **/
  onUpdateQty: (size: string, qty: number) => void;

  /* Display user note handler */
  onUpdateNote: (note: string, designIndex: number) => void;

  /** Flag whether we can edit products */
  editableProducts: boolean;

  /** Flag to show add color option */
  productEditTab: 'color' | 'qty' | null;

  // /** Toggle add more color handler */
  // onToggleAddMoreColor: (value: boolean) => void;
  onSetProductEditTab: (value: string | null) => void;

  /** Add product clicker handler */
  onClickAddProduct: () => void;

  /** Reset designs handler */
  onResetDesignCart: () => void;
}

interface State {
  prevUnitPrice?: number,
  unitPrice?: number,
}

class ToolButton extends React.Component<{
  icon: string;
  tool: string;
  display: string | JSX.Element;
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  textClassNames?: string;
}> {
  render() {
    return (
      <div className="ta-center w-full cursor-pointer" onClick={this.props.onClick}>
        <div
          className={classnames('d-ib p-p5 br-full mb-p25 lg:bw-1', {
            'bc-navy-200': !this.props.active,
            'bc-primary': this.props.active,
          })}
        >
          <i
            className={classnames(`mdi mdi-${this.props.icon} fs-icon-2`, {
              'color-primary': this.props.active,
              'color-navy-700': !this.props.active,
            })}
          />
        </div>
        <div
          className={classnames('fw-bold', this.props.textClassNames, {
            'color-navy-500': !this.props.active,
            'color-primary': this.props.active,
          })}
        >
          {this.props.display}
        </div>
      </div>
    );
  }
}

export default class DesignToolControl extends React.Component<Props, State> {
  state: State = {};

  resetPriceChangeIcon(newPrice?: number) {
    if (newPrice) {
      return this.setState({
        prevUnitPrice: newPrice,
        unitPrice: newPrice,
      })
    }
    if (this.state.prevUnitPrice !== this.state.unitPrice) {
      this.setState({ prevUnitPrice: this.state.unitPrice });
    }
  }

  priceChangeUpdate(quote: DesignToolQuote) {
    if (quote.qty === 0) return;
    const unitPrice = quote.subtotal / quote.qty;
    const prevUnitPrice = this.state.unitPrice;
    if (unitPrice === prevUnitPrice) return;

    // This is to make sure price change icon won't show up when product tab is active
    if (!this.props.selected && (
      !this.props.tool && !this.props.mobileTool
      || this.props.tool === Tool.PRODUCT
      || this.props.mobileTool === Tool.PRODUCT
    )) {
      this.resetPriceChangeIcon(unitPrice);
      return;
    }

    this.setState({
      prevUnitPrice,
      unitPrice,
    });
  }

  componentDidUpdate(prevProps: Props) {
    this.priceChangeUpdate(this.props.quote);
  }

  displayTool = (tool: string | null, selectTool: (tool: string | null) => void) => {
    const {
      textProps: { className: classNameAddText, text: textAddText, onAddText: onAddTextAddText },
      selected,
      products,
      quote,
      sideColors,

      onSelectColor,
      onDisplayColor,
      onRemoveProduct,

      onUpdateQty,
      onUpdateNote,

      productEditTab,
      onSetProductEditTab,

      onClickAddProduct,
      onResetDesignCart,
    } = this.props;

    if (tool === Tool.PRODUCT) {
      return <ProductManager
        editable={this.props.editableProducts}
        products={products}
        quote={quote}
        sideColors={sideColors}
        onSelectColor={onSelectColor}
        onDisplayColor={onDisplayColor}
        onUpdateQty={onUpdateQty}
        onRemoveProduct={onRemoveProduct}
        tab={productEditTab}
        onTabClicked={onSetProductEditTab}
        onClickAddProduct={onClickAddProduct}
        onUpdateNote={onUpdateNote}
      />;
    }

    if (tool === Tool.IMAGE) {
      return (
        <ImageUpload
          uploadPolicy={this.props.uploadProps.uploadPolicy}
          onUpload={this.props.uploadProps.onUpload}
          onUploadStart={this.props.uploadProps.onUploadStart}
          onFileDrop={this.props.uploadProps.onFileDrop}
          onProgress={this.props.uploadProps.onProgress}
          onFailure={this.props.uploadProps.onFailure}
          file={this.props.uploadProps.file}
        />
      );
    }

    if (tool === Tool.TEXT) {
      return (
        <AddTextTool
          className={classNameAddText}
          title="Add Text"
          label="Add new text"
          text={textAddText}
          onAddText={onAddTextAddText}
        />
      );
    }

    if (tool === Tool.CLIPART) {
      return (
        <ClipArtSelector
          onAddClipArt={this.props.clipArtProps.onAddClipArt}
        />
      )
    }

    if (!tool && !selected) {
      return (
        <DefaultMenu
          tools={Object.keys(Tool)}
          onSelectTool={selectTool}
          onStartOver={onResetDesignCart}
        />
      );
    }

    return null;
  };

  render() {
    const { onSelectTool, onSelectMobileTool, mobileTool, selected } = this.props;
    let tool = this.props.tool;
    // Default to product when no element is selected
    if (!selected && !tool) {
      tool = Tool.PRODUCT;
    }

    if (selected) {
      tool = null;
    }

    let priceChangeIcon: string | JSX.Element = '';
    const { unitPrice, prevUnitPrice } = this.state;
    if (unitPrice && prevUnitPrice) {
      if (unitPrice > prevUnitPrice) priceChangeIcon = <img className="p-absolute" src={QUOTE_UP_ICON_SRC} />;
      if (unitPrice < prevUnitPrice) priceChangeIcon = <img className="p-absolute" src={QUOTE_DOWN_ICON_SRC} />;
    }

    return (
      <div className="bgc-white lg:bgc-transparent px-p5 lg:px-0 shadow overflow-hidden brt lg:shadow-none">
        <div className="flex lg:mb-2 bc-gray bwb-1 lg:bwb-0 py-p5 lg:py-0 justify-center">
          <div className="w-1/4 lg:d-n">
            <ToolButton
              tool={Tool.PRODUCT}
              textClassNames={"fs-sm"}
              icon="tshirt-crew"
              display={<span className="p-relative">Product{priceChangeIcon}</span>}
              active={mobileTool === Tool.PRODUCT}
              onClick={() => {
                onSelectMobileTool(Tool.PRODUCT);
                this.resetPriceChangeIcon();
              }}
            />
          </div>
          <div className="w-1/4 lg:d-n">
            <ToolButton
              tool={Tool.IMAGE}
              textClassNames={"fs-sm"}
              icon="image"
              display="Upload"
              active={mobileTool === Tool.IMAGE}
              onClick={() => onSelectMobileTool(Tool.IMAGE)}
            />
          </div>
          <div className="w-1/4 lg:d-n">
            <ToolButton
              tool={Tool.TEXT}
              textClassNames={"fs-sm"}
              icon="format-size"
              display="Add Text"
              active={mobileTool === Tool.TEXT}
              onClick={() => onSelectMobileTool(Tool.TEXT)}
              />
          </div>
          <div className="w-1/4 lg:d-n">
            <ToolButton
              tool={Tool.CLIPART}
              textClassNames={"fs-sm"}
              icon="paw"
              display="Add Clipart"
              active={mobileTool === Tool.CLIPART}
              onClick={() => onSelectMobileTool(Tool.CLIPART)}
            />
          </div>
          <div className="w-1/4 d-n lg:d-b">
            <ToolButton
              tool={Tool.PRODUCT}
              icon="tshirt-crew"
              display={<span>Product{priceChangeIcon}</span>}
              active={tool === Tool.PRODUCT}
              onClick={() => {
                onSelectTool(Tool.PRODUCT);
                this.resetPriceChangeIcon();
              }}
            />
          </div>
          <div className="w-1/4 d-n lg:d-b">
            <ToolButton
              tool={Tool.IMAGE}
              icon="image"
              display="Upload"
              active={tool === Tool.IMAGE}
              onClick={() => onSelectTool(Tool.IMAGE)}
            />
          </div>
          <div className="w-1/4 d-n lg:d-b">
            <ToolButton
              tool={Tool.TEXT}
              icon="format-size"
              display="Add Text"
              active={tool === Tool.TEXT}
              onClick={() => onSelectTool(Tool.TEXT)}
            />
          </div>
          <div className="w-1/4 d-n lg:d-b">
            <ToolButton
              tool={Tool.CLIPART}
              icon="paw"
              display="Add Clipart"
              active={tool === Tool.CLIPART}
              onClick={() => onSelectTool(Tool.CLIPART)}
            />
          </div>
        </div>
        <style jsx={true}>{`
          .scrollable {
            max-height: 50vh;
            overflow-y: scroll;
          }

          @media only screen and (min-width: 1024px) {
            .scrollable {
              max-height: none;
              overflow: visible;
            }
          }
        `}</style>
        <div className="scrollable d-n lg:d-b">
          {this.displayTool(tool, onSelectTool)}
        </div>
        <div className="scrollable d-b lg:d-n">
          {this.displayTool(mobileTool, onSelectMobileTool)}
        </div>
      </div>
    );
  }
}

