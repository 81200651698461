import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

import InputRange from 'inkp-components/dist/Components/InputRange';
import ColorPicker from 'inkp-components/dist/Components/ColorPicker';
import LayerTool from '../../LayerTool';
import ActionButton from '../ActionButton';
import MenuSection from '../MenuSection';

import { TEXT_COLORS, DEGREE_OPTIONS } from '../constants';
import { computeCenterPosition } from '../utils';
import { PrintZoneProps } from '../../../../components/Canvas/PrintZone';


const SECTION_HEADERS = {
  default: 'Edit Clip Art',
  action: 'Actions',
  color: 'Color',
  rotate: 'Rotate',
  layer: 'Layers',
};

const COLOR_HEX_MAP = _.keyBy(TEXT_COLORS, 'name');

interface Props {
  /** Function that handles the onRotationChange handler */
  onChange: (newShape: any) => void;

  /* Shape data */
  shape: any;

  /** Mobile section */
  mobileSection: string | null;

  /** Seciont change handler */
  onChangeSection: (section: string | null) => void;

  /** Cancel handler */
  onCancel: () => void;

  /** Print zone data */
  mainPrintZone: PrintZoneProps;

  /** Class Name **/
  className?: string;

  /** Edit layer button handler */
  onShowLayer: () => void;

  /** Layer hide handler */
  onHideLayer: () => void;

  /** Flag for layer display */
  showLayer?: boolean;

  /** List of shapes in the design for layering */
  shapes: any[];

  /** Layer change handler */
  onNewIndex: (data: { id: string; zIndex: number }) => void;
}

interface State {}

class ClipArtMenu extends React.Component<Props, State> {
  updateColor = (color: string) => {
    const hex = COLOR_HEX_MAP[color] && COLOR_HEX_MAP[color].hex || '000000';
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        color: `#${hex}`,
      })
    );
  }

  updateRotation = (rotation: number) => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        rotation,
      })
    );
  };

  toggleFlip = () => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        flip: !this.props.shape.flip,
      })
    );
  };

  toggleFlop = () => {
    this.props.onChange(
      Object.assign({}, this.props.shape, {
        flop: !this.props.shape.flop,
      })
    );
  };

  updatePosition = (position: string) => {
    const { shape, mainPrintZone } = this.props;
    const { x, y } = computeCenterPosition(position, shape, mainPrintZone);
    this.props.onChange(Object.assign({}, shape, {
      x,
      y,
    }));
  };

  render() {
    const { showLayer, mobileSection } = this.props;
    return (
      <div className={classnames(this.props.className)}>
        <div className="flex justify-between p-p75 lg:p-0 lg:mb-1p5 bwb-1 color-navy bc-gray lg:bwb-0 p-relative">
          <div
            className={classnames({ 'vis-hidden': !mobileSection }, 'lg:d-n w-1/4')}
            onClick={() => this.props.onChangeSection(null)}
          >
            <i className="mdi mdi-chevron-left fs-md-icon lg:d-n" /> <span className="fs-md fw-bold">Back</span>
          </div>
          <h2 className="flex-1 ta-center fs-md fw-bold lg:d-n w-1/2" style={{ zIndex: -1 }}>
            {SECTION_HEADERS[mobileSection || 'default']}
          </h2>
          <h2 className="flex-1 d-n lg:d-b fs-lg fw-extra-bold">{SECTION_HEADERS.default}</h2>
          <div className="lg:d-n ta-right w-1/4">
            <div className="d-ib" onClick={this.props.onCancel}>
              <i className="mdi mdi-close fs-icon-1p5 lg:d-n" />
            </div>
          </div>
        </div>
        <div className={classnames({ 'mb-3': !mobileSection }, 'lg:mb-0')}>
         <MenuSection
           title="Actions"
           section="action"
           selectedSection={mobileSection}
           onClick={() => this.props.onChangeSection("action")}
         >
            <div
              className={classnames(
                {
                  'd-n': !mobileSection || mobileSection !== 'action',
                  flex: mobileSection && mobileSection === 'action',
                },
                'lg:flex -mx-p5'
              )}
            >
              <div className="w-1/4 px-p5">
                <ActionButton
                  text="V-Center"
                  value="vertical-center"
                  icon={<i className="mdi mdi-format-align-middle fs-icon-1p5" />}
                  active={false}
                  onClick={this.updatePosition}
                  textStyles={{ whiteSpace: 'nowrap' }}
                />
              </div>
              <div className="w-1/4 px-p5">
                <ActionButton
                  text="H-Center"
                  value="horizontal-center"
                  icon={<i className="mdi mdi-format-align-middle mdi-rotate-90 fs-icon-1p5" />}
                  active={false}
                  onClick={this.updatePosition}
                  textStyles={{ whiteSpace: 'nowrap' }}
                />
              </div>
              <div className="w-1/4 px-p5">
                <ActionButton
                  text="Flip"
                  value="flip"
                  icon={
                    <i className="material-icons md-24" style={{ transform: 'rotate(90deg)' }}>
                      flip
                    </i>
                  }
                  active={this.props.shape.flip}
                  onClick={this.toggleFlip}
                />
              </div>
              <div className="w-1/4 px-p5">
                <ActionButton
                  text="Flop"
                  value="flop"
                  icon={<i className="material-icons md-24">flip</i>}
                  active={this.props.shape.flop}
                  onClick={this.toggleFlop}
                />
              </div>
            </div>
          </MenuSection>
          <MenuSection
            title="Color"
            section="color"
            selectedSection={mobileSection}
            onClick={() => this.props.onChangeSection('color')}
          >
            <div
              className={classnames(
                { 'd-n': !mobileSection || mobileSection !== 'color' },
                'lg:flex mx-1 lg:mx-0'
              )}
            >
              <ColorPicker
                showTooltip={true}
                onClick={this.updateColor}
                colors={TEXT_COLORS.map((color) => {
                  color.active = this.props.shape.color.replace('#', '') === color.hex;
                  return color;
                })}
                limit={32}
                colorPerRow={mobileSection && mobileSection === 'color' ? 8 : 10}
                onMouseEnter={() => null}
                onMouseLeave={() => null}
              />
            </div>
          </MenuSection>
          <MenuSection
            title="Rotate"
            section="rotate"
            selectedSection={mobileSection}
            onClick={() => this.props.onChangeSection("rotate")}
          >
            <div
              className={classnames(
                { 'd-n': !mobileSection || mobileSection !== 'rotate' },
                'lg:d-b'
              )}
            >
              <InputRange
                onChange={(e: any) => this.updateRotation(parseInt(e.target.value.replace('º', ''), 10) || 0)}
                options={DEGREE_OPTIONS}
                step={1}
                min={-180}
                max={180}
                unit={"degrees"}
                substractFunction={() => this.updateRotation(Math.max(-180, this.props.shape.rotation - 1))}
                addFunction={() => this.updateRotation(Math.min(180, this.props.shape.rotation + 1))}
                value={this.props.shape.rotation}
              />
            </div>
          </MenuSection>
          {this.props.shapes.length > 1 && (
            <MenuSection
              title="Layers"
              section="layer"
              selectedSection={mobileSection}
              onClick={() => this.props.onChangeSection("layer")}
            >
              <div
                className="d-n lg:d-ib br-full mt-1 py-p5 px-1 bw-1 bc-navy-500 bgc-gray-50 bgc-navy-100:hover cursor-pointer fw-bold"
                onClick={this.props.onShowLayer}
              >
                Edit Layers <i className="mdi mdi-chevron-right fs-icon-1p5" />
              </div>
              {(showLayer || mobileSection === 'layer') && (
                <LayerTool
                  shapes={this.props.shapes}
                  onNewIndex={this.props.onNewIndex}
                  onHide={(e: any) => {
                    e.stopPropagation();
                    this.props.onHideLayer();
                  }}
                />
              )}
            </MenuSection>
          )}
        </div>
      </div>
    );
  }
}

export default ClipArtMenu;
