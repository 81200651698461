import * as React from 'react';
import classnames from 'classnames';

import Logo from 'inkp-components/dist/Components/Logo';
import config from 'inkp-config';

import { HeaderButton, MobileHeaderButton } from '../../../components/HeaderButton';

interface Props {
  onSaveClick: () => void;
  onRetrieveClick?: () => void;
  onNextClick: (skipSave?: boolean) => void;
  submitting?: boolean;
  disableSave?: boolean;
  disableNext?: boolean;
  onNextTitle?: string;

  // Call to action flags
  actions: {
    redesigning: boolean;
  };
}

function renderRedesign(props: Props) {
  const onNextTitle = props.submitting ? 'Submitting...' : 'Submit';
  const onNext = () => {
    if (!props.submitting) props.onNextClick(true);
  };
  return (
    <div>
      <HeaderButton className="color-navy" disabled={props.submitting} onClick={onNext}>
        {onNextTitle}
      </HeaderButton>
      <MobileHeaderButton className="color-navy" text={onNextTitle} onClick={onNext} />
    </div>
  );
}

function renderDefault(props: Props) {
  const { onNextTitle } = props;
  return (
    <>
      <HeaderButton
        className="color-white bgc-primary bgc-primary-600:hover"
        disabled={props.disableNext}
        onClick={() => props.onNextClick(false)}
      >
        <span>
          {onNextTitle ? onNextTitle : 'Add to Cart'}
          <i className="mdi mdi-arrow-right fs-icon-1p5 ml-2px" />
        </span>
      </HeaderButton>
      <MobileHeaderButton
        className="color-white bgc-primary"
        text="Quote"
        icon="arrow-right"
        onClick={() => props.onNextClick(false)}
      />
    </>
  );
}

export default function(props: Props) {
  return (
    <>
      <div className="d-n lg:d-b">
        <div className="grid lg:py-p5 items-center">
          <div className="col col-1">
            <div className="d-n lg:d-b" style={{ height: '32px' }}>
              <Logo
                logoSrc="https://inkp-production.32pt.com/public/logos/inkpop-wordmark.png"
                className="d-n lg:d-b"
                styles={{ maxHeight: '32px' }}
                hAlignment="center"
                vAlignment="center"
                id="desktop"
              />
            </div>
          </div>
          <div className="col col-2 flex justify-center">
            {props.onRetrieveClick && (
              <HeaderButton
                className="color-navy"
                icon="download"
                onClick={() => {
                  props.onRetrieveClick!();
                }}
              >
                Retrieve Design
              </HeaderButton>
            )}
            <HeaderButton
              className="color-navy"
              icon="content-save"
              disabled={props.disableSave}
              onClick={props.onSaveClick}
            >
              Save Design
            </HeaderButton>
          </div>
          <div className="col col-3 flex justify-between items-center">
            <HeaderButton className="color-navy -ml-1p25" icon="headset" href={`tel:${config.contact.phone}`}>
              <>
                <div className="color-primary">
                  <style jsx>
                    {`
                      div {
                        font-size: 10px;
                        line-height: 15px;
                        height: 15px;
                      }
                    `}
                  </style>
                  Talk to a Specialist
                </div>
                <div className="fs-md-n">
                  {config.contact.phone.replace(/^\d{3}-/, (match: string) => `(${match.slice(0, 3)}) `)}
                </div>
              </>
            </HeaderButton>
            {props.actions.redesigning && renderRedesign(props)}
            {!props.actions.redesigning && renderDefault(props)}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center d-b lg:d-n">
        <div className="flex-1">
          <div className="d-b lg:d-n" style={{ height: '24px' }}>
            <Logo
              logoSrc="https://inkp-production.32pt.com/public/logos/inkpop-ink-drop.svg"
              className="d-b lg:d-n pl-1"
              styles={{ maxHeight: '24px' }}
              hAlignment="left"
              vAlignment="top"
              id="mobile"
            />
          </div>
        </div>
        {props.onRetrieveClick && (
          <MobileHeaderButton
            className="color-navy"
            text="Retrieve"
            icon="download"
            onClick={() => {
              props.onRetrieveClick!();
            }}
          />
        )}
        <MobileHeaderButton className="color-navy" text="Save" icon="content-save" onClick={props.onSaveClick} />
        <MobileHeaderButton className="color-navy" text="Help" icon="headset" href={`tel:${config.contact.phone}`} />
        {props.actions.redesigning && renderRedesign(props)}
        {!props.actions.redesigning && renderDefault(props)}
      </div>
    </>
  );
}
