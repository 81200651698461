import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Input from 'inkp-components/dist/Components/Input';
import Loading from 'inkp-components/dist/Components/Loading';

import NotFoundLogo from '../../../../components/NotFoundLogo';

import GTM from '../../../../util/gtm';
import { GTMTypes, GTMEvents } from '../../../../interfaces/GTM';

interface Props {
  onAddClipArt: (e: any) => void;
}

interface State {
  text: string;
  selectedCategoryIds: string[];
  debouncedSearchText: string;
  clipartPage: number;
  blobUrls: { [propName: string]: string };
}

const LOG_DEBOUNCE = 3000;
const SEARCH_DEBOUNCE = 500;
const CATEGORY_LEVELS = 2;

const CLIP_ART_CATEGORIES_QUERY = gql`
  query ListClipArtCategories($parentCategoryId: String) {
    listClipartCategories(parentCategoryId: $parentCategoryId) {
      id
      name
      svg
      type
    }
  }
`;

const CLIP_ART_QUERY = gql`
  query ListClipArts($categoryId: String, $query: String, $page: Int!, $limit: Int!) {
    listCliparts(categoryId: $categoryId, query: $query, page: $page, limit: $limit) {
      id
      name
      svg
      tags
      categoryIds
    }
  }
`;

interface ClipArtItem {
  id: string;
  name: string;
  svg: string;
  onClick: (e: any) => void
}

function ClipArtList(props: {
  items: ClipArtItem[];
  blobUrls: { [propName: string]: string };
  setBlobUrls: (svg: string, imageUrl: string) => void;
  hideName?: Boolean;
}) {
  return (
    <div className="flex flex-wrap mt-1p5 -mx-p5 ta-center">
    {props.items.map((item) => {
      const node = document.createElement('div');
      node.innerHTML = item.svg;
      const width = _.get(node.firstElementChild, 'viewBox.animVal.width');
      const height = _.get(node.firstElementChild, 'viewBox.animVal.height');
      let imageUrl = props.blobUrls[item.svg];
      if (item.svg && !imageUrl) {
        let blob = new Blob([item.svg], { type: 'image/svg+xml' });
        imageUrl = URL.createObjectURL(blob);
        props.setBlobUrls(item.svg, imageUrl);
      }
      return (
        <div
          className="w-1/3 cursor-pointer mb-1"
          key={item.id}
          onClick={() => item.onClick({...item, dimensions: { width, height } })}
        >
          <div className="p-relative mx-p5" style={{ width: 'calc(100% - 1rem)', paddingTop: '100%'}}>
            <img className="w-full h-full p-absolute pin br bw-1 bc-navy-500 bgc-white bgc-navy-300:hover" src={imageUrl} />
          </div>
          {!props.hideName && <span className="mt-p5 flex justify-center fw-bold">{item.name}</span>}
        </div>
      );
    })}
  </div>
  )
}

function Header(props: {
  title: string;
  onBack?: () => void;
}) {
  return (
    <div className="flex mb-1p5 bc-navy-100 bwb-1 py-p75 lg:bw-0 lg:py-0">
      {props.onBack &&
        <button className="flex w-1/3 justify-start items-center fw-bold" onClick={props.onBack}>
          <i className="mdi mdi-chevron-left lg-icon"/>
          <span>Back</span>
        </button>
      }
      <span className={classnames("ta-center fw-bold", {
        "w-1/3": props.onBack,
        "w-full": !props.onBack,
      })}>{props.title}</span>
    </div>
  )
}

function NoResult(props: {
  searchText: string;
}) {
  return (
    <div className="mt-1p5 ta-center">
      <div className="fw-bold fs-mlg color-navy-500">Sorry, No result found for "{props.searchText}"</div>
      <NotFoundLogo className="w-1/4 my-1p5" />
      <div className="fs-mlg color-navy-500">Try these categories instead</div>
    </div>
  );
}

export default class ClipArtSelector extends React.Component<Props, State> {
  state = {
    text: '',
    debouncedSearchText: '',
    selectedCategoryIds: [],
    clipartPage: 0,
    blobUrls: {},
  }

  logSearch = (term: string) => {
    GTM.push(GTMTypes.CLIPART, { searchTerm: term});
    GTM.fire(GTMEvents.SEARCH_CLIPART);
  }
  logSearchDebounced = _.debounce(this.logSearch, LOG_DEBOUNCE);

  requestSearchText = (text: string) => {
    this.setState({ debouncedSearchText: text });
  }
  requestSearchTextDebounced = _.debounce(this.requestSearchText, SEARCH_DEBOUNCE);

  changeSearchText = (event: any) => {
    const text = event.target.value;
    this.setState({ text });
    this.requestSearchTextDebounced(text);
  }

  clearSearchText = () => {
    const text = "";
    this.setState({ text, debouncedSearchText: text });
  }

  selectCategory = (item: ClipArtItem) => {
    this.setState(({ selectedCategoryIds }) => {
      return {
        selectedCategoryIds: [...selectedCategoryIds, item.id],
      };
    });
  }

  categoryOnBack = () => {
    this.setState(({ selectedCategoryIds }) => {
      const newCategoryIds = _.clone(selectedCategoryIds);
      newCategoryIds.pop();
      return {
        selectedCategoryIds: newCategoryIds,
      };
    });
  }

  setBlobUrls = (svg: string, imageUrl: string) => {
    this.setState((state: State) => {
      let newBlobUrls = Object.assign({}, state.blobUrls);
      newBlobUrls[svg] = imageUrl;
      return {
        blobUrls: newBlobUrls,
      };
    })
  }

  render() {
    const {
      onAddClipArt,
    } = this.props;
    const selectedCategoryId = _.last(this.state.selectedCategoryIds);

    return (
      <div className="w-full">
        <h3 className="color-navy fs-xl mb-1p5 fw-extra-bold d-n lg:d-b">Add clipart</h3>
        <Header
          title="Categories"
          onBack={ selectedCategoryId ? this.categoryOnBack : undefined }
        />
        <Input
          label="Search"
          value={this.state.text}
          autoFocus={true}
          clearable={true}
          removeInnerLabel={true}
          onChange={this.changeSearchText}
          onClearClick={this.clearSearchText}
        />
        <Query query={CLIP_ART_CATEGORIES_QUERY} variables={{ parentCategoryId: selectedCategoryId }}>
          {({ data: categoryData, error, loading }: any) => {
            if (error) return null;
            if (loading) return <Loading size="small"/>;

            const isSearch = !!this.state.text;
            const isLeafCategory = this.state.selectedCategoryIds.length === CATEGORY_LEVELS;

            const clipartVariables: any = { page: this.state.clipartPage, limit: 30 };
            if (isLeafCategory && selectedCategoryId && !isSearch) {
              clipartVariables.categoryId = selectedCategoryId;
            }
            if (this.state.debouncedSearchText) {
              clipartVariables.query = this.state.debouncedSearchText;
            }
            return (
              <Query query={CLIP_ART_QUERY} variables={clipartVariables} onCompleted={(data: any) => {
                if (clipartVariables.query) {
                  this.logSearchDebounced(clipartVariables.query);
                }
              }}>
                {({ data: clipartData, error, loading }: any) => {
                  if (error) return null;
                  if (loading) return <Loading size="small"/>;

                  const categories = (_.get(categoryData, 'listClipartCategories', [])).map((category: any) => {
                    if (isSearch) {
                      category.onClick = (item: ClipArtItem) => {
                        this.clearSearchText();
                        this.selectCategory(item);
                      }
                    } else {
                      category.onClick = this.selectCategory;
                    }
                    return category;
                  });
                  const cliparts = _.get(clipartData, 'listCliparts', []).map((clipart: any) => {
                    clipart.onClick = onAddClipArt;
                    return clipart;
                  });

                  const isClipart = (isLeafCategory && cliparts.length > 0) || isSearch;
                  const items = isClipart ? cliparts : categories;
                  const noResult = isSearch && items.length <= 0;

                  return (
                    <>
                      {noResult &&
                        <>
                          <NoResult searchText={this.state.text} />
                          <ClipArtList
                            items={categories}
                            blobUrls={this.state.blobUrls}
                            setBlobUrls={this.setBlobUrls}
                          />
                        </>
                      }
                      <ClipArtList
                        items={items}
                        blobUrls={this.state.blobUrls}
                        setBlobUrls={this.setBlobUrls}
                        hideName={isClipart}
                      />
                    </>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    )
  }
}
