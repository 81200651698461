import * as React from 'react';
import classnames from 'classnames';

import ImageThumbnail from './ImageThumbnail';

interface Props {
  /** Additional classNames */
  className?: string;

  /** Images */
  images: {
    label: string;
    url: string;
  }[];

  /** onClick handler */
  onClick: (label: string) => void;

  /** Active Label */
  active?: string;

  /** Color for Active Label */
  colorActive?: string;
}

class HeaderCanvas extends React.Component<Props> {
  render() {
    const { className, images, onClick, active, colorActive } = this.props;
    return (
      <div className={classnames(['flex flex-col vis-hidden md:vis w-full ta-center bgc-blue-50', className])}>
        {images.map((image: any) => (
          <ImageThumbnail key={image.label} {...image} onClick={onClick} active={active} colorActive={colorActive} />
        ))}
      </div>
    );
  }
}

export default HeaderCanvas;
