import * as React from 'react';
import classnames from 'classnames';

// Components
import Button from 'inkp-components/dist/Components/Button';

interface Props {
  /** Additional classNames */
  className?: string;

  /** Text sent by the parent to be displayed on the component */
  text?: string;

  /** Label text overlaying the textarea */
  label?: string;

  /** Title text shown above the textarea */
  title?: string;

  /** onAddText handler */
  onAddText?: (text: string) => void;

  /** onUpdateText handler */
  onUpdateText?: (text: string) => void;
}

interface State {
  text?: string;
}

class AddTextTool extends React.Component<Props, State> {
  state: State = {
    text: this.props.text || '',
  };

  updateText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ text: event.target.value });
    if (this.props.onUpdateText) {
      this.props.onUpdateText(event.target.value);
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.text !== this.props.text) {
      this.setState({ text: this.props.text });
    }
  }

  onAddText = () => {
    if (!this.state.text) return;
    if (this.props.onAddText) {
      this.props.onAddText(this.state.text);
    }
  }

  render() {
    const { className, onAddText, label, title } = this.props;
    const { text } = this.state;
    return (
      <div className={classnames(['w-full ta-center fs-md lg:px-0', className])}>
        <style jsx={true}>{`
          .label-text-area {
            top: 0.75rem;
            left: 1rem;
            transition: 0.3s ease all;
            -moz-transition: 0.3s ease all;
            -webkit-transition: 0.3s ease all;
          }
          .text-area {
            box-sizing: border-box;
            height: 157px;
            transition: 0.3s ease all;
            -moz-transition: 0.3s ease all;
            -webkit-transition: 0.3s ease all;
          }
          .text-area:focus,
          .text-area.dirty {
            outline: none;
          }
          .text-area:focus ~ .label-text-area,
          .text-area.dirty ~ .label-text-area {
            top: calc(0.75rem - 22px);
            font-size: 0.75rem;
            font-weight: bold;
            line-height: 1.5;
            color: #1f2245;
            outline: none;
            background-color: #ffffff;
            padding: 0 4px;
          }
        `}</style>
        <div className="w-full add-text-tool-group p-relative">
          <h3 className="fs-xl color-navy fw-extra-bold ta-left mb-1p5 d-n lg:d-b">{title}</h3>
          <div className="p-relative">
            <textarea
              autoFocus={true}
              className={classnames(
                `text-area w-full py-1 px-p75 bw-1
                bc-navy-500 br bgc-white bc-navy:focus bw-1:focus`,
                { dirty: !!text }
              )}
              value={text}
              onChange={this.updateText}
            />
            <label className="label-text-area p-absolute color-navy-500 fs-md">{label}</label>
            {onAddText &&
              <Button className="lg:my-2 mt-p5 w-full" onClick={this.onAddText}>
                <div className="py-p25">Add Text</div>
              </Button>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default AddTextTool;
