import * as React from 'react';
import Modal from 'inkp-components/dist/Components/Modal';
import MenuButton from 'inkp-components/dist/Components/MenuButton';

interface CartActionButton {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface Props {
  cartActionButtons: CartActionButton[];
  onModalClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CartActionsModal: React.FunctionComponent<Props> = ({ cartActionButtons, onModalClose }) => {
  return (
    <Modal
      title="What do you want to do next?"
      width="330px"
      headerStyle="light"
      headerTextStyles={{ fontSize: '1.125rem' }}
      headerTextAlignment="left"
      closeButtonPadding="p-p5"
      onCloseClick={onModalClose}
    >
      <ul className="p-0 px-1p5 pb-p75">
        {cartActionButtons.map((cartActionButton: CartActionButton, idx: number) => {
          return (
            <li key={idx} className="d-b mb-p75">
              <MenuButton onClick={cartActionButton.onClick}>{cartActionButton.label}</MenuButton>
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

export default CartActionsModal;
