import * as React from 'react';
import classnames from 'classnames';

class MenuSection extends React.Component<{
  onClick: () => void;
  title: string;
  selectedSection: string | null;
  section: string;
  hideDesktopTitle?: boolean;
  children: any;
}> {
  render() {
    const {
      title,
      selectedSection,
      hideDesktopTitle,
      section,
      children,
      onClick,
    } = this.props;
    const showSection = !selectedSection || selectedSection === section;
    return (
      <div
        className={classnames({ 'd-n': !showSection }, 'bwb-1 bc-gray lg:d-b lg:bwb-0 mb-1p5')}
      >
        <div
          className={classnames({
            'd-n': selectedSection,
            'flex': !selectedSection,
            'lg:d-n': hideDesktopTitle,
            'lg:d-b': !hideDesktopTitle,
          }, 'p-1 lg:p-0 p-relative')}
        >
          <h3 className="flex-1 fs-md fw-extra-bold color-navy">{title}</h3>
          <i className="mdi mdi-chevron-right fs-md-icon lg:d-n" />
          <div className="p-absolute pin lg:d-n" onClick={onClick} />
        </div>
        {children}
      </div>
    );
  }
}

export default MenuSection;
