import * as React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';

import S3FileUpload from 'inkp-components/dist/Components/S3FileUpload';
import { Query } from 'react-apollo';

const MIMETYPE_FILE_EXTENTION = {
  'image/jpeg': [ 'jpg', 'jpeg' ],
  'image/bmp': [ 'bmp' ],
  'image/png': [ 'png' ],
  'application/postscript': [ 'eps', 'ps' ],
  'application/pdf': [ 'pdf' ],
};
export const ACCEPT = Object.keys(MIMETYPE_FILE_EXTENTION);
const ACCEPT_FILE_EXTENSIONS = Object.values(MIMETYPE_FILE_EXTENTION)
                                    .reduce((output, extensions) => { return output.concat(extensions); }, [])
                                    .join(", ");

const SIZE_LIMIT = 25e6; // 25MB

const QUERY = gql`
  {
    uploadPolicy {
      action
      keyPrefix
      fields
    }
  }
`;


interface Props {
  file?: File | null;
  uploadPolicy: any;
  onUpload: (artwork: any) => void;
  onUploadStart: (file: any) => void;
  onProgress: (event: any) => void;
  onFailure: (error: any) => void;
  onFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
}

export default class ImageUpload extends React.Component<Props> {
  state = { dragOver: false };

  dragCounter = 0;

  preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  dragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    this.preventDefault(event);
    if (event.dataTransfer.items && event.dataTransfer.items.length === 1) {
      const item = event.dataTransfer.items[0];
      if (ACCEPT.includes(item.type)) {
        this.setState({ dragOver: true });
      }
    }
    this.dragCounter++;
  };

  dragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    this.preventDefault(event);
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragOver: false });
    }
  };

  drop = (event: React.DragEvent<HTMLDivElement>) => {
    this.preventDefault(event);
    this.dragCounter = 0;
    this.setState({ dragOver: false });
    this.props.onFileDrop(event);
  };

  componentDidMount() {
    document.addEventListener('dragover', this.preventDefault);
    document.addEventListener('drop', this.preventDefault);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', this.preventDefault);
    document.removeEventListener('drop', this.preventDefault);
  }

  render() {
    return (
      <div className="w-full" onDragEnter={this.dragEnter} onDragLeave={this.dragLeave}>
        <h3 className="color-navy fs-xl mb-2 fw-extra-bold d-n lg:d-b">Choose a file to upload</h3>
        <Query query={QUERY}>
          {({ loading, data: { uploadPolicy }, error }: any) => {
            if (loading) return null;
            if (error) return <div>Error</div>;
            return (
              <div
                className={classnames(
                  'p-relative w-full lg:bgc-gray-100 bc-navy-500 br lg:bw-1 lg:p-2 ta-center overflow-hidden',
                  { 'bc-primary': this.state.dragOver, 'bc-navy-500': !this.state.dragOver }
                )}
                style={{ borderStyle: this.state.dragOver ? 'solid' : 'dashed' }}
              >
                <div className={classnames({ 'vis-hidden': this.state.dragOver })}>
                  <div className="color-primary mb-1 lg:mb-2">
                    <div className="d-n lg:d-b">
                      <i className="mdi mdi-upload fs-icon-2p5 mb-1p5" />
                      <div className="fw-bold fs-lg">Drag & drop files here</div>
                      <div className="fs-lg mb-p5">or</div>
                    </div>
                    <label
                      className={
                        'p-relative w-full lg:w-auto br-full bgc-primary color-white py-p75 lg:py-p5 px-1p5 fs-md fw-bold cursor-pointer d-ib'
                      }
                    >
                      <i className="mdi mdi-upload fs-icon-1p5 color-white d-b lg:d-n p-absolute pin-l pl-1" />
                      Upload
                      <S3FileUpload
                        hidden={true}
                        immediate={true}
                        accept={ACCEPT.join(',')}
                        onChangeFile={this.props.onUploadStart}
                        onUpload={this.props.onUpload}
                        onProgress={this.props.onProgress}
                        onFailure={this.props.onFailure}
                        uploadPolicy={uploadPolicy.fields}
                        sizeLimit={SIZE_LIMIT}
                        file={this.props.file}
                        keyPrefix={uploadPolicy.keyPrefix}
                        action={uploadPolicy.action}
                      />
                    </label>
                  </div>
                  <div className="flex flex-col-reverse lg:flex-col">
                    <div className="mb-1">
                      <div className="color-navy lg:color-navy-500 fw-bold mb-p5 lg:mb-p25">Allowed file types</div>
                      <div className="d-ib w-1/2 lg:w-full">{ACCEPT_FILE_EXTENSIONS}</div>
                    </div>
                    <div className="mb-1 lg:mb-0">
                      <div className="color-navy lg:color-navy-500 fw-bold mb-p5 lg:mb-p25">Max file size</div>
                      <div>25MB</div>
                    </div>
                  </div>
                </div>
                {this.state.dragOver && (
                  <div className="flex p-absolute w-full h-full justify-center pin-l pin-t bgc-gray-100" onDrop={this.drop}>
                    <div className="self-center ta-center">
                      <div className="bgc-primary br-full p-p5 mb-p25">
                        <i className="mdi mdi-plus fs-icon-2 color-white" />
                      </div>
                      <span className="color-primary fw-bold">Add</span>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Query>

      </div>
    );
  }
}