import * as React from 'react';
import Button from 'inkp-components/dist/Components/Button';

export default function(props: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <div className="p-absolute w-full pin-b mb-1p5 ta-center lg:d-n">
      <Button
        type="default"
        size="md"
        corners="full"
        color="secondary"
        shadowEnabled
        onClick={props.onClick}
      >
        <i className="mdi mdi-filter-variant color-navy fs-icon-1p5" />
        <span className="px-p25 color-navy">Filter</span>
      </Button>
    </div>
  );
}
