import * as React from 'react';
import Modal from 'inkp-components/dist/Components/Modal';

function readableProgress(uploaded: number, total: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(Math.floor(Math.log(total) / Math.log(1024)).toString(), 10);
  return { uploaded: (uploaded / Math.pow(1024, i)).toFixed(2), total: (total / Math.pow(1024, i)).toFixed(2), unit: sizes[i]}
};

const UPLOAD_RESERVE = 0.2; // Reserve 10% for uploading to oo-artwork;
const OO_ARTWORK_UPLOAD_TIME = 8000; // Assume oo-artwork upload time to be 8s

export default (props: {
  uploaded: number;
  total: number;
  startedAt: number;
  onCancel: () => void;
}) => {
  const {
    uploaded,
    total,
    startedAt,
    onCancel,
  } = props;

  const reserved = uploaded * (1 - UPLOAD_RESERVE);

  // True when starting to uploading to oo-artwork where we cannot know the progres
  const unknownProgress = reserved >= total * (1 - UPLOAD_RESERVE);
  // Set the date when that happens
  const [finishedKnownAt, setDate] = React.useState((null as Date | null));
  const [time, setTime] = React.useState((null as Date | null));

  React.useEffect(() => {
    if (unknownProgress) {
      setDate(new Date());
       const id = setInterval(() => {
        setTime(new Date());
      }, 100);

      return () => {
        clearInterval(id);
      }
    }
  }, [unknownProgress]);

  const elapsed = (finishedKnownAt ? finishedKnownAt : new Date()).getTime() -  startedAt;
  const speed = uploaded / elapsed;
  let fakeProgress = reserved;
  if (finishedKnownAt) {
    fakeProgress += ((total / OO_ARTWORK_UPLOAD_TIME ) * ((new Date()).getTime() - finishedKnownAt.getTime())) * UPLOAD_RESERVE;
  }
  fakeProgress = Math.min(fakeProgress, total - 1);

  const percentage = Math.floor((fakeProgress / total) * 100);

  const remainingTime = ((total - fakeProgress) / ((speed * (1 - UPLOAD_RESERVE)) + ((total / OO_ARTWORK_UPLOAD_TIME) * UPLOAD_RESERVE))) || 0;

  const progress = readableProgress(fakeProgress, total);

  return (
    <Modal title="Uploading Image" headerTextAlignment="left" onCloseClick={onCancel} headerStyle="light">
      <div>
        <div className="flex color-navy-500 pb-1p5 px-1p5">
          <div className="flex-1">
            <span className="fw-bold">{percentage}%</span>
            &nbsp;
            {percentage > 10 && ( // Only show time when we have enough data
              <span>{Math.ceil(remainingTime / 1000)} seconds left</span>
            )}
          </div>
          <div>
            {progress.uploaded}/{progress.total}{progress.unit}
          </div>
        </div>
        <div className="w-full h-p25 bgc-blue-100 mb-1p5">
          <div className="h-full bgc-purple" style={{ width: `${percentage}%` }} />
        </div>
      </div>
    </Modal>
  )
}

