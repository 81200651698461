import * as React from 'react';
import classnames from 'classnames';
import { FilterPillProps } from '../FilterPills';
import MobileFilterMenuHeader from '../MobileFilterMenuHeader';
import Button from 'inkp-components/dist/Components/Button';

const FilterItem: React.SFC<{
  display: string;
  selected?: boolean;
  color?: string;
  onClick: () => void;
  forceBorder?: boolean;
}> = (props) => (
  <div className={classnames('flex items-center bwb-1 bc-gray bgc-navy-100:hover cursor-pointer h-full', {
    'bwr-1': !props.color || props.forceBorder,
  })} onClick={props.onClick}>
    {props.color && (
      <div className={classnames('px-2px h-full brr-full bc-navy-500', {
        'bwr-1': props.color.toLowerCase() === 'white' || props.color.toLowerCase() === '#fff' || props.color.toLowerCase() === '#ffffff',
      })} style={{ backgroundColor: `${props.color}` }} />
    )}
    <div className={classnames('py-1 pr-p5', { 'pl-p75': !!props.color, 'pl-1': !props.color })}>
      <i className={classnames('mdi fs-icon-1p5', {
        'mdi-checkbox-blank-outline color-navy-500': !props.selected,
        'mdi-checkbox-marked color-primary': props.selected,
      })} />
    </div>
    <div className={classnames({
      'fw-bold color-primary': props.selected,
      'fw-normal color-navy': !props.selected,
    })}>
      {props.display}
    </div>
  </div>

);

const ExpandableFilterSection: React.SFC<{
  header: string;
  values: string[];
  open?: boolean;
  options?: FilterPillProps[];
  onSelect: (value: string) => void;
  onReset: () => void;
  onOpen: () => void;
  onClose: () => void;
}> = (props) => (
  <div>
    <div className="py-1 px-1 bwb-1 bc-gray bgc-navy-100:hover cursor-pointer" onClick={props.options ? props.onOpen : undefined}>
      <div className="flex items-center">
        <div><h4 className="fw-bold fs-md">{props.header}</h4></div>
        <div className="flex-1 truncate fw-bold fs-xs color-primary ta-right px-p5">
          {props.values.length > 0
            ? (props.options || []).filter(({ value }) => props.values.indexOf(value) > -1).map((option) => option.display).join(', ')
            : 'All'
          }
        </div>
        <div><i className="mdi mdi-chevron-right fs-icon-1p5" /></div>
      </div>
    </div>
    {props.open && props.options && (
      <div className="p-absolute pin bgc-white flex flex-col">
        <div>
          <MobileFilterMenuHeader
            header={props.header}
            onClose={props.onClose}
            onReset={props.onReset}
            resettable={!!props.values && props.values.length > 0}
          />
        </div>
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-wrap">
            {props.options.map((option, i) => (
              <div key={option.value} className="w-1/2">
                <FilterItem
                  display={option.display}
                  selected={props.values.indexOf(option.value) > -1}
                  color={option.color}
                  onClick={() => props.onSelect(option.value)}
                  // @ts-ignore
                  forceBorder={(i === props.options.length - 1) && (i % 2 === 0)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="py-1 px-1">
          <Button className="w-full" size="lg" onClick={props.onClose}>
            Done
          </Button>
        </div>
      </div>
    )}
  </div>
)

export default ExpandableFilterSection;
