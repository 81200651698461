import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';

import {
  Mutation as DesignMutationResults,
  MutationpopulateDesignArtworkArgs,
  MutationcreateDesignsArgs,
} from 'inkp-design-sdk/types.g';
import {
  Mutation as OrderMutationResults,
  Query as OrderQueryResults,
  MutationswapOrderDesignArgs,
  MutationupdateCartArgs,
} from 'inkp-order-sdk/types.g';
import { Query as ProductQueryResults, QueryproductsByIdArgs, QueryproductByIdArgs } from 'inkp-product-sdk/types.g';

import { DESIGN_CART_SHAPE } from '../../states/global/designCart';
import { DESIGN_FRAGMENT } from '../../graphql/designs';

export const DESIGN_STATE_QUERY = gql`
  {
    designCart @client ${DESIGN_CART_SHAPE}
  }
`;

export const ARTWORK_CREATION_MUTATION = gql`
  mutation CreateArtwork($url: String!) {
    createArtwork(url: $url) {
      id
      url
      mediumUrl
      dimensions {
        width
        height
      }
    }
  }
`;

export const ORDER_PRODUCT_DESIGNS_QUERY = gql`
  ${DESIGN_FRAGMENT}
  query OrderByIdAndUser($id: String!) {
    orderByIdAndUser(id: $id) {
      id
      quote {
        items {
          product {
            productItemId
            quantity
            productItem {
              color
              productId
            }
            product {
              id
              colors {
                name
              }
            }
          }
          design {
            ...DesignFragment
          }
        }
      }
    }
  }
`;

export const ORDER_PRODUCT_DESIGNS_BY_EMAIL_QUERY = gql`
  ${DESIGN_FRAGMENT}
  query OrderByIdAndEmail($id: String!, $email: String!) {
    orderByIdAndEmail(id: $id, email: $email) {
      id
      quote {
        items {
          product {
            productItemId
            quantity
            productItem {
              color
              productId
            }
            product {
              id
              colors {
                name
              }
            }
          }
          design {
            ...DesignFragment
          }
        }
      }
    }
  }
`;

export class QueryProducts extends Query<ProductQueryResults, QueryproductsByIdArgs> {}
export class QueryProduct extends Query<ProductQueryResults, QueryproductByIdArgs> {}

export class MutationSwapOrderDesign extends Mutation<OrderMutationResults, MutationswapOrderDesignArgs> {}
export const SWAP_ORDER_DESIGN_MUTATION = gql`
  mutation SwapOrderDesign($orderId: String!, $oldDesignId: String!, $newDesignId: String!) {
    swapOrderDesign(orderId: $orderId, oldDesignId: $oldDesignId, newDesignId: $newDesignId) {
      id
    }
  }
`;

export class MutationCreateDesigns extends Mutation<DesignMutationResults, MutationcreateDesignsArgs> {}
export const CREATE_DESIGNS_MUTATION = gql`
  mutation CreateDesigns($designs: [NewDesignInput!]!) {
    createDesigns(designs: $designs) {
      id
      sides {
        name
        shapes {
          id
        }
      }
    }
  }
`;

export class MutationCreateTempDesigns extends Mutation<DesignMutationResults, MutationcreateDesignsArgs> {}
export const CREATE_TEMP_DESIGNS_MUTATION = gql`
  mutation CreateTempDesigns($designs: [NewTempDesignInput!]!) {
    createTempDesigns(designs: $designs) {
      id
      sides {
        name
        artwork {
          colors {
            name
            hex
          }
          fullColor
        }
      }
    }
  }
`;

const CART_FRAGMENT = gql`
  ${DESIGN_FRAGMENT}
  fragment CartFragment on Cart {
    id
    items {
      product {
        quantity
        productItemId
        mockupUrl
        mockups {
          side
          mockupUrl
        }
        productItem {
          productId
          color
        }
        product {
          sides {
            name
            printZonesObjects {
              id
            }
          }
        }
      }
      designId
      design {
        ...DesignFragment
      }
    }
    coupons {
      code
    }
  }
`;


export class UpdateCartMutation extends Mutation<OrderMutationResults, MutationupdateCartArgs> {}
export const UPDATE_CART_MUTATION = gql`
  mutation UpdateCart($id: ID!, $data: UpdatableCartFields!) {
    updateCart(id: $id, data: $data) {
      ...CartFragment
    }
  }
  ${CART_FRAGMENT}
`;

export class CartQuery extends Query<OrderQueryResults> {}
export const CART_QUERY = gql`
  query CartByUser {
    cartByUser {
      ...CartFragment
    }
  }

  ${CART_FRAGMENT}
`;

export class QueryDesignItemQuote extends Query<OrderQueryResults> {}
export const QUERY_DESIGN_ITEM_QUOTE = gql`
  query RequestLimitedQuote($items: [LimitedQuoteItems!]!, $speed: SHIPPING_SPEED_ENUM) {
    requestLimitedQuote(items: $items, speed: $speed) {
      printType
      designId
      amounts {
        blanks
        printing
        shipping
        upcharge
        subtotal
        discount
        adjustment
        tax
        total
      }
      product {
        productItemId
        quantity
      }
    }
  }
`

