import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import Checkbox from 'inkp-components/dist/Components/Checkbox';

import {
  SORT_OPTIONS,
  genderFilters,
  sizeFilters,
  speedFilters,
  colorFilters,
  brandFilters,
  newFilters,
} from '../util';

import { Filters, } from '..';

const UNEXPANDED_LIMIT = 5;

function Header(props: {
  title: string;
  collapsed: boolean;
  onClick: () => void;
}) {
  return (
    <div className="flex color-navy bgc-navy-100:hover cursor-pointer py-p5 px-1" onClick={props.onClick}>
      <div className="flex-1 fw-bold">
        {props.title}
      </div>
      <div className="ta-right">
        <i className={`fs-icon-1p5 mdi mdi-chevron-${props.collapsed ? 'down' : 'up'}`} />
      </div>
    </div>
  );
}

function ShowAll(props: {
  title: string;
  expanded: boolean;
  onClick: () => void;
}) {
  return (
    <div className="w-full flex justify-center fs-xs cursor-pointer bgc-navy-100:hover py-p5" onClick={props.onClick}>
      <span className="pr-p5">Show All colors</span>
      <i className={`mdi mdi-chevron-${props.expanded ? 'up' : 'down'} fs-icon-1`} />
    </div>
  );
}

function Section(props: {
  collapsed: boolean;
  onToggleCollapsed: (collapsed: boolean) => void;
  expanded: boolean;
  onToggleExpand: (expanded: boolean) => void;
  title: string;
  options: {
    display: string;
    value: string;
    color?: string;
  }[];
  values?: string[];
  value?: string;
  onSelect: (value: string) => void;
}) {
  return (
    <div className="w-full bgc-blue-50">
      <Header title={props.title} collapsed={props.collapsed} onClick={() => props.onToggleCollapsed(!props.collapsed)} />
      {!props.collapsed && (
        <>
          {props.options.map(({ display, value, color }) => (
            <div key={value} className="flex">
              {color && (
                <div className={classnames('px-2px brr-full bc-navy-500', {
                  'bwr-1': color.toLowerCase() === 'white' || color.toLowerCase() === '#fff' || color.toLowerCase() === '#ffffff',
                })} style={{ backgroundColor: `${color}` }} />
              )}
              <Checkbox
                classNames={classnames('flex-1 py-p5 pr-1 bgc-navy-100:hover cursor-pointer', { 'pl-p75': !!color, 'pl-1': !color })}
                text={display}
                onChange={() => props.onSelect(value)}
                selected={(props.values && props.values.indexOf(value) > -1) || value === props.value}
              />
            </div>
          )).slice(0, props.expanded ? props.options.length : UNEXPANDED_LIMIT)}
          {props.options.length > UNEXPANDED_LIMIT && (
            <ShowAll title={props.title} onClick={() => props.onToggleExpand(!props.expanded)} expanded={props.expanded} />
          )}
        </>
      )}
    </div>
  );
}

export default function(props: {
  data?: any;
  filters: Filters;
  onNewFilters: (filters: Filters) => void;
  collapsedState: {[key: string]: boolean};
  onToggleCollapsed: (section: string, value: boolean) => void;
  expandState: {[key: string]: boolean};
  onToggleExpand: (section: string, value: boolean) => void;
}) {
  if (!props.data || !props.data.filterProducts) return null;
  const onSelect = (type: string) => (value: string) => props.onNewFilters(newFilters(props.filters, type, value));
  return (
    <div className="br overflow-hidden">
      <Section
        title="Gender"
        options={genderFilters(props.data.filterProducts.filters.genders)}
        onSelect={onSelect('genders')}
        collapsed={props.collapsedState['genders']}
        onToggleCollapsed={(val) => props.onToggleCollapsed('genders', val)}
        expanded={props.expandState['genders']}
        onToggleExpand={(val) => props.onToggleExpand('genders', val)}
        values={props.filters.genders}
      />
      <Section
        title="Color"
        options={colorFilters(props.data.filterProducts.filters.colors)}
        onSelect={onSelect('colors')}
        collapsed={props.collapsedState['colors']}
        onToggleCollapsed={(val) => props.onToggleCollapsed('colors', val)}
        expanded={props.expandState['colors']}
        onToggleExpand={(val) => props.onToggleExpand('colors', val)}
        values={props.filters.colors}
      />
      <Section
        title="Delivery Speed"
        options={speedFilters(props.data.filterProducts.filters.speeds)}
        onSelect={onSelect('speeds')}
        collapsed={props.collapsedState['speeds']}
        onToggleCollapsed={(val) => props.onToggleCollapsed('speeds', val)}
        expanded={props.expandState['speeds']}
        onToggleExpand={(val) => props.onToggleExpand('speeds', val)}
        values={props.filters.speeds}
      />
      <Section
        title="Brands"
        options={brandFilters(props.data.filterProducts.filters.brands)}
        onSelect={onSelect('brands')}
        collapsed={props.collapsedState['brands']}
        onToggleCollapsed={(val) => props.onToggleCollapsed('brands', val)}
        expanded={props.expandState['brands']}
        onToggleExpand={(val) => props.onToggleExpand('brands', val)}
        values={props.filters.brands}
      />
      <Section
        title="Sizes"
        options={sizeFilters(props.data.filterProducts.filters.sizes)}
        onSelect={onSelect('sizes')}
        collapsed={props.collapsedState['sizes']}
        onToggleCollapsed={(val) => props.onToggleCollapsed('sizes', val)}
        expanded={props.expandState['sizes']}
        onToggleExpand={(val) => props.onToggleExpand('sizes', val)}
        values={props.filters.sizes}
      />
    </div>
  );
}
