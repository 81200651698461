import React, { ChangeEvent, FormEvent } from 'react';
import _ from 'lodash';
import { DataProxy } from 'apollo-cache';
import { Query, Mutation, MutationFn, withApollo, WithApolloClient } from 'react-apollo';
import { Link } from 'react-router-dom';
import moment from 'moment';

import RightSidebar from 'inkp-components/dist/Components/RightSidebar';
import MobileSlideModal from 'inkp-components/dist/Components/MobileSlideModal';
import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';
import Loading from 'inkp-components/dist/Components/Loading';
import { routes, url } from 'inkp-routes/public';
import mockupRoutes from 'inkp-mockup-sdk/routes';
import { isUserRegistered } from 'inkp-user-sdk/user';
import { Mutation as UsersMutationResults} from 'inkp-user-sdk/types.g';
import { Shareable } from 'inkp-design-sdk/types.g';
import { Product } from 'inkp-product-sdk/types.g';

import { SHAREABLES_BY_USER, SHAREABLES_BY_USER_EMAIL } from '../../../graphql/shareable';
import { findServiceError } from '../../../util/apollo';
import { QUERY_CART_BY_USER } from '../../../util/Checkout';

// Utils
import {
  QUERY_CURRENT_USER,
  QueryCurrentUser,
  QUERY_USER_BY_EMAIL,
  QueryUserByEmail,
  MUTATION_SIGN_IN,
  MutationSignIn,
} from '../../../graphql/users';
import { updateCurrentUser } from '../../../graphql/users';


interface Props {
  onSelectDesign: (shareable: Shareable) => void;
}

interface State {
  email: string;

  fields: {
    email: string;
    password?: string;
  };

  errors: {
    field: string;
    message: string;
  }[]
}

class RetrieveDesign extends React.Component<Props, State> {
  state: State = {
    email: '',
    fields: {
      email: '',
    },
    errors: [],
  }

  submit = (signIn: MutationFn, signInRequired: boolean) => {
    const { fields: { email, password }} = this.state;
    if (signInRequired) {
      return signIn({ variables: { email, password } });
    }
    this.setState({ email });
  }

  updateField = (field: string, value: string) => {
    this.setState({ 
      fields: {
        ...this.state.fields,
        [field]: value,
      } 
    });
  }

  clearError = () => {
    this.setState({ errors: [] });
  }

  setSignInError = (error: any) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'INVALID_CREDENTIALS') {
      return this.setError(serviceError.data.data.field, serviceError.data.message);
    }
    this.setError('general', 'Error encountered');
  };

  setError = (field: string, message: string) => {
    this.setState(({ errors }) => {
      if (_.find(errors, { field })) return { errors };
      return { errors: errors.concat({ field, message })};
    });
  }

  renderLogin() {
    const { fields: { email, password }, errors } = this.state;

    return (
      <QueryUserByEmail query={QUERY_USER_BY_EMAIL} variables={{ email }} skip={!email}>
        {({ error, loading, data }) => {
          if (error) return null;
          const signInRequired = !!(data && data.userByEmail);

          return (
            <MutationSignIn
              mutation={MUTATION_SIGN_IN}
              update={(cache: DataProxy, { data }: { data: UsersMutationResults }) => {
                updateCurrentUser(cache, data.signIn);
              }}
              refetchQueries={[{ query: QUERY_CART_BY_USER }]}
              onError={this.setSignInError}
            >
              {(signIn: MutationFn) => {
                const emailError = _.find(errors, { field: 'email' });
                const passwordError = _.find(errors, { field: 'password' });

                const disableSubmit = !email || (signInRequired && !password);

                return (
                  <div>
                    <div className="fw-bold ta-center mt-1p5">
                      {signInRequired ? (
                        <span>
                          Looks like you have an account with us,<br />
                          sign in to retrieve design
                        </span>
                      ) : (
                        'Enter email you used to save design'
                      )}
                    </div>
                    <form
                      onSubmit={(event: FormEvent) => {
                        event.preventDefault();
                        if (disableSubmit) return;
                        this.clearError();
                        this.submit(signIn, signInRequired);
                      }}
                    >
                      <div className="w-full px-1 md:px-0 md:w-3/5 md:mx-auto">
                        <Input
                          className="mt-1p5"
                          inputType="email"
                          label="Email"
                          placeholder="Email"
                          autoFocus={true}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            this.updateField('email', event.target.value.toLowerCase());
                          }}
                          disabled={false}
                          removeInnerLabel={false}
                          value={email}
                          error={!!emailError}
                          helper={_.get(emailError, 'message')}
                          helperColor={emailError ? 'red' : 'green'}
                        />
                        {signInRequired && (
                          <>
                            <Input
                              className="mt-1p5"
                              label="Password"
                              placeholder="Password"
                              inputType="password"
                              autoFocus={true}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                this.updateField('password', event.target.value);
                              }}
                              value={password}
                              error={!!passwordError}
                              helper={_.get(passwordError, 'message')}
                              helperColor={passwordError ? 'red' : 'green'}
                            />
                            <div className="fs-xs ta-right mt-p25 ">
                              <Link to={routes.app.account.forgotPassword} target="_blank">
                                Forgot Password
                              </Link>
                            </div>
                          </>
                        )}
                        <div className="mt-1p5">
                          <Button
                            buttonType="submit"
                            className="w-full"
                            color="primary"
                            disabled={disableSubmit}
                          >
                            {signInRequired ? 'Sign In & Retrieve Design' : 'Retrieve Design'}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                );
              }}
            </MutationSignIn>
          );
        }}
      </QueryUserByEmail>
    );
  }

  renderDesigns(email: string, userId?: string) {
    return (
      <Query
        query={userId ? SHAREABLES_BY_USER : SHAREABLES_BY_USER_EMAIL}
        variables={{ email, includeDeleted: false }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, fetchMore }: any) => {
          if (error) return <div>Error encountered</div>
          if (loading || !data) return <Loading size="large" />;
          const shareables: Shareable[] = data.shareables;

          if (_.isEmpty(shareables)) return (
            <div className="ta-center color-navy-500 fw-extra-bold fs-xl">
              You have no saved designs yet!
            </div>
          )

          const sortedShareables = shareables.sort((shareable1, shareable2) => {
            const time1 = new Date(shareable1.products[0].design!.updatedAt).getTime();
            const time2 = new Date(shareable2.products[0].design!.updatedAt).getTime();
            return time2 - time1;
          });
          
          return (
            <div className="mx-1 lg:mx-3">
              <div className="flex justify-between mt-1p5">
                <div className="fw-extra-bold">Saved Designs</div>
                <div className="color-navy-500 fw-bold">{sortedShareables.length} Designs</div>
              </div>
              {/* <WithInfiniteScroll
                onPaginatedNext={() => {
                  if (loading) return;
                  if (!data) return;
                  if (data.metaData.page >= data.metaData.pages)
                    return;
                }}
                loading={loading}
              >
              </WithInfiniteScroll> */}
                <div className="flex flex-wrap -mx-p5 md:-mx-p75">
                  {sortedShareables.map((shareable: Shareable) => {
                    const shareableProduct = shareable.products[0];
                    const design = shareableProduct.design;
                    const sideName = design!.sides[0].name;
                    const product = shareableProduct.product as Product;
                    const templateIdentifier = product!.designTemplates.find(
                      (template) => (template.side as string) == sideName
                    );
                    let selectedColor = shareableProduct.colors.find((color) => {
                      return color.selected == true;
                    });
                    // If there's no color selected, we set the first color on the array
                    if (!selectedColor) {
                      selectedColor = shareableProduct.colors[0];
                    }
                    const selectedColorHex = product.colors.find((color) => color.name == selectedColor!.name)!.hex;
                    const mockupUrl = url('mockup', mockupRoutes.mockup, {
                      templateIdentifier: templateIdentifier!.id, 
                      designId: design!.id,
                      size: 'medium',
                      side: sideName,
                      color: selectedColorHex || 'ffffff',
                    });
      
                    return (
                      <div key={shareable.id} className="w-1/2 p-p5 md:p-p75">
                        <div
                          className="br bw-1 bc-navy-200 cursor-pointer p-p5 md:p-p75"
                          onClick={() => this.props.onSelectDesign(shareable)}
                        >
                          <div>
                            <img src={mockupUrl} />
                          </div>
                          <div className="fw-bold fs-xs truncate mt-p5 md:mt-p75">{shareable.name}</div>
                          <div className="fs-xs color-navy-700 mt-p25">{moment(shareable.createdAt).format('MMM DD, YYYY')}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
            </div>
          );
        }}
      </Query>
    );
  }

  render() {
    return (
      <QueryCurrentUser query={QUERY_CURRENT_USER}>
        {({ loading: currentUserLoading, error: currentUserError, data: currentUserData }) => {
          if (currentUserError || currentUserLoading || !currentUserData || !currentUserData.currentUser) return null;
          const { currentUser } = currentUserData;

          const email = (isUserRegistered(currentUser) && currentUser.email) || this.state.email;
          if (!email) return this.renderLogin();

          return this.renderDesigns(email, isUserRegistered(currentUser) ? currentUser.id : undefined);
        }}
      </QueryCurrentUser>
    )
  }

}

export default function(props: {
  onSelectDesign: (shareable: Shareable) => void;
  onClose: () => void;
}) {
  return (
    <>
      <div className="d-n md:d-b">
        <RightSidebar
          show={true}
          width="556px"
          title="Retrieve Design"
          onBackButtonClick={props.onClose}
          onOverlayClick={props.onClose}
        >
          <RetrieveDesign
            onSelectDesign={props.onSelectDesign}
          />
        </RightSidebar>
      </div>
      <div className="md:d-n">
        <MobileSlideModal
          show={true}
          title="Retrieve Design"
          onBackButtonClick={props.onClose}
          onOverlayClick={props.onClose}
          slideDirection="up"
        >
          <RetrieveDesign
            onSelectDesign={props.onSelectDesign}
          />
        </MobileSlideModal>
      </div>
    </>
  );
}