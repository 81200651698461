import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import Color from 'color';

import { ProductSize } from 'inkp-product-sdk/types.g';
import { url } from 'inkp-routes/public';
import mockupRoutes from 'inkp-mockup-sdk/routes';
import { prettyPrice } from 'infra-util/billing';

import TextArea from 'inkp-components/dist/Components/TextArea';
import Loading from 'inkp-components/dist/Components/Loading';
import PriceDisplayer from 'inkp-components/dist/Components/PriceDisplayer';
import ColorPicker from 'inkp-components/dist/Components/ColorPicker';

import ProductQuantitySelector from '../../../../components/ProductQuantitySelector';
import { DesignCartDesign as Design } from '../../../../states/global/designCart';
import { isTouchSupported } from '../../../../util/touch';

interface ProductColor {
  active: boolean;
  selected: boolean;
  name: string;
  hex: string;
  sizes: string[];
  sizesObjects: ProductSize[];
  images: {
    url: string;
    label: string;
  }[];
}

export interface DesignToolQuote {
  subtotal: number;
  qty: number;
  loading: boolean;
}

export interface ProductProps {
  id: string;
  name: string;
  active: boolean;
  detailTemplates: {
    id: string
    side: string
  }[]
  colors: ProductColor[];
  design: Design;
  designIndex: number;
  sizesQuantities: {
    name: string;
    qty: number;
  }[];
}

function ProductColorSelector(props: {
  product: ProductProps;
  onSelectColor: (data: { productId: string, color: string }) => void;
  onDisplayColor: (data: { productId: string, color: string }) => void;
}) {
  const { product, onSelectColor, onDisplayColor } = props;
  const selectedColor = (
    product.colors.find(({ selected }) => selected) || product.colors[0]
  ).name;
  return (
    <>
      <div className="d-n lg:d-b bgc-gray-50 px-1 py-p5">
        <ColorPicker
          limit={9999}
          colors={product.colors.map((color) => Object.assign({}, color, { active: color.selected })).sort((colorA, colorB) => {
            return Color(`#${colorA.hex}`).hue() - Color(`#${colorB.hex}`).hue()
          })}
          gutterWidth={0.5}
          gutterHeight={0.5}
          colorPerRow={13}
          onClick={(value: string) => {
            onSelectColor({ productId: product.id, color: value });
          }}
          onMouseEnter={(value: string) => {
            onDisplayColor({ productId: product.id, color: value });
          }}
          onMouseLeave={() => {
            // set display color back to selected color
            onDisplayColor({ productId: product.id, color: selectedColor});
          }}
          showTooltip={true}
          isTouchSupported={isTouchSupported()}
        />
      </div>
      <div className="lg:d-n bgc-gray-50 px-1 py-p5">
        <ColorPicker
          limit={9999}
          colors={product.colors.map((color) => Object.assign({}, color, { active: color.selected })).sort((colorA, colorB) => {
            return Color(`#${colorA.hex}`).hue() - Color(`#${colorB.hex}`).hue()
          })}
          gutterWidth={0.5}
          gutterHeight={0.5}
          colorPerRow={7}
          onClick={(value: string) => {
            onSelectColor({ productId: product.id, color: value });
          }}
          onMouseEnter={(value: string) => {
            onDisplayColor({ productId: product.id, color: value });
          }}
          onMouseLeave={() => {
            // set display color back to selected color
            onDisplayColor({ productId: product.id, color: selectedColor});
          }}
          showTooltip={true}
          isTouchSupported={isTouchSupported()}
        />
      </div>
    </>
  )
}

function mockupUrl(props: { product: ProductProps, hex: string, side: string, defaultUrl?: string }) {
  let imageUrl = props.defaultUrl;
  if (props.product.detailTemplates) {
    const mockupTemplate = props.product.detailTemplates.find((detailTemplate) => {
      return detailTemplate.side === props.side;
    });

    if (mockupTemplate) {
      imageUrl = url('mockup', mockupRoutes.blank, { templateIdentifier: mockupTemplate.id, color: props.hex, size: 'thumb'});
    }
  }
  return imageUrl;
}

interface Props {
  editable: boolean;
  product: ProductProps;
  quote: DesignToolQuote;
  sideColors: { [key: string]: number };
  tab?: 'color' | 'qty' | 'note' | null;
  onSelectColor: (data: { productId: string, color: string }) => void;
  onDisplayColor: (data: { productId: string, color: string }) => void;
  onRemoveProduct: (productId: string) => void;
  onUpdateQty: (size: string, qty: number) => void;
  onUpdateNote: (note: string, designIndex: number) => void;
  onTabClicked: (value: string | null) => void;
}

class ProductSection extends React.Component<Props> {
  _blurTimeout: NodeJS.Timeout;

  onBlurTab = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (!this.props.tab) return;
    this._blurTimeout = setTimeout(() => {
      this.props.onTabClicked(null);
    }, 0);
  }

  // This is ncessary so that clicking inner elements won't trigger onBlur for parent div
  onFocusTab = (e: React.ChangeEvent<HTMLDivElement>) => {
    clearTimeout(this._blurTimeout);
  }

  renderColorSelector() {
    const { editable, product, onSelectColor, onTabClicked, onDisplayColor } = this.props;
    return (
      <>
        <div className="bwt-1 bc-navy-200 bgc-gray-50">
          <ProductColorSelector
            product={product}
            onSelectColor={onSelectColor}
            onDisplayColor={onDisplayColor}
          />
        </div>
        <div
          className="bc-navy-200 bwt-1 brb cursor-pointer py-p75 ta-center fw-bold"
          onClick={() => onTabClicked(null)}
        >
          Done
        </div>
     </>
    );
  }

  renderQtySelector() {
    const { editable, product, onTabClicked, onUpdateQty } = this.props;

    const color = _.find(product.colors, { selected: true });

    if (!color) return null;
    const availableSizes: ProductSize[] = color.sizesObjects;

    const sizesQuantities: { [size: string]: number } = {};
    product.sizesQuantities.forEach(({ name, qty }) => {
      if (qty > 0) {
        sizesQuantities[name] = qty;
      }
    })

    return (
      <>
        <div className="bwt-1 bc-navy-200 bgc-gray-50 pb-1">
          <ProductQuantitySelector
            className="mt-1 px-p75 md:px-1"
            sizeBoxPaddingX="p25"
            availableSizes={availableSizes}
            sizesQuantities={sizesQuantities}
            onQuantityChange={onUpdateQty}
          />
        </div>
        <div
          className="bc-navy-200 bwt-1 brb cursor-pointer py-p75 ta-center fw-bold"
          onClick={() => onTabClicked(null)}
        >
          Done
        </div>
      </>
    )
  }

  renderAddNote() {
    const { product, onTabClicked, onUpdateNote } = this.props;
    return (
      <>
        <div className="bwt-1 bc-navy-200 bgc-gray-50 p-1">
          <TextArea
            value={product.design.userNote}
            placeholder="Add Special Note (e.g. special artwork instruction, background removal, pms colors, etc.)"
            resize={true}
            autoFocus={true}
            onChange={(e) => onUpdateNote(e.target.value, product.designIndex)}
          />
        </div>
        <div
          className="bc-navy-200 bwt-1 brb cursor-pointer py-p75 ta-center fw-bold"
          onClick={() => onTabClicked(null)}
        >
          Done
        </div>
      </>
    );
  }

  render() {
    const { editable, product, tab, onTabClicked, quote, sideColors } = this.props;
    const activeColor = product.colors.find(({ selected }) => selected) || product.colors[0];
    const designSideImages = [];
    const totalQty = _.sumBy(product.sizesQuantities, 'qty');

    // DesignSide images and ink color
    const inkColorLabels = [];
    for (const side of product.design.sides) {
      if (side.shapes && side.shapes.length <= 0) continue;
      const sideImage = activeColor!.images.find(({ label }: any) => label === _.capitalize(side.name));
      if (side.name !== 'FRONT') {
        designSideImages.push(mockupUrl({ product, side: side.name, hex: activeColor.hex, defaultUrl: sideImage && sideImage.url }));
      }
      if (sideColors[side.name] > 0) {
        inkColorLabels.push(`${sideColors[side.name]} ${_.capitalize(side.name)}`);
      }
    }

    // Image thumbnail
    const image = activeColor!.images.find(({ label }) => label === 'Front') || activeColor!.images[0];
    const imageUrl = mockupUrl({ product, side: 'FRONT', hex: activeColor.hex, defaultUrl: image && image.url });
    const imageDimensions = { width: 80, height: 112 };
    const additionalImageDimensions = {
      width: imageDimensions.width / (designSideImages.length || 1),
      height: 34,
    };

    return (
      <div className={classnames({
        'bc-navy-500': !product.active,
        'bc-navy': product.active,
      }, 'bw-1 br mt-1 bgc-white')}>
        <div className="flex p-p75 br p-relative minh-6">
          <div className="flex-none">
            <img width={`${imageDimensions.width}px`} src={imageUrl} />
            {designSideImages &&
              <div className="flex justify-center">
                {designSideImages.map((sideUrl) => {
                  return (
                    <div key={sideUrl} className="ta-center bgc-blue-50" style={{ width: `${additionalImageDimensions.width}px`}}>
                      <img height={`${additionalImageDimensions.height}px`} src={sideUrl}/>
                    </div>
                  );
                })}
              </div>
            }
          </div>
          <div className="flex-grow ml-p75 p-relative">
            <div>
              <div className="fs-xs fw-bold">{product.name}</div>
              <div className="fs-xs color-navy-700">{activeColor.name}</div>
              {inkColorLabels.length > 0 &&
                <div className="fs-xs color-navy-700">
                    Ink colors:&nbsp;{inkColorLabels.join(', ')}
                </div>
              }
            </div>

            <div className="p-absolute pin-l pin-b pin-r flex justify-between">
              {quote.loading ? (
                <Loading size="small" loadingText="" inline={true} paddingClassName="" />
              ) : (
                <>
                  <div>
                    <PriceDisplayer
                      price={quote.subtotal / quote.qty}
                      sign={'$'}
                      numUnits={1}
                      priceClassName={'fs-md'}
                      unitsClassName={'fs-xs color-navy-700 fw-bold'}
                      label={null}
                    />
                  </div>
                  <div>
                    <span className="color-navy-700 fs-xs fw-bold">Subtotal</span>&nbsp;
                    <span className={`${quote.qty > 0 ? 'color-primary' : 'color-navy-500'} fs-xs fw-bold`}>
                      {quote.qty > 0 ? prettyPrice(quote.subtotal) : '$--'}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {editable && (
          <div tabIndex={-1} onBlur={this.onBlurTab} onFocus={this.onFocusTab}>
            <div className="flex color-navy-700 fs-sm fw-bold bwt-1 bc-navy-200">
              <div
                className={`flex-1 ta-center cursor-pointer py-p5 ${tab === 'color' ? 'bgc-gray-50 color-primary' : ''}`}
                onClick={() => onTabClicked('color')}
              >
                Change Color
              </div>
              <div className="bwl-1 bc-navy-200" />
              <div
                className={`flex-1 ta-center cursor-pointer py-p5 ${tab === 'qty' ? 'bgc-gray-50 color-primary' : ''}`}
                onClick={() => onTabClicked('qty')}
              >
                Edit Quantity - {totalQty}
              </div>
              <div className="bwl-1 bc-navy-200" />
              <div
                className={`flex-1 ta-center cursor-pointer py-p5 ${tab === 'note' ? 'bgc-gray-50 color-primary' : ''}`}
                onClick={() => onTabClicked('note')}
              >
                Special Note
              </div>
            </div>
            {(tab === 'color') && this.renderColorSelector()}
            {(tab === 'qty') && this.renderQtySelector()}
            {(tab === 'note') && this.renderAddNote()}
          </div>
        )}
      </div>
    );
  }
}

export default function(props: {
  editable: boolean;
  products: ProductProps[];
  quote: DesignToolQuote;
  sideColors: { [key: string]: number };
  tab: 'color' | 'qty' | null;
  onTabClicked: (value: string | null) => void;
  onSelectColor: (data: { productId: string, color: string }) => void;
  onDisplayColor: (data: { productId: string, color: string }) => void;
  onRemoveProduct: (productId: string) => void;
  onUpdateQty: (size: string, qty: number) => void;
  onUpdateNote: (note: string, designIndex: number) => void;
  onClickAddProduct: () => void;
}) {
  return (
    <div className="px-1 lg:px-0">
      <div className="w-full d-n lg:flex items-center justify-between mt-1p5">
        <div className="fw-extra-bold fs-lg">Edit Products</div>
        {props.editable && (
          <div
            className="change-product-btn fs-xs fw-bold color-primary bgc-primary-100:hover bw-1 br-1p5 bc-primary cursor-pointer pl-p5 pr-p75"
            onClick={props.onClickAddProduct}
          >
            <style jsx>
            {`
              .change-product-btn {
                padding-top: 6px;
                padding-bottom: 6px;
              }
            `}
            </style>
            <i className="mdi mdi-tshirt-crew fs-md-n align-middle" /> Change Product
          </div>
        )}
      </div>
      <div className="mb-2">
        {props.products.map((product) => (
          <ProductSection
            key={product.id}
            editable={props.editable}
            product={product}
            quote={props.quote}
            sideColors={props.sideColors}
            onSelectColor={props.onSelectColor}
            onDisplayColor={props.onDisplayColor}
            onRemoveProduct={props.onRemoveProduct}
            onUpdateQty={props.onUpdateQty}
            onUpdateNote={props.onUpdateNote}
            tab={product.active ? props.tab : null}
            onTabClicked={props.onTabClicked}
          />
        ))}
      </div>
      {props.editable && (
        <div
          className="w-full br-full color-primary bc-primary bgc-primary-100:hover bw-1 cursor-pointer ta-center p-relative py-p75 fw-bold mb-2"
          onClick={props.onClickAddProduct}
        >
          Change Product
        </div>
      )}
    </div>
  );

}
