import * as React from 'react';
import Modal from 'inkp-components/dist/Components/Modal';
import { UPLOAD_ERROR_CODE } from 'inkp-components/dist/Components/FileUpload';

import { ACCEPT } from '../DesignToolControl/ImageUpload';

export default (props: {
  error: {
    code: UPLOAD_ERROR_CODE;
  };

  onDismiss: () => void;
  onReupload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const {
    error,
    onDismiss,
    onReupload,
  } = props;

  return (
    <Modal>
      <div>
        <div className="flex py-1p5 px-1">
          <div>
            <i className="mdi mdi-alert-circle color-red fs-lg fs-lg-icon mr-1" />
          </div>
          {error.code === UPLOAD_ERROR_CODE.FILE_TOO_BIG && (
            <div className="flex-1 color-navy">
              <div className="fw-bold fs-lg mb-p5">
                File Size Over 25MB
              </div>
              <div>
                Please choose a file that is less than 25MB.
              </div>
            </div>
          )}
          {error.code === UPLOAD_ERROR_CODE.UPLOAD_REJECTED && (
            <div className="flex-1 color-navy">
              <div className="fw-bold fs-lg mb-p5">
                Something Wrong Happened.
              </div>
              <div>
                Please try again later.
              </div>
            </div>
          )}
        </div>
        <div className="bc-gray bwt-1 flex">
          <div className="w-1/2 ta-center py-p75 cursor-pointer bc-gray bwr-1" onClick={onDismiss}>
            Close
          </div>
          <label className="w-1/2 ta-center py-p75 cursor-pointer fw-bold">
            Choose Image
            <input
              type="file"
              style={{
                display: 'none',
                zIndex: 0,
              }}
              accept={ACCEPT.join(',')}
              onChange={onReupload}
            />
          </label>
        </div>
      </div>
    </Modal>
  )
}

