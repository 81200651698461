import * as React from 'react';
import classnames from 'classnames';

interface Props {
  /** Additional classNames */
  className?: string;

  /** Width of the Image to be displayed */
  width: number;

  /** Height of the Image to be displayed */
  height: number;

  /** Lable of the Image */
  label: string;

  /** URL of the Image */
  url: string;

  /** onClick handler */
  onClick: (label: string) => void;

  /** Active Label */
  active?: string;

  /** Color for Active Label */
  colorActive?: string;
}

class ImageThumbnail extends React.Component<Props> {
  render() {
    const { className, label, url, onClick, active, colorActive } = this.props;
    const classActive = colorActive || 'coral';
    const activeImage = (active && active.toLowerCase()) === label.toLowerCase() ? true : false;
    return (
      <div className={classnames(['vis-hidden md:vis w-full ta-center', className])} onClick={() => onClick(label)}>
        <img
          className={classnames(
            { [`bc-${classActive}`]: activeImage, 'bc-navy-500': !activeImage },
            'bw-1 br cursor-pointer'
          )}
          src={url}
          key={url}
          alt={label}
        />
        <p
          className={classnames([
            'mb-p5 cursor-pointer fw-bold',
            { [`color-${classActive}`]: activeImage, 'color-navy-500': !activeImage },
          ])}
        >
          {label}
        </p>
      </div>
    );
  }
}

export default ImageThumbnail;
