import { DesignSide, SHAPE_TYPE_ENUM } from 'inkp-design-sdk/types.g';

export function getFontsNamesFromDesignSide(designSide: DesignSide): string[] {
  const fontFamiliesNames: string[] = [];
  if (!designSide.shapes || (designSide.shapes && designSide.shapes.length === 0)) {
    return fontFamiliesNames;
  }

  for (const shape of designSide.shapes) {
    if (shape.type === SHAPE_TYPE_ENUM.TEXT && shape.fontFamily && fontFamiliesNames.indexOf(shape.fontFamily) === -1) {
      fontFamiliesNames.push(shape.fontFamily);
    }
  }

  return fontFamiliesNames;
}
