import * as React from 'react';
import classnames from 'classnames';

export enum Tool {
  PRODUCT = 'PRODUCT',
  IMAGE = 'IMAGE',
  CLIPART = 'CLIPART',
  TEXT = 'TEXT',
}

interface Props {
  tools: string[];
  onSelectTool: (tool: Tool) => void;
  onStartOver: () => void;
}

interface State {}

function ActionTile(props: {
  value: string;
  title: string;
  icon: string;
  active?: boolean;
  onSelectTool: (tool: string) => void
}) {
  const { value, onSelectTool, title, icon } = props;
  return (
    <div className="w-1/2 px-p5 py-p5">
      <div
        onClick={() => onSelectTool(value)}
        className="ta-center br bw-1 p-relative cursor-pointer bc-navy-200 bc-primary:hover bgc-white:hover group"
        style={{ height: '224px' }}
      >
        <div className="p-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <i className={`mdi mdi-${icon} fs-icon-2 mb-p75 color-navy-700 color-primary:group-hover`} />
          <div className="fw-bold color-navy-700 color-primary:group-hover">{title}</div>
        </div>
      </div>
    </div>
  );
}

const ACTION_DISPLAY: {
  [key in Tool]: { display: string; icon: string }
} = {
  [Tool.IMAGE]: { display: 'Upload Image', icon: 'image' },
  [Tool.TEXT]: { display: 'Add Text', icon: 'format-size' },
  [Tool.CLIPART]: { display: 'Add Clip Art', icon: 'emoticon-happy-outline' },
  [Tool.PRODUCT]: { display: 'Add Products', icon: 'tshirt-crew' },
};

class DefaultMenu extends React.Component<Props, State> {
  render() {
    const {
      tools,
      onSelectTool,
      onStartOver,
    } = this.props;
    return (
      <div className="w-full">
        <div className="d-n lg:flex flex-wrap -mx-p5">
          {tools.map((tool: Tool) => {
            const toolAction = ACTION_DISPLAY[tool];
            return (
              <div key={tool}>
                {ActionTile({
                  value: tool,
                  title: toolAction.display,
                  icon: toolAction.icon,
                  onSelectTool,
                })}
              </div>
            );
          })}
        </div>
        <div className="d-n lg:flex justify-center mt-1">
          <button onClick={onStartOver}>
            <span className="color-navy-700 fw-bold">
              Start Over
              <i className="mdi mdi-cached fs-icon-1p5 ml-p25" />
            </span>
          </button>
        </div>
      </div>
    );
  }
}

export default DefaultMenu;
