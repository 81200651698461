import * as _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import { useDrag, useDrop, useDragLayer, DropTargetMonitor, DragPreviewImage } from 'react-dnd';
import { Shape, ShapeType } from '../../../interfaces/Canvas';

const ICONS: {
  [key in ShapeType]: string
} = {
  [ShapeType.IMAGE]: 'image',
  [ShapeType.TEXT]: 'format-size',
};

// Separate the item and the dragable component so we can use the item as drag preview later
function LayerItem(props: {
  shape: Shape;
}) {
  const { type, display, id } = props.shape;
  return (
    <>
      <div className="mr-p5">
        <i className={`mdi mdi-${ICONS[type]} fs-icon-1p5`} />
      </div>
      <div className="flex-1 flex mr-p5 items-center">
        <span className="mr-p5 fw-bold capitalize">
          {type}
        </span>
        <span className="color-navy-500 fw-bold fs-sm">
          {display}
        </span>
      </div>
      <div>
        <i className="mdi mdi-code-tags fs-icon-1p5 mdi-rotate-90" />
      </div>
    </>
  );
}

function DraggableLayerItem(props: {
  shape: Shape;
  onNewIndex: (data: { id: string, zIndex: number }) => void
}) {
  const ref = React.useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: 'shape',
    hover(item: any, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.shape.zIndex;
      const hoverIndex = props.shape.zIndex;

      // Determine dimensions & position on screen
      const hoverBoundingRect = ref.current!.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as { y: number }).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      props.onNewIndex({ id: item.shape.id, zIndex: hoverIndex });
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'shape', shape: props.shape },
    collect: monitor => {
      return ({
        isDragging: !!monitor.isDragging(),
      });
    },
  });

  drag(drop(ref));


  return (
    <>
      <style jsx={true}>{`
        .active-grab {
          cursor: grab;
        }

        .active-grab:active {
          cursor: grabbing;
        }

        .active-grab.grabbing {
          cursor: grabbing;
        }

      `}</style>
      <DragPreviewImage connect={preview} src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
      <div className={classnames('px-1 py-p75 lg:px-2 flex active-grab', { 'vis-hidden grabbing': isDragging, 'bgc-gray-100:hover': !isDragging })} ref={ref}>
        <LayerItem shape={props.shape} />
      </div>
    </>
  );

}

function DragLayer() {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
    initialClientOffset,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging) return null;
  const { shape } = item;
  const transform = `translate3d(${currentOffset!.x - initialOffset!.x}px, ${currentOffset!.y - (2 * initialOffset!.y) + (initialClientOffset!.y)}px, 0)`;
  return (
    <div style={{
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: 100,
      left: 0,
      top: 0,
      width: '100%',
      transform: transform,
	  WebkitTransform: transform,

    }} className="px-1 py-p75 lg:px-2 flex bgc-gray-100 cursor-pointer">
	  <LayerItem shape={shape} />
    </div>
  );
}

export default class LayerTool extends React.Component<{
  shapes: Shape[];
  onNewIndex: (data: { id: string, zIndex: number }) => void;
  onHide: (e: any) => void;
}> {
  render() {
    const { shapes, onNewIndex, onHide } = this.props;
    return (
      <div className="p-relative lg:p-fixed pin flex w-full">
        <div className="flex-1 bgc-navy opacity-50 d-n lg:d-b" onClick={onHide} />
        <div className="bgc-white w-full lg:w-464px shadow-none lg:shadow-3">
          <div className="py-1 ta-center p-relative d-n lg:d-b">
            <div className="p-absolute pin-r pin-t mt-p75 mr-2 overflow-hidden br-full p-p5 cursor-pointer bgc-navy-100:hover"  onClick={onHide}>
              <i className="mdi mdi-close fs-icon-1p5"/>
            </div>
            <h3 className="fw-bold fs-lg">Edit Layers</h3>
          </div>
          <div className="py-1 ta-center bgc-gray">
            Drag & drop the layers to reorganize
          </div>
          <div className="p-relative">
            <DragLayer />
            <div className="shapes-container pb-3">
              {_.reverse(_.sortBy(shapes, 'zIndex')).map((shape) => (
                <div key={shape.id} className="bwb-1 bc-gray p-relative">
                  <DraggableLayerItem
                    shape={shape}
                    onNewIndex={onNewIndex}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Fix max height to be 4.5 items + padding in mobile */}
        <style jsx={true}>{`
          .shapes-container {
            max-height: 264px;
            overflow-y: scroll;
          }

          @media only screen and (min-width: 1024px) {
            .shapes-container {
              max-height: unset;
              overflow-y: hidden;
            }
          }


        `} </style>
      </div>
    );
  }
}
