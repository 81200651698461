import { PrintZoneProps } from "../../../components/Canvas/PrintZone";

export function computeCenterPosition(position: string, shape: any, printZone: PrintZoneProps) {
  const vCenter = shape.height / 2;
  const hCenter = shape.width / 2;
  const vZoneCenter = printZone.height / 2;
  const hZoneCenter = printZone.width / 2;
  let x = shape.x;
  let y = shape.y;

  if (position === 'horizontal-center' ) {
    x = hZoneCenter - hCenter;
  } else if (position === 'vertical-center') {
    y = vZoneCenter - vCenter;
  }

  return { x, y };
}

export function toNumber(str: string) {
  return parseInt(str.replace(/[^\d-\.]/, ''));
}
