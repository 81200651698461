import { ColorPickerItem } from 'inkp-components/dist/Components/ColorPicker';

export const DEGREE_OPTIONS = [
  {
    key: '-180',
    value: '-180',
    display: '-180',
  },
  {
    key: '-135',
    value: '-135',
    display: '-135',
  },
  {
    key: '-90',
    value: '-90',
    display: '-90',
  },
  {
    key: '-45',
    value: '-45',
    display: '-45',
  },
  {
    key: '0',
    value: '0',
    display: '0',
  },
  {
    key: '45',
    value: '45',
    display: '45',
  },
  {
    key: '90',
    value: '90',
    display: '90',
  },
  {
    key: '135',
    value: '135',
    display: '135',
  },
  {
    key: '180',
    value: '180',
    display: '180',
  },
];

export const TEXT_COLORS: ColorPickerItem[] = [
  {
    hex: '000000',
    name: 'Black',
  },
  {
    hex: '8e9089',
    name: 'Grey',
  },
  {
    hex: 'c7c9c7',
    name: 'Light Grey',
  },
  {
    hex: 'ffffff',
    name: 'White',
  },
  {
    hex: 'cb333b',
    name: 'Red',
  },
  {
    hex: 'e53c2e',
    name: 'Orange Red',
  },
  {
    hex: 'ff3eb3',
    name: 'Hot Pink',
  },
  {
    hex: 'c5299b',
    name: 'Magenta',
  },
  {
    hex: 'f57eb6',
    name: 'Charity Pink',
  },
  {
    hex: 'fabbcb',
    name: 'Pink',
  },
  {
    hex: 'd9c89e',
    name: 'Tan',
  },
  {
    hex: 'f9e547',
    name: 'Lemon',
  },
  {
    hex: 'ffb81c',
    name: 'Athletic Gold',
  },
  {
    hex: 'ff6a13',
    name: 'Orange',
  },
  {
    hex: 'fdda24',
    name: 'Yellow',
  },
  {
    hex: 'b58500',
    name: 'Old Gold',
  },
  {
    hex: '7b4931',
    name: 'Brown',
  },
  {
    hex: '9d2235',
    name: 'Cardinal',
  },
  {
    hex: '7e2d40',
    name: 'Maroon',
  },
  {
    hex: '006747',
    name: 'Kelly Green',
  },
  {
    hex: '8edd65',
    name: 'Lime',
  },
  {
    hex: '154734',
    name: 'Forest',
  },
  {
    hex: '00843d',
    name: 'Green',
  },
  {
    hex: '827a04',
    name: 'Olive',
  },
  {
    hex: '9adbe8',
    name: 'Light Blue',
  },
  {
    hex: '3eb1c8',
    name: 'Turquoise',
  },
  {
    hex: '0254a2',
    name: 'Blue',
  },
  {
    hex: '003087',
    name: 'Royal',
  },
  {
    hex: '13294b',
    name: 'Navy',
  },
  {
    hex: 'cba3d8',
    name: 'Light Purple',
  },
  {
    hex: '3d0182',
    name: 'Purple',
  },
  {
    hex: '54585a',
    name: 'Dark Grey',
  },
];
