import * as React from 'react';
import { Query } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { MutationFn } from 'react-apollo';

import { Subscribe } from 'infra-frontend/helpers/apollo';
import { path, routes } from 'inkp-routes/public';
import * as t from 'inkp-design-sdk/types.g';
import {
  MutationupdateShareableArgs,
  ShareableProducItemInput,
  ShareableDesignInput,
  Shareable,
  Mutation as MutationDesignResults,
} from 'inkp-design-sdk/types.g';

import DesignTool from '../DesignTool/index2';
import DesignToolStateContainer from '../DesignTool/DesignToolStateContainer';
import DesignCart from '../../states/global/designCart';
import { normalizeDesigns } from '../../util/shareable';
import { SHAREABLE_BY_CODE_QUERY, MutationUpdateSharable, MUTATION_UPDATE_SHARABLE } from '../../graphql/shareable';

class TypedSubscribe extends Subscribe<[DesignToolStateContainer]> {}

function RestoreDesign(props: {
  designToolStateContainer: DesignToolStateContainer;
  shareable: t.Shareable;
  designCart: DesignCart;
  history: any;
  match: any;
  location: any;
  onNext: () => void;
}) {
  React.useEffect(() => {
    props.designCart.restoreFromShareable(props.shareable).then(() => {
      props.history.replace(path(props.location.pathname, params));
      props.designToolStateContainer.changeSaveField('designName', props.shareable.name);
    });
  }, []);

  const { designCart, shareable } = props;
  const params: { [key: string]: string } = {};
  const selectedProduct = (shareable.products as any).find(({ selected }: { selected: boolean }) => selected);
  if (selectedProduct) {
    params.product = selectedProduct.productId;
    const selectedColor = selectedProduct.colors.find(({ selected }: { selected: boolean }) => selected);
    if (selectedColor) {
      params.color = selectedColor.name;
    }
  }

  return <DesignTool shareable={shareable} designCart={designCart} designCartState={designCart.state} {...props} />;
}

function onNext(
  designCart: DesignCart,
  designToolStateContainer: DesignToolStateContainer,
  updateShareable: MutationFn<MutationDesignResults, MutationupdateShareableArgs>,
  shareable: Shareable
) {
  return () => {
    const { save } = designToolStateContainer.state;
    const { state } = designCart;
    const designId = state.products[0].designId;
    if (state.saved && designId) {
      designToolStateContainer.addToCart(designId, state.products[0].productId, state.products[0].colors[0]);
      designToolStateContainer.setShowCart(true, false);
      return;
    }

    designToolStateContainer.setShowCart(true, true);
    updateShareable({
      variables: {
        shareableId: shareable.id,
        shareable: {
          name: save.data.designName,
        },
        products: getProducts(designCart),
        designs: getDesigns(designCart),
      },
    });
  };
}

function getProducts(designCart: DesignCart) {
  const { products } = designCart.state;
  const normalizedProducts: ShareableProducItemInput[] = [
    {
      productId: products[0].productId,
      colors: [
        {
          name: products[0].colors[0],
          selected: true,
        },
      ],
      designIndex: 0,
      selected: true,
    },
  ];
  return normalizedProducts;
}

function getDesigns(designCart: DesignCart) {
  const { designs } = designCart.state;
  const normalizedDesigns: ShareableDesignInput[] = normalizeDesigns(designs);
  return normalizedDesigns;
}

function onSaved(designToolStateContainer: DesignToolStateContainer, designCart: DesignCart) {
  return (result: MutationDesignResults) => {
    const { updateShareable: shareable } = result;
    if (shareable) {
      const { design, productId, colors } = shareable.products[0];
      designToolStateContainer.saveShareable(shareable);
      designCart.assignDesignIds(shareable);
      designToolStateContainer.addToCart(design!.id, productId, colors[0].name);
    }
  };
}

interface EditDesignPageParams {
  code: string;
}

export default function(props: RouteComponentProps<EditDesignPageParams>) {
  return (
    <Subscribe to={[DesignCart]} namespace="Redesign">
      {(designCart: DesignCart) => (
        <TypedSubscribe to={[DesignToolStateContainer]} namespace="RedesignDesignToolInteraction">
          {(designToolStateContainer: DesignToolStateContainer) => (
            <Query query={SHAREABLE_BY_CODE_QUERY} variables={{ code: props.match.params.code }}>
              {({ loading, error, data: shareableData, client }: any) => (
                <MutationUpdateSharable
                  mutation={MUTATION_UPDATE_SHARABLE}
                  onCompleted={onSaved(designToolStateContainer, designCart)}
                >
                  {(updateShareable) => {
                    if (loading) return null;
                    if (error) return <Redirect to={routes.app.base} />;
                    if (!shareableData || !shareableData.shareableByCode) return <Redirect to={routes.app.base} />;

                    return (
                      <RestoreDesign
                        designToolStateContainer={designToolStateContainer}
                        shareable={shareableData.shareableByCode}
                        designCart={designCart}
                        history={props.history}
                        onNext={onNext(
                          designCart,
                          designToolStateContainer,
                          updateShareable,
                          shareableData.shareableByCode
                        )}
                        {...props}
                      />
                    );
                  }}
                </MutationUpdateSharable>
              )}
            </Query>
          )}
        </TypedSubscribe>
      )}
    </Subscribe>
  );
}
