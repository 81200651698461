import * as React from 'react';

interface Props {
  onClick: (label?: string) => void;
  active: boolean;
  icon: React.ReactNode;
  text: string;
  value: string;
  textStyles?: React.CSSProperties;
}

interface State {
  active: boolean;
}

class ActionButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  activateButton = (active: boolean) => {
    this.setState({ active });
  }

  render() {
    let active = this.props.active || this.state.active;

    return (
      <div
        className={`cursor-pointer color-${active ? 'primary' : 'navy-500'} ta-center`}
        onClick={() => this.props.onClick(this.props.value)}
        onMouseDown={() => this.activateButton(true)}
        onMouseUp={() => this.activateButton(false)}
        onTouchStart={() => this.activateButton(true)}
        onTouchEnd={() => this.activateButton(false)}
      >
        <div className="my-1 h-1p5">{this.props.icon}</div>
        <div className="fs-xs fw-bold" style={this.props.textStyles}>{this.props.text}</div>
      </div>
    );
  }
}

export default ActionButton;
