import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

import { prettyProductSize } from 'inkp-product-sdk/product';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';

import ContainerLeftFilterButtonMobile from 'inkp-components/dist/Components/MobileLeftFilter/ContainerLeftFilterButtonMobile';
import Button from 'inkp-components/dist/Components/Button';

import ExpandableFilterSection from '../ExpandableFilterSection';
import MobileFilterMenuHeader from '../MobileFilterMenuHeader';
import FilterPills, { FilterPillProps } from '../FilterPills';

import {
  SORT_OPTIONS,
  genderFilters,
  sizeFilters,
  speedFilters,
  colorFilters,
  brandFilters,
  newFilters,
} from '../util';

import { Filters, } from '..';


const FilterSection:React.SFC<{
  header: string;
  options?: FilterPillProps[];
  onSelect: (value: string) => void;
  values?: string[];
  value?: string;
}> = (props) => (
  <div className="bwb-1 bc-gray py-1">
    <h4 className="fw-bold fs-md px-1">
      {props.header}
    </h4>
    <div className="mt-1">
      <FilterPills
        options={props.options}
        onSelect={props.onSelect}
        values={props.values}
        value={props.value}
      />
      </div>
  </div>
);



function resetFilter(oldFilters: Filters, type: string): Filters {
  //@ts-ignore
  if (_.isArray(oldFilters[type])) {
    return Object.assign({}, oldFilters, {
      [type]: [],
    });
  }

  return Object.assign({}, oldFilters, {
    [type]: null,
  });

}

export default function(props: {
  data: any;
  filters: Filters;
  onClose: () => void;
  onNewFilters: (filters: Filters) => void;
  expandableSection: string | null;
  onExpandableOpen: (value: string) => void;
  onExpandableClose: () => void;
}) {
  const count = _.get(props.data, 'filterProducts.pageMetadata.count');
  const onSelect = (type: string) => (value: string) => props.onNewFilters(newFilters(props.filters, type, value));
  const resettable =
    !(props.filters.sort === 'popular'
    && props.filters.genders.length === 0
    && props.filters.sizes.length === 0
    && props.filters.colors.length === 0
    && props.filters.brands.length === 0);

  return (
    <div className="p-absolute pin bgc-white flex flex-col">
      <MobileFilterMenuHeader
        header="Filter"
        onClose={props.onClose}
        resettable={resettable}
        onReset={() => props.onNewFilters(Object.assign({}, props.filters, {
          sort: 'popular',
          genders: [],
          sizes: [],
          colors: [],
          brands: [],
        }))}
      />
      <div className="overflow-y-auto flex-1">
        <FilterSection
          header="Sort By"
          options={SORT_OPTIONS}
          onSelect={onSelect('sort')}
          value={props.filters.sort}
        />
        <FilterSection
          header="Gender"
          options={props.data ? genderFilters(props.data.filterProducts.filters.genders) : undefined}
          onSelect={onSelect('genders')}
          values={props.filters.genders}
        />
        <FilterSection
          header="Delivery Speed"
          options={props.data ? speedFilters(props.data.filterProducts.filters.speeds) : undefined}
          onSelect={onSelect('speeds')}
          values={props.filters.speeds}
        />
        <FilterSection
          header="Sizes"
          options={props.data ? sizeFilters(props.data.filterProducts.filters.sizes) : undefined}
          onSelect={onSelect('sizes')}
          values={props.filters.sizes}
        />
        <ExpandableFilterSection
          header="Colors"
          options={props.data ? colorFilters(props.data.filterProducts.filters.colors) : undefined}
          onSelect={onSelect('colors')}
          values={props.filters.colors}
          open={props.expandableSection === 'colors'}
          onReset={() => props.onNewFilters(resetFilter(props.filters, 'colors'))}
          onOpen={() => props.onExpandableOpen('colors')}
          onClose={props.onExpandableClose}
        />
        <ExpandableFilterSection
          header="Brands"
          options={props.data ? brandFilters(props.data.filterProducts.filters.brands) : undefined}
          onSelect={onSelect('brands')}
          values={props.filters.brands}
          open={props.expandableSection === 'brands'}
          onReset={() => props.onNewFilters(resetFilter(props.filters, 'brands'))}
          onOpen={() => props.onExpandableOpen('brands')}
          onClose={props.onExpandableClose}
        />
      </div>
      <div className="py-1 px-1 bwt-1 bc-gray">
        <Button className="w-full" size="lg" onClick={props.onClose}>
          {count >= 1
            ? `View ${count} Result${count > 1 ? 's' : ''}`
            : 'View Results'
          }
        </Button>
      </div>
    </div>
  );
}
