import * as React from 'react';
import * as _ from 'lodash';
import TrackVisibility from 'react-on-screen';
import { recalculateDesignSidesForProduct } from '../../states/global/designCart';
import { Product } from 'inkp-product-sdk/types.g';
import { Design, Font } from 'inkp-design-sdk/types.g';
import { DEFAULT_COLOR_HEX } from '../../util/constants';

import StyleFilter from 'inkp-components/dist/Components/StyleFilter';
import WithInfiniteScroll from 'inkp-components/dist/HOC/WithInfiniteScroll';
import ProductCardContainer from 'inkp-components/dist/Components/ProductCardContainer';
import TopRightFilter from 'inkp-components/dist/Components/TopRightFilter';
import { Props as ProductCardProps } from 'inkp-components/dist/Components/ProductCard';

import CatalogHeader from './CatalogHeader';
import MobileFilterButton from './MobileFilterButton';
import MobileBackToTop from './MobileBackToTop';
import MobileFilterMenu from './MobileFilterMenu';
import FilterMenu from './FilterMenu';
import FilterPills from './FilterPills';
import ProductCardCanvas from './ProductCardCanvas';

import {
  SORT_OPTIONS,
  genderFilters,
  sizeFilters,
  speedFilters,
  colorFilters,
  brandFilters,
  mapCatalogProducts,
} from './util';

export interface Filters {
  sort: string;
  genders: string[];
  colors: string[];
  sizes: string[];
  style: string | null;
  brands: string[];
  speeds: string[];
}

export interface CatalogAddToCartActionProps {
  product: Product;
  design: Design;
  colorName: string;
  previousProduct: Product;
}

export interface CatalogEditDesignActionsProps {
  product: Product;
  design: Design;
  colorName: string;
  previousProduct: Product;
}

export interface CatalogNewProductActions {
  onAddToCartClick: ({
    product,
    design,
    colorName,
    previousProduct,
  }: CatalogAddToCartActionProps) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEditDesignClick: ({
    product,
    design,
    colorName,
    previousProduct,
  }: CatalogEditDesignActionsProps) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function(props: {
  query: {
    loading: boolean;
    data: any;
  };
  collapsedState: { [key: string]: boolean };
  expandState: { [key: string]: boolean };
  expandableSection: string | null;
  filterMenuOpen: boolean;
  filters: Filters;
  design?: Design;
  fonts?: Font[];
  previousProduct?: Product;
  newProductActions?: CatalogNewProductActions;
  onBackToTop: () => void;
  onClickProduct: (product: any) => void;
  onExpandableClose: () => void;
  onExpandableOpen: (value: string) => void;
  onFilterButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  onFilterMenuClose: () => void;
  onNewFilters: (filters: Filters) => void;
  onToggleDesktopCollapsed: (section: string, value: boolean) => void;
  onToggleDesktopExpand: (section: string, value: boolean) => void;
}) {
  const loading = props.query.loading;
  const data = props.query.data;
  const styles = _.get(data, 'filterProducts.filters.styles');
  let items = _.get(data, 'filterProducts.items');
  const meta = _.get(data, 'filterProducts.pageMetadata');
  const page = _.get(data, 'filterProducts.pageMetadata.page');
  const pages = _.get(data, 'filterProducts.pageMetadata.pages');
  const { newProductActions, design, previousProduct, fonts } = props;

  let productCardProps: ProductCardProps[] =
    items && items.length
      ? mapCatalogProducts({
          products: items || [],
          filterColors: props.filters.colors,
          design,
          previousProduct,
          newProductActions,
        })
      : [];
  // attach canvas component
  if (items && items.length > 0 && productCardProps.length > 0 && design && previousProduct && fonts) {
    productCardProps = productCardProps.map((_productCardProps, index) => {
      const item: Product = items.find(({ id }: any) => id === _productCardProps.id);
      const product: Product = _.cloneDeep(item);
      const { hex = DEFAULT_COLOR_HEX } = _productCardProps;
      let updatedDesignForProduct: Design = _.cloneDeep(design);
      if (previousProduct) {
        updatedDesignForProduct = recalculateDesignSidesForProduct(
          [updatedDesignForProduct],
          product,
          previousProduct
        )[0];
      }
      const canvasComponent: React.ReactElement = (
        <ProductCardCanvas product={product} fonts={fonts} design={updatedDesignForProduct} hexColor={hex} />
      );
      return {
        ..._productCardProps,
        canvas: canvasComponent,
      };
    });
  }

  return (
    <div>
      <div className="fs-xs fw-bold ml-1 lg:ml-0 mt-p5 lg:mt-p75 mb-1p5 lg:mb-1 color-navy-500">All Products</div>
      <div className="flex mx-1 lg:mx-0 mb-p5 lg:mb-1 items-center">
        <div className="flex-1 fs-xl lg:fs-2xl fw-extra-bold">All Products</div>
        <div className="fs-xs fw-bold lg:d-n color-navy-500">{_.get(meta, 'count') || 0} Products</div>
      </div>
      {!!items && (
        <div className="py-p75 lg:pb-2 bwb-1 bc-gray lg:bwb-0">
          <FilterPills
            options={[
              {
                display: 'All',
                value: 'all',
              },
            ].concat(
              _.compact(styles as string[]).map((sleeve: string) => ({
                display: sleeve,
                value: sleeve,
              }))
            )}
            value={props.filters.style || 'all'}
            onSelect={(value) => {
              props.onNewFilters(
                Object.assign({}, props.filters, {
                  style: value === 'all' ? null : value,
                })
              );
            }}
          />
        </div>
      )}

      <div className="d-n lg:d-b">
        <TopRightFilter
          options={SORT_OPTIONS}
          selectedOptionIndex={_.findIndex(SORT_OPTIONS, { value: props.filters.sort }) || 0}
          filterSelections={genderFilters(props.filters.genders)
            .map((item) => Object.assign({ title: 'Gender', filter: 'genders' }, item))
            .concat(
              sizeFilters(props.filters.sizes).map((item) => Object.assign({ title: 'Sizes', filter: 'sizes' }, item))
            )
            .concat(
              colorFilters(props.filters.colors).map((item) =>
                Object.assign({ title: 'Color', filter: 'colors' }, item)
              )
            )
            .concat(
              brandFilters(props.filters.brands).map((item) =>
                Object.assign({ title: 'Brand', filter: 'brands' }, item)
              )
            )
            .concat(
              speedFilters(props.filters.speeds).map((item) =>
                Object.assign({ title: 'Delivery Speeds', filter: 'speeds' }, item)
              )
            )
            .map(({ display, value, filter, title }) => ({
              display: `${title}: ${display}`,
              onRemove: () =>
                props.onNewFilters(
                  Object.assign({}, props.filters, {
                    [filter]: (props.filters as any)[filter].filter((val: string) => val !== value),
                  })
                ),
            }))}
          resultCount={_.get(meta, 'count')}
          onFilterChange={({ value }: { value: string }) =>
            props.onNewFilters(Object.assign({}, props.filters, { sort: value }))
          }
          onClearFilters={() =>
            props.onNewFilters(
              Object.assign({}, props.filters, {
                sort: 'popular',
                genders: [],
                sizes: [],
                colors: [],
                brands: [],
                speeds: [],
              })
            )
          }
        />
      </div>
      {/**}
      <div className="mt-1p5 mx-2">
        <CatalogHeader count={_.get(meta, 'count')} mobileOnly={true} />
      </div>
      {**/}
      <div className="flex -mx-0 lg:-mx-p75 xl:-mx-1 mt-1p5 mb-3">
        <div className="d-n lg:d-b lg:w-1/4 xl:w-1/5 px-0 lg:px-p75 xl:px-1">
          <FilterMenu
            data={data}
            filters={props.filters}
            onNewFilters={props.onNewFilters}
            collapsedState={props.collapsedState}
            onToggleCollapsed={props.onToggleDesktopCollapsed}
            expandState={props.expandState}
            onToggleExpand={props.onToggleDesktopExpand}
          />
        </div>
        <div className="w-full lg:w-3/4 xl:w-4/5 px-1 lg:px-p75 xl:px-1">
          <ProductCardContainer
            isLoading={loading}
            productCardsProps={productCardProps}
            fluid={true}
            onClick={(productId: string) => {
              const product = items.find(({ id }: { id: string }) => id === productId);
              if (product) props.onClickProduct(product);
            }}
          />
        </div>
      </div>
      <MobileFilterButton onClick={props.onFilterButtonClick} />
      <MobileBackToTop onClick={props.onBackToTop} />
      {props.filterMenuOpen && (
        <MobileFilterMenu
          filters={props.filters}
          data={data}
          onClose={props.onFilterMenuClose}
          onNewFilters={props.onNewFilters}
          expandableSection={props.expandableSection}
          onExpandableOpen={props.onExpandableOpen}
          onExpandableClose={props.onExpandableClose}
        />
      )}
    </div>
  );
}
