import * as React from 'react';

export interface CanvasDimensions {
  width?: number;
  height?: number;
}

export interface Props {
  children: (widths: CanvasDimensions) => React.ReactNode;
  className?: string;

  /**
   * CSS querySelector used to find the canvas container DOM Noe
   * @type {string}
   * @default '#canvas-container'
   */
  canvasContainerSelector?: string;
}

interface State {
  result: CanvasDimensions;
}

export default class CanvasDimensionsProvider extends React.Component<Props, State> {
  state: State = {
    result: {},
  };

  root = React.createRef<HTMLDivElement>();

  computeWidths = () => {
    const { canvasContainerSelector = '#canvas-container' } = this.props;

    if (this.root.current) {
      const elem = this.root.current.querySelector(canvasContainerSelector);
      if (elem && window) {
        const result = {
          width: (elem as HTMLDivElement).offsetWidth,
          height: this.props.canvasContainerSelector ? (elem as HTMLDivElement).offsetHeight : window.innerHeight - 128,
        };
        this.setState({ result });
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.canvasContainerSelector !== prevProps.canvasContainerSelector) {
      this.computeWidths();
    }
  }

  componentDidMount() {
    this.computeWidths();
    window.addEventListener('resize', this.computeWidths);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.computeWidths);
  }

  render() {
    return (
      <div className={this.props.className} ref={this.root}>
        {this.props.children.apply(null, [this.state.result])}
      </div>
    );
  }
}
