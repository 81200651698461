import * as React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';

import S3FileUpload from 'inkp-components/dist/Components/S3FileUpload';
import { Query } from 'react-apollo';

const MIMETYPE_FILE_EXTENTION = {
  'image/jpeg': [ 'jpg', 'jpeg' ],
  'image/bmp': [ 'bmp' ],
  'image/png': [ 'png' ],
  'application/postscript': [ 'eps', 'ps' ],
  'application/pdf': [ 'pdf' ],
};
export const ACCEPT = Object.keys(MIMETYPE_FILE_EXTENTION);
const ACCEPT_FILE_EXTENSIONS = Object.values(MIMETYPE_FILE_EXTENTION)
                                    .reduce((output, extensions) => { return output.concat(extensions); }, [])
                                    .join(", ");

const SIZE_LIMIT = 25e6; // 25MB

const QUERY = gql`
  {
    uploadPolicy {
      action
      keyPrefix
      fields
    }
  }
`;


interface Props {
  file?: File | null;
  uploadPolicy: any;
  isOverlay?: boolean;
  onUpload: (artwork: any) => void;
  onUploadStart: (file: any) => void;
  onProgress: (event: any) => void;
  onFailure: (error: any) => void;
  onFileDrop: (e: React.DragEvent<HTMLDivElement>) => void;
}

export default class ImageUpload extends React.Component<Props> {
  state = { dragOver: false };

  dragCounter = 0;

  preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  dragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    this.preventDefault(event);
    if (event.dataTransfer.items && event.dataTransfer.items.length === 1) {
      const item = event.dataTransfer.items[0];
      if (ACCEPT.includes(item.type)) {
        this.setState({ dragOver: true });
      }
    }
    this.dragCounter++;
  };

  dragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    this.preventDefault(event);
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragOver: false });
    }
  };

  drop = (event: React.DragEvent<HTMLDivElement>) => {
    this.preventDefault(event);
    this.dragCounter = 0;
    this.setState({ dragOver: false });
    this.props.onFileDrop(event);
  };

  componentDidMount() {
    document.addEventListener('dragover', this.preventDefault);
    document.addEventListener('drop', this.preventDefault);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', this.preventDefault);
    document.removeEventListener('drop', this.preventDefault);
  }

  render() {
    return (
      <div className="w-full h-full" onDragEnter={this.dragEnter} onDragLeave={this.dragLeave}>
        <Query query={QUERY}>
          {({ loading, data: { uploadPolicy }, error }: any) => {
            if (loading) return null;
            if (error) return <div>Error</div>;
            return (
              <label
                className={`d-b cursor-pointer overlay p-relative w-full h-full
                  flex items-center bc-navy-200 br bw-1 p-2 ta-center overflow-hidden color-white`}
                style={{ 
                  borderStyle: this.state.dragOver ? 'solid' : 'dashed',
                }}
              >
                <style jsx>
                  {`
                    .overlay {
                      background-color: rgba(31,34,69,0.15);
                    }
                    .overlay:hover {
                      background-color: rgba(31,34,69,0.5);
                    }
                  `}
                </style>
                <div className={classnames('d-b cursor-pointer', { 'vis-hidden': this.state.dragOver })}>
                  <div><i className="mdi mdi-upload fs-icon-2p5" /></div>
                  <div className="fw-bold mt-p5">Drag & Drop Files Here or Click to Browse File</div>

                  <div className="flex flex-col-reverse lg:flex-col fs-xs">
                    <div className="mt-1">
                      <div className="fw-bold mb-p5 lg:mb-p25">Allowed file types</div>
                      <div className="d-ib w-1/2 lg:w-full">{ACCEPT_FILE_EXTENSIONS}</div>
                    </div>
                    <div className="mt-1">
                      <div className="fw-bold mb-p5 lg:mb-p25">Max file size</div>
                      <div>25MB</div>
                    </div>
                  </div>
                </div>
                {this.state.dragOver && (
                  <div
                    className={classnames(
                      "overlay flex p-absolute w-full h-full justify-center pin-l pin-t",
                    )}
                    onDrop={this.drop}
                  >
                    <div className="self-center ta-center">
                      <i className="mdi mdi-plus fs-icon-2" /><br />
                      <span className="fw-bold">Add</span>
                    </div>
                  </div>
                )}
                <S3FileUpload
                  hidden={true}
                  immediate={true}
                  accept={ACCEPT.join(',')}
                  onChangeFile={this.props.onUploadStart}
                  onUpload={this.props.onUpload}
                  onProgress={this.props.onProgress}
                  onFailure={this.props.onFailure}
                  uploadPolicy={uploadPolicy.fields}
                  sizeLimit={SIZE_LIMIT}
                  file={this.props.file}
                  keyPrefix={uploadPolicy.keyPrefix}
                  action={uploadPolicy.action}
                />
              </label>
            );
          }}
        </Query>
      </div>
    );
  }
}
