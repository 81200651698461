import * as React from 'react';
import classnames from 'classnames';

// Components
import ImageMenu from './ImageMenu';
import TextMenu from './TextMenu';
import ClipArtMenu from './ClipArtMenu';
// Interfaces
import { PrintZoneProps } from '../../../components/Canvas/PrintZone';
import { ShapeType } from '../../../interfaces/Canvas';

interface Props {
  /** Function that handles shape changes */
  onUpdateShape: (shape: any) => void;

  /** Selected element **/
  selected?: any;

  /** Active in mobile **/
  mobileSelected?: boolean;

  /** Mobile section */
  mobileSection: string | null;

  /** Seciont change handler */
  onChangeSection: (section: string | null) => void;

  /** Cancel handler */
  onCancel: () => void;

  /** Edit layer button handler */
  onShowLayer: () => void;

  /** Layer hide handler */
  onHideLayer: () => void;

  /** Flag for layer display */
  showLayer?: boolean;

  /** List of shapes in the design for layering */
  shapes: any[];

  /** Print zone data */
  mainPrintZone: PrintZoneProps;

  /** Layer change handler */
  onNewIndex: (data: { id: string; zIndex: number }) => void;
}

class ShapeMenu extends React.Component<Props> {
  displayTools = () => {
    const { onUpdateShape, selected, mobileSection } = this.props;
    const type = selected ? selected.type : null;
    switch (type) {
      case ShapeType.IMAGE:
        return (
          <ImageMenu
            shape={selected}
            shapes={this.props.shapes}
            mobileSection={mobileSection}
            showLayer={this.props.showLayer}
            onNewIndex={this.props.onNewIndex}
            mainPrintZone={this.props.mainPrintZone}
            onChange={onUpdateShape}
            onChangeSection={this.props.onChangeSection}
            onCancel={this.props.onCancel}
            onShowLayer={this.props.onShowLayer}
            onHideLayer={this.props.onHideLayer}
          />
        );
      case ShapeType.TEXT:
        return (
          <TextMenu
            shape={selected}
            shapes={this.props.shapes}
            mobileSection={mobileSection}
            showLayer={this.props.showLayer}
            onNewIndex={this.props.onNewIndex}
            mainPrintZone={this.props.mainPrintZone}
            onChange={onUpdateShape}
            onChangeSection={this.props.onChangeSection}
            onCancel={this.props.onCancel}
            onShowLayer={this.props.onShowLayer}
            onHideLayer={this.props.onHideLayer}
          />
        );
      case ShapeType.CLIPART:
        return (
          <ClipArtMenu
            shape={selected}
            shapes={this.props.shapes}
            mobileSection={mobileSection}
            showLayer={this.props.showLayer}
            onNewIndex={this.props.onNewIndex}
            mainPrintZone={this.props.mainPrintZone}
            onChange={onUpdateShape}
            onChangeSection={this.props.onChangeSection}
            onCancel={this.props.onCancel}
            onShowLayer={this.props.onShowLayer}
            onHideLayer={this.props.onHideLayer}
          />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div
        className={classnames(
          {
            'd-b': this.props.mobileSelected,
            'd-n': !this.props.mobileSelected,
          },
          'lg:d-b p-fixed pin-l pin-b pin-r lg:p-relative',
          'w-full bgc-white brt lg:bgc-blue-50 shadow-3 lg:shadow-none'
        )}
      >
        {this.displayTools()}
      </div>
    );
  }
}

export default ShapeMenu;
