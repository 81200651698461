import React from 'react';

interface Props {
  showPrompt: boolean;
}

const MESSAGE = "Changes you made may not be saved.";

export default class WithNavigationPrompt extends React.Component<Props> {
  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  onBeforeUnload = (event: any) => {
    event.preventDefault();
    if (this.props.showPrompt) {
      event.returnValue = MESSAGE;
      return MESSAGE;
    }
  }

  render() {
    return (
      this.props.children
    );
  }
};
