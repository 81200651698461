import * as _ from 'lodash';
import moment from 'moment';

import { StateContainer } from 'infra-frontend/helpers/apollo';
import { url, routes } from 'inkp-routes';
import { UPLOAD_ERROR_CODE } from 'inkp-components/dist/Components/FileUpload';
import { Shareable, Design } from 'inkp-design-sdk/types.g';

import { SaveScene } from '../SaveDesignModal';

type ProductEditTab = 'color' | 'qty' | null;

export interface DesignToolState {
  selectedId: string | null;
  hoveredId: string | null;
  selectedTool: string | null;
  mobileTool: string | null;
  resizing: boolean;
  dragging: boolean;
  showVerticalGuideline: boolean;
  showLayer: boolean;
  showQty: boolean;
  showCart: boolean;
  showSaving: boolean;

  mobileSelected: boolean;
  mobileSection: string | null;

  upload: {
    fileName: string;
    total: number;
    uploaded: number;
    startedAt: number;
  } | null;
  uploadError: {
    code: UPLOAD_ERROR_CODE;
  } | null;

  productEditTab: ProductEditTab;
  retrieveDesign: boolean;

  outOfBounds:
    | {
        productId: string;
        side: string;
        shapes: { id: string }[];
      }[]
    | null;
  hideOutOfBoundsWarning: boolean;

  emptyWarning: boolean;

  save: {
    modal: boolean; // Whether save modal is active
    cart: boolean;
    scene: SaveScene; // Which UI to display in the modal
    shareUrl: string; // Saved shareable URL
    shareCode: string | null; // Shareable code
    shareEmails: string[]; // Email addresses for email sharing
    duplicate: {
      // Shareable found with the same name, to help determining if the user want to save new/replace
      id: string;
      name: string;
      email: string | null;
    } | null;
    emailInUse: string | null; // Email address that is tied an existing account, prompt user to login
    data: {
      // Input fields in the modal
      designName: string;
      email: string;
      password: string;
      shareEmail: string;
    };
    fieldErrors: {
      // Input field errors in the modal
      valid: boolean;
      field: string;
      message: string;
    }[];
  };

  redesignError: string | null;
  redesignSuccess: string | null;
  showProgressBar: boolean;
  newCartItem: {
    designId: string;
    productId: string;
    color: string;
  } | null;
  showCartActionsModal: boolean;
}

export default class DesignToolStateContainer extends StateContainer<DesignToolState> {
  initialState = {
    selectedId: null,
    hoveredId: null,
    selectedTool: null,
    mobileTool: null,
    resizing: false,
    dragging: false,
    showVerticalGuideline: false,
    showLayer: false,
    showQty: false,
    showCart: false,
    showSaving: false,

    mobileSelected: false,
    mobileSection: null,

    upload: null,
    uploadError: null,

    productEditTab: 'color' as ProductEditTab,

    retrieveDesign: false,

    outOfBounds: null,
    hideOutOfBoundsWarning: false,
    emptyWarning: false,

    save: {
      modal: false,
      cart: false,
      shareEmails: [],
      scene: 'save' as SaveScene,
      shareUrl: '',
      shareCode: null,
      duplicate: null,
      emailInUse: null,
      data: {
        designName: `My Design - ${moment().format('MM-DD-YY, HH:mm:ss')}`,
        email: '',
        password: '',
        shareEmail: '',
      },
      fieldErrors: [],
    },

    redesignError: null,
    redesignSuccess: null,
    showProgressBar: false,
    newCartItem: null,
    showCartActionsModal: false,
  };

  // TODO: separate saving state from the page state
  shape = `
    {
      selectedId
      hoveredId
      selectedTool
      mobileTool
      dragging
      resizing
      showVerticalGuideline
      mobileSelected
      mobileSection
      showLayer
      showQty
      showCart
      showSaving

      upload {
        fileName
        uploaded
        total
        startedAt
      }
      uploadError {
        code
      }

      productEditTab

      retrieveDesign

      outOfBounds {
        productId
        side
        shapes {
          id
        }
      }
      hideOutOfBoundsWarning

      emptyWarning

      save {
        modal
        cart
        shareEmails
        shareUrl
        shareCode
        scene
        emailInUse
        duplicate {
          id
          name
          email
        }
        data {
          designName
          email
          password
          shareEmail
        }
        fieldErrors {
          valid
          field
          message
        }
      }

      redesignError
      redesignSuccess
      showProgressBar
      newCartItem {
        designId
        productId
        color
      }
      showCartActionsModal
    }
  `;

  showSaveModal = (cart: boolean, shareable?: { name: string }) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        modal: true,
        shareUrl: '',
        scene: 'save',
        shareEmails: [],
        duplicate: null,
        emailInUse: null,
        data: Object.assign({}, this.state.save.data, {
          password: '',
          shareEmail: '',
          designName: shareable ? shareable.name : this.state.save.data.designName,
        }),
        fieldErrors: [],
        cart: cart || false,
      }),
    });
  };

  changeSaveField = (field: string, value: string) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        data: Object.assign({}, this.state.save.data, {
          [field]: value,
        }),
      }),
    });
  };

  hideSaveModal = () => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        modal: false,
        cart: false,
      }),
    });
  };

  saveShareable = (shareable: Shareable) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        duplicate: null,
        emailInUse: null,
        shareUrl: url('app', routes.app.share, { code: shareable.code }),
        shareCode: shareable.code,
        data: {
          ...this.state.save.data,
          designName: shareable.name,
        },
        // scene: 'share',
        fieldErrors: [],
      }),
    });
  };

  changeSaveScene = (scene: SaveScene) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        scene,
      }),
    });
  };

  addShareEmail = (email: string) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        shareEmails: _.uniq(this.state.save.shareEmails.concat([email])),
        data: Object.assign({}, this.state.save.data, {
          shareEmail: '',
        }),
      }),
    });
  };

  removeShareEmail = (emailToRemove: string) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        shareEmails: this.state.save.shareEmails.filter((email) => email !== emailToRemove),
      }),
    });
  };

  setDuplicate = (duplicate: { id: string; name: string; email?: string }) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        duplicate: Object.assign(
          {
            email: null,
          },
          duplicate
        ),
        emailInUse: null,
        fieldErrors: [],
      }),
    });
  };

  setEmailInUse = (email: string) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        emailInUse: email,
      }),
    });
  };

  setFieldErrors = (fieldErrors: { valid: boolean; field: string; message: string }[]) => {
    this.setState({
      save: Object.assign({}, this.state.save, {
        fieldErrors,
      }),
    });
  };

  showLayer = (showLayer: boolean) => {
    this.setState({
      showLayer,
      mobileSection: !showLayer ? null : this.state.mobileSection,
    });
  };

  setUploadError = (uploadError: { code: string | number }) => {
    this.setState({ uploadError, upload: null });
  };

  dismissUploadError = () => {
    this.setState({ uploadError: null });
  };

  startUpload = (file: { name: string; size: number }) => {
    this.setState({
      upload: {
        fileName: file.name,
        uploaded: 0,
        total: file.size,
        startedAt: new Date().getTime(),
      },
      uploadError: null,
    });
  };

  progressUpload = (event: { loaded: number }) => {
    if (this.state.upload) {
      //TODO: Do a better job to check if there the progress event is not from a canceld upload
      this.setState({
        upload: Object.assign({}, this.state.upload, {
          uploaded: event.loaded,
        }),
      });
    }
  };

  unsetUpload = () => {
    this.setState({
      upload: null,
    });
  };

  selectTool = (tool: string) => {
    this.setState({
      selectedTool: tool,
      selectedId: null,
    });
  };

  selectMobileTool = (tool: string | null) => {
    this.setState({
      mobileTool: tool,
      selectedId: null,
    });
  };

  setDragging = (dragging: boolean, { id }: { id: string }) => {
    this.setState({ dragging, selectedId: id });
  };

  setResizing = (resizing: boolean) => {
    this.setState({ resizing });
  };

  setVerticalGuideline = (showVerticalGuideline: boolean) => {
    this.setState({ showVerticalGuideline });
  };

  selectShape = ({ id }: { id: string }) => {
    this.setState({
      selectedId: id,
      selectedTool: null,
      mobileTool: null,
      mobileSelected: false,
      mobileSection: null,
    });
  };

  deselect = () => {
    this.setState({
      selectedId: null,
      hoveredId: null,
      selectedTool: null,
      mobileTool: null,
      mobileSelected: false,
      mobileSection: null,
    });
  };

  setHovered = ({ id }: { id: string }) => {
    this.setState({
      hoveredId: id,
    });
  };

  unsetHovered = ({ id }: { id: string }) => {
    if (this.state.hoveredId === id) {
      this.setState({
        hoveredId: null,
      });
    }
  };

  mobileSelect = (select: boolean) => {
    this.setState({
      mobileSelected: select,
      mobileSection: null,
    });
  };

  changeMobileSection = (section: string) => {
    this.setState({
      mobileSection: section,
    });
  };

  setProductEditTab = (value: string | null) => {
    this.setState({
      productEditTab: this.state.productEditTab === value ? null : value,
    });
  };

  setRetrieveDesign = (value: boolean) => {
    this.setState({
      retrieveDesign: value,
    });
  };

  setShowQty = (value: boolean) => {
    this.setState({
      showQty: value,
      showCart: value,
    });
  };

  setShowSaving = (value: boolean) => {
    this.setState({
      showSaving: value,
    });
  };

  setRedesignError = (error: string | null) => {
    this.setState({
      redesignError: error,
    });
  };

  setRedesignSuccess = (message: string | null) => {
    this.setState({
      redesignSuccess: message,
    });
  };

  setOutOfBounds = (data: { productId: string; side: string; shapes: { id: string }[] } | null) => {
    this.setState({ outOfBounds: data });
  };

  hideOutOfBoundsWarning = (hideWarning: boolean) => {
    this.setState({ hideOutOfBoundsWarning: hideWarning });
  };

  setEmptyWarning = (emptyWarning: boolean) => {
    this.setState({ emptyWarning });
  };

  setShowCart = (open: boolean, showProgressBar: boolean) => {
    this.setState({
      showQty: false,
      showCart: open,
      showProgressBar,
    });
  };

  addToCart = (designId: string, productId: string, color: string) => {
    this.setState({
      showProgressBar: false,
      newCartItem: {
        designId,
        productId,
        color,
      },
    });
  };

  removeNewCartItem = () => {
    this.setState({
      newCartItem: null,
    });
  };

  setShowCartActionsModal = (show: boolean) => {
    return this.setState({
      showCartActionsModal: show,
    });
  };

  resetDefaultDesignName = () => {
    return this.changeSaveField('designName', `My Design - ${moment().format('MM-DD-YY, HH:mm:ss')}`);
  };
}
