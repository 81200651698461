import * as React from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';

export interface FilterPillProps {
  className?: string;
  icon?: React.ReactNode;
  display: string;
  value: string;
  active?: boolean;
  color?: string;
  selected?: boolean;
  onClick?: () => void;
}

const FilterPill: React.SFC<FilterPillProps> = ({ icon, display, active, className, onClick, selected }) => (
  <div className={classnames('br-full cursor-pointer d-ib mr-p5 bgc-gray-50 py-p5 lg:py-p75 px-1 lg:px-1p5 color-primary:hover minw-3', {
    'color-primary': active,
    'color-navy-500': !active,
  }, className)} onClick={onClick}>
    <div className="flex justify-center items-center">
      <span className={classnames('fw-bold fs-xs lg:fs-md ta-center', { selected:  'color-coral'})}>{display}</span>
      {selected && ( <i className="lg:d-n mdi mdi-check ml-p25" style={{ fontSize: '18px', lineHeight: 1, height: '18px', top: '-2px', position: 'relative' }} />)}
    </div>
  </div>
)

const FilterPills: React.SFC<{
  options?: FilterPillProps[];
  onSelect: (value: string) => void;
  values?: string[];
  value?: string;
}> = (props) => (
  <div className="whitespace-no-wrap overflow-x-auto">
    {props.options && props.options.map((option, i) => (
      <FilterPill
        key={option.value}
        className={i === 0 ? 'ml-1 lg:ml-0' : ''}
        onClick={() => props.onSelect(option.value)}
        active={!!((props.values && props.values.indexOf(option.value) > -1) || (props.value && props.value === option.value))}
        selected={(props.values && props.values.indexOf(option.value) > -1)}
        {...option}
      />
    ))}
    {!props.options && _.times(4, (i) => (
      <div
        key={i}
        className={classnames('br-full cursor-pointer d-ib mr-p5 bgc-gray-50 px-3', {
         'ml-1': i === 0,
        })}
        style={{ height: '34px' }}
      />
    ))}
  </div>
)

export default FilterPills;
